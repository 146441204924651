import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faQuestionCircle, faTrashAlt, faCircle } from '@fortawesome/free-solid-svg-icons';
import {
	Card,
	CardTitle,
	CardHeader,
	CardBody,
	CardFooter,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	Spinner,
	Alert,
	Tooltip,
	Label,
	Button,
	Table
} from 'reactstrap';
import formFilterShow from './formFilterShow.js';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import axios from '../../../axios';

// Components
import ContentWrapper from '../../../components/ContentWrapper';
import SpinnerLoader from '../../../components/SpinnerLoader';
import Aux from '../../../hoc/Auxilary';

import './EditForm.sass';

class EditForm extends Component {

	state = {
		codeValue: '',
    	showFormNobody: false,
    	formDataTemp: {
    		formName: '',
    		formShortCode: '',
    		formTriggerType: 0,
    		formToDisplay: 'all',
    		formShowFilter: [],
    		formSubscribeType: 'single',
    		formShowQuantity: 0,
    		formShowQuantityPer: 'visit',
    		formAppear: 'excluding-scroll',
    		formAppearScroll: 0,
    		formShowOnWebsite: 'all',
    		formShowOnWebsiteSpecificPages: ''
    	},
    	formData: {},
    	formDataLoading: true,
    	formDataErrorLoading: false,
    	formSaveLoading: false
	};

	componentDidMount () {
		this.getFormData();
	}

	getFormData = () => {
		this.setState({
			formDataLoading: true,
			formDataErrorLoading: false
		});

		axios.get(`/forms/${this.props.match.params.formId}/rules`)
			.then(response => {
				this.setState({
					formDataLoading: false
				});

				if (response.data.success) {
					this.setState({
						formData: response.data.data.rules
					});

					this.setFormData();
				} else {
					this.setState({
						formDataErrorLoading: true
					});
				}
			}).catch(error => {
				this.setState({
					formDataLoading: false,
					formDataErrorLoading: true
				});
			});
	};

	setFormData = () => {
		const { formData, formDataTemp } = this.state;

		let formDataTempNew = {
			...formDataTemp,
			formName: formData.name,
			formShortCode: formData.shortCode,
			formTriggerType: formData.trigger ? formData.trigger : formData.allTriggers[0].id,
			formSubscribeType: formData.subscribeType
		};

		if (formData.showRules) {
			formDataTempNew = {
				...formDataTempNew,
				formShowFilter: formData.showRules
			};
		}

		if (formData.users) {
			const users = JSON.parse(formData.users);

			formDataTempNew = {
				...formDataTempNew,
				formToDisplay: users.formToDisplay,
				formShowQuantity: users.formShowQuantity,
				formShowQuantityPer: users.formShowQuantityPer
			};
		}

		if (formData.scroll) {
			const scroll = JSON.parse(formData.scroll);

			formDataTempNew = {
				...formDataTempNew,
				formAppear: scroll.formAppear,
				formAppearScroll: scroll.formAppearScroll
			};
		}

		if (formData.pages) {
			const pages = JSON.parse(formData.pages);

			formDataTempNew = {
				...formDataTempNew,
				formShowOnWebsite: pages.formShowOnWebsite,
				formShowOnWebsiteSpecificPages: pages.formShowOnWebsiteSpecificPages
			};
		}

		this.setState({
			formDataTemp: formDataTempNew
		});
	};

	saveFormData = () => {
		const { formDataTemp } = this.state;

		this.setState({
			formSaveLoading: true
		});

		let params = {
			name: formDataTemp.formName,
			trigger: formDataTemp.formTriggerType,
			opt_in: formDataTemp.formSubscribeType,
			showRules: formDataTemp.formShowFilter,
			users: JSON.stringify({
				formToDisplay: formDataTemp.formToDisplay,
				formShowQuantity: formDataTemp.formShowQuantity,
				formShowQuantityPer: formDataTemp.formShowQuantityPer
			}),
			scroll: JSON.stringify({
				formAppear: formDataTemp.formAppear,
				formAppearScroll: formDataTemp.formAppearScroll
			}),
			pages: JSON.stringify({
				formShowOnWebsite: formDataTemp.formShowOnWebsite,
				formShowOnWebsiteSpecificPages: formDataTemp.formShowOnWebsiteSpecificPages
			})
		};

		axios.post(`/forms/${this.props.match.params.formId}/rules/save`, params)
			.then(response => {
				this.setState({
					formSaveLoading: false
				});

				if (response.data.success) {
					toast.success('Настройки формы сохранены.');
				} else {
					toast.error('Ошибка при сохранении формы');
				}
			}).catch(error => {
				this.setState({
					formSaveLoading: false
				});
				
				toast.error('Ошибка при сохранении формы');
			});
	};

	formElementChange = (name, value) =>{
		this.setState({
			formDataTemp: {
				...this.state.formDataTemp,
				[name]: value
			}
		});
	};

	formShowFilterElementChange = (name, value, i) => {
		const { formDataTemp } = this.state;

		let formShowFilterNew = formDataTemp.formShowFilter;

		formShowFilterNew[i][name] = value;

		this.setState({
			formDataTemp: {
				...formDataTemp,
				formShowFilter: formShowFilterNew
			}
		});
	};

    addFormShowRule = () => {
    	const { formDataTemp } = this.state;

    	this.setState({
    		formDataTemp: {
    			...formDataTemp,
    			formShowFilter: [
    				...formDataTemp.formShowFilter,
    				{
    					id: uuid(),
    					sourceValue: formFilterShow.sourceInput[0].value !== 'inputText' && formFilterShow.sourceInput[0].value !== 'inputNumber' ? formFilterShow.sourceInput[0].value : '',
						equalValue: formFilterShow.sourceInput[0].equalInput.value !== 'inputText' && formFilterShow.sourceInput[0].equalInput.value !== 'inputNumber' ? formFilterShow.sourceInput[0].equalInput[0].value : '',
						valueValue: formFilterShow.sourceInput[0].valueInput.value !== 'inputText' && formFilterShow.sourceInput[0].valueInput.value !== 'inputNumber' ? formFilterShow.sourceInput[0].valueInput[0].value : ''
    				}
    			]
    		}
    	});
    };

    deleteFormShowFilter = i => {
    	const { formDataTemp } = this.state;

    	let formShowFilterNew = formDataTemp.formShowFilter;

		formShowFilterNew.splice(i, 1);

		this.setState({
			formDataTemp: {
				...formDataTemp,
				formShowFilter: formShowFilterNew
			}
		});
    };

    getFormShowFiltersList = () => {
    	const { formDataTemp } = this.state;

    	let renderFormShowFiltersList = null;

		renderFormShowFiltersList = formDataTemp.formShowFilter.map((formFilter, i) => {

	    	let formFilterSourceInput = null;
			let formFilterEqualInput = null;
			let formFilterValueInput = null;

			const formFilterSourceFirstInput = formFilterShow.sourceInput.find(filter => filter.value === formFilter.sourceValue);

			switch (formFilterSourceFirstInput.value) {
				case 'inputText':
					formFilterSourceInput = <Input 
												type="text" 
												value={formFilter.sourceValue} 
												onChange={e => this.formShowFilterElementChange('sourceValue', e.target.value, i)} 
												placeholder={formFilterSourceFirstInput.label} 
											/>
					break;

				case 'inputNumber':
					formFilterSourceInput = <Input 
												type="number" 
												value={formFilter.sourceValue} 
												onChange={e => this.formShowFilterElementChange('sourceValue', e.target.value, i)} 
												placeholder={formFilterSourceFirstInput.label} 
											/>
					break;

				default:
					formFilterSourceInput = (
						<Input 
							type="select" 
							value={formFilter.sourceValue} 
							onChange={e => this.formShowFilterElementChange('sourceValue', e.target.value, i)}
						>
							{formFilterShow.sourceInput.map(filter => (
								<option key={filter.value} value={filter.value}>{filter.label}</option>
							))}
						</Input>
					);
			}

			const formFilterEqualFirstInput = formFilterSourceFirstInput.equalInput;

			switch (formFilterEqualFirstInput.value) {
				case 'inputText':
					formFilterSourceInput = <Input 
												type="text" 
												value={formFilter.equalValue} 
												onChange={e => this.formShowFilterElementChange('equalValue', e.target.value, i)} 
												placeholder={formFilterEqualFirstInput.label} 
											/>
					break;

				case 'inputNumber':
					formFilterSourceInput = <Input 
												type="number" 
												value={formFilter.equalValue} 
												onChange={e => this.formShowFilterElementChange('equalValue', e.target.value, i)} 
												placeholder={formFilterEqualFirstInput.label} 
											/>
					break;
				
				default:
					formFilterEqualInput = (
						<Input 
							type="select" 
							value={formFilter.equalValue} 
							onChange={e => this.formShowFilterElementChange('equalValue', e.target.value, i)}
						>
							{formFilterEqualFirstInput.map(filter => (
								<option key={filter.value} value={filter.value}>{filter.label}</option>
							))}
						</Input>
					);
			}

			const formFilterValueFirstInput = formFilterSourceFirstInput.valueInput;

			switch (formFilterValueFirstInput.value) {
				case 'inputText':
					formFilterValueInput = <Input 
												type="text" 
												value={formFilter.valueValue} 
												onChange={e => this.formShowFilterElementChange('valueValue', e.target.value, i)} 
												placeholder={formFilterValueFirstInput.label} 
											/>
					break;

				case 'inputNumber':
					formFilterValueInput = <Input 
												type="number" 
												value={formFilter.valueValue} 
												onChange={e => this.formShowFilterElementChange('valueValue', e.target.value, i)} 
												placeholder={formFilterValueFirstInput.label} 
											/>
					break;

				default:
					formFilterValueInput = (
						<Input 
							type="select" 
							value={formFilter.valueValue} 
							onChange={e => this.formShowFilterElementChange('valueValue', e.target.value, i)}
						>
							{formFilterValueFirstInput.map(filter => (
								<option key={filter.value} value={filter.value}>{filter.label}</option>
							))}
						</Input>
					);
			}

			return (
				<tr key={formFilter.id}>
					<td>{formFilterSourceInput}</td>
					<td>{formFilterEqualInput}</td>
					<td>{formFilterValueInput}</td>
					<td>
						<Button onClick={() => this.deleteFormShowFilter(i)}>
							<FontAwesomeIcon icon={faTrashAlt} />
						</Button>
					</td>
				</tr>
			);

		});

		return renderFormShowFiltersList;
    };

    getTriggerOptionsList = () => {
    	const { formData } = this.state;

    	const triggerOptions = formData.allTriggers ? formData.allTriggers : [];

    	return triggerOptions.map(option => (
    		<option key={option.id} value={option.value}>{option.name}</option>
    	));
    };

    pinCopiedAlert = () => {
    	toast.success('Скопировано', { autoClose: 1500 });
    };

    toggleShowFormNobody = () => {
    	this.setState({
    		showFormNobody: !this.state.showFormNobody
    	});
    };

	render () {
		const { 
			formDataTemp, 
			showFormNobody, 
			formDataLoading,
			formDataErrorLoading,
			formSaveLoading 
		} = this.state;

		return (
			<ContentWrapper>
				{formDataErrorLoading ? (
					<Alert className="text-center" color="danger">
						Ошибка при загрузке данных

						<br/>

						<Button 
							className="mt-2" 
							size="xs" 
							color="secondary" 
							onClick={() => this.getFormData()}
						>
							Повторить запрос
						</Button>
					</Alert>
				) : (
					<Aux>
						{formDataLoading
							? 
								<SpinnerLoader /> 
							: 
								<Card>
									<CardHeader>
										<div className="container-fluid">
											<div className="row">
												<div className="col-xl-4">

													<Input className="form-name-input" value={formDataTemp.formName} onChange={e => this.formElementChange('formName', e.target.value)} />

												</div>
												<div className="col-xl-8">

													<FormGroup className="copy-code-fg">
														<Label for="formCodeInput">Код, которым форма отправляет email в систему:</Label>

														<InputGroup>
															<Input 
																id="formCodeInput"
															   	value={formDataTemp.formShortCode}
												          	   	onChange={e => this.formElementChange('formShortCode', e.target.value)} 
												          	/>

												          	<InputGroupAddon addonType="append">
												          		<CopyToClipboard 
																	text={formDataTemp.formShortCode}
																	onCopy={() => this.pinCopiedAlert()}
																>
														          <Button className="copy-btn" color="primary">Скопировать код</Button>
														        </CopyToClipboard>
												          	</InputGroupAddon>
														</InputGroup>
													</FormGroup>

												</div>
											</div>
										</div>
									</CardHeader>

									<CardBody>
										<div className="container-fluid">
											<div className="row">
												<div className="col-xl-6">

													<FormGroup>
														<Label>Какой триггер запускать при подписке через эту форму:</Label>
														
														<Input type="select" name="select" value={formDataTemp.formTriggerType} onChange={e => this.formElementChange('formTriggerType', e.target.value)}>
															{this.getTriggerOptionsList()}
														</Input>
													</FormGroup>

													<FormGroup>
														<Label>Кому показывать эту форму</Label>

														<FormGroup>
															<label className="c-radio">
				                                            	<Input
				                                            		id="whereToShowFormRadio1" 
				                                            		type="radio" 
				                                            		name="whereToShowFormRadio" 
				                                            		value="all" 
				                                            		onChange={e => this.formElementChange('formToDisplay', e.target.value)} 
																	checked={formDataTemp.formToDisplay === 'all'}
																/>
				                                            	
				                                            	<FontAwesomeIcon icon={faCircle} /> Всем посетителям
				                                            </label>
				                                        	
				                                        	<label className="c-radio">
				                                            	<Input
				                                            		id="whereToShowFormRadio2" 
				                                            		type="radio" 
				                                            		name="whereToShowFormRadio" 
				                                            		value="filter" 
				                                            		onChange={e => this.formElementChange('formToDisplay', e.target.value)} 
																	checked={formDataTemp.formToDisplay === 'filter'}
																/>
				                                            	
				                                            	<FontAwesomeIcon icon={faCircle} /> Включить фильтр
				                                           </label>
				                                        	
				                                        	<label className="c-radio">
				                                            	<Input
				                                            		id="whereToShowFormRadio3" 
				                                            		type="radio" 
				                                            		name="whereToShowFormRadio" 
				                                            		value="nobody" 
				                                            		onChange={e => this.formElementChange('formToDisplay', e.target.value)} 
																	checked={formDataTemp.formToDisplay === 'nobody'}
																/>
				                                            	
				                                            	<FontAwesomeIcon icon={faCircle} />

				                                            	Никому

				                                            	<FontAwesomeIcon className="ml-2" id="showFormNobody" icon={faQuestionCircle} />
							
																<Tooltip 
																	placement="right" 
																	isOpen={showFormNobody} 
																	target="showFormNobody" 
																	toggle={this.toggleShowFormNobody}
																>
																	Отключить отображение формы. В данном режиме остается возможность собирать адреса подписчиков через API-вызов TGP.api.subscribe('track', 'form_id', '#user_email#');
																</Tooltip> 
				                                           </label>
				                                        </FormGroup>
													</FormGroup>

													<Card>
														<CardHeader>Фильтр показа формы</CardHeader>
														
														<CardBody>
															<CardTitle>Форма будет показана при выполнении ВСЕХ условий</CardTitle>
															
															<Table className="showing-form-filter">
																<tbody>
																	{this.getFormShowFiltersList()}

																	<tr>
																		<td colSpan="4">
																			<Button color="primary" onClick={() => this.addFormShowRule()}>Добавить условие</Button>
																		</td>
																	</tr>
																</tbody>
															</Table>
														</CardBody>
													</Card>

												</div>
												<div className="col-xl-6">

													<FormGroup>
														<Label>Тип подписки</Label>
														
														<Input
															type="select" 
															name="select" 
															value={formDataTemp.formSubscribeType} 
															onChange={e => this.formElementChange('formSubscribeType', e.target.value)}
														>
															<option value="single">Single Opt-in</option>
															<option value="double">Double Opt-in</option>
														</Input>
													</FormGroup>

													<div className="row">
														<div className="col-sm-6">

															<FormGroup>
																<Label>Сколько раз показывать (не более):</Label>

																<Input 
																	type="number" 
																	min={0} 
																	value={formDataTemp.formShowQuantity} 
																	onChange={e => this.formElementChange('formShowQuantity', e.target.value)} 
																	placeholder="Количество показов"
																/>
															</FormGroup>

														</div>
														<div id="showPerItemSelectCol" className="col-sm-6 d-flex">

															<span id="showPerItemSelectColLabel">за</span>

															<FormGroup id="showPerItemSelect">
																<Input 
																	type="select" 
																	name="select"
																	value={formDataTemp.formShowQuantityPer} 
																	onChange={e => this.formElementChange('formShowQuantityPer', e.target.value)}
																>
																	<option value="visit">Визит</option>
																	<option value="day">День</option>
																	<option value="always">Все время</option>
																</Input>
															</FormGroup>

														</div>
													</div>

													<FormGroup>
														<Label>Форма должна появляться:</Label>

														<FormGroup>
															<label className="c-radio">
				                                            	<Input 
				                                            		id="formShouldAppearRadio1" 
				                                            		type="radio" 
				                                            		name="formShouldAppearRadio" 
				                                            		value="excluding-scroll" 
				                                            		onChange={e => this.formElementChange('formAppear', e.target.value)} 
				                                            		checked={formDataTemp.formAppear === 'excluding-scroll'}
				                                            	/>
				                                            	
				                                            	<FontAwesomeIcon icon={faCircle} /> Без учета прокрутки
				                                            </label>
				                                        	
				                                        	<label className="c-radio">
				                                            	<Input 
				                                            		id="formShouldAppearRadio2" 
				                                            		type="radio" 
				                                            		name="formShouldAppearRadio" 
				                                            		value="scroll-procent" 
				                                            		onChange={e => this.formElementChange('formAppear', e.target.value)} 
				                                            		checked={formDataTemp.formAppear === 'scroll-procent'}
				                                            	/>
				                                            	
				                                            	<FontAwesomeIcon icon={faCircle} /> При прокрутке страницы на указанный процент
				                                           </label>
				                                        </FormGroup>
													</FormGroup>

													<FormGroup>											
														<Input 
															type="number" 
															placeholder="" 
															min={0} 
															value={formDataTemp.formAppearScroll} 
															onChange={e => this.formElementChange('formAppearScroll', e.target.value)}
															disabled={formDataTemp.formAppear === 'excluding-scroll'}
														/>
													</FormGroup>

													<FormGroup>
														<Label>Отображать на сайте:</Label>

														<FormGroup>
															<label className="c-radio">
				                                            	<Input 
				                                            		id="showOnSiteRadio1" 
				                                            		type="radio" 
				                                            		name="showOnSiteRadio"
				                                            		value="all"
				                                            		onChange={e => this.formElementChange('formShowOnWebsite', e.target.value)}
																	checked={formDataTemp.formShowOnWebsite === 'all'}
				                                            	 />

				                                            	<FontAwesomeIcon icon={faCircle} /> На всех страницах
				                                            </label>
				                                        	
				                                        	<label className="c-radio">
				                                            	<Input 
				                                            		id="showOnSiteRadio2" 
				                                            		type="radio" 
				                                            		name="showOnSiteRadio"
				                                            		value="specific"
				                                            		onChange={e => this.formElementChange('formShowOnWebsite', e.target.value)}
																	checked={formDataTemp.formShowOnWebsite === 'specific'}
				                                            	/>

				                                            	<FontAwesomeIcon icon={faCircle} /> Только на указанных
				                                           </label>

				                                           <label className="c-radio">
				                                            	<Input 
				                                            		id="showOnSiteRadio3" 
				                                            		type="radio" 
				                                            		name="showOnSiteRadio"
				                                            		value="excluding-specific"
				                                            		onChange={e => this.formElementChange('formShowOnWebsite', e.target.value)}
																	checked={formDataTemp.formShowOnWebsite === 'excluding-specific'}
				                                            	/>

				                                            	<FontAwesomeIcon icon={faCircle} /> Не показывать на указанных
				                                           </label>
				                                        </FormGroup>
													</FormGroup>

													<FormGroup>
														<Input 
															type="textarea" 
															name="text" 
															rows="7"
															value={formDataTemp.formShowOnWebsiteSpecificPages}
															onChange={e => this.formElementChange('formShowOnWebsiteSpecificPages', e.target.value)}
															disabled={formDataTemp.formShowOnWebsite === 'all'}
														/>
													</FormGroup>

												</div>
											</div>
										</div>
									</CardBody>

									<CardFooter>
										<div className="container-fluid">
											<div className="row">
												<div className="col-sm-6 text-center text-sm-left">

													<Link to={`/form/${this.props.match.params.formId}/editor/edit`}><Button size="lg" color="success">Редактировать форму</Button></Link>

												</div>
												<div className="col-sm-6 text-sm-right text-center mt-4 mt-sm-0">

													<Button 
														size="lg" 
														color="primary" 
														onClick={() => this.saveFormData()}
														disabled={formSaveLoading}
													>
														Сохранить {formSaveLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
													</Button>

													<Link to="/subscriptions">
														<Button size="lg" color="secondary" className="ml-2">Отмена</Button>
													</Link>

												</div>
											</div>
										</div>
									</CardFooter>
								</Card>
						}
					</Aux>
				)}
			</ContentWrapper>
		);
	}
}

export default EditForm;
