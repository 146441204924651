import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'jquery-slimscroll';

const Scrollable = props => {
    const init = node =>
        $(node).slimScroll({
            height: props.height
        });

    return (
        <div ref={init} {...props}>
            {props.children}
        </div>
    );
};

Scrollable.propTypes = {
    height: PropTypes.string
};

Scrollable.defaultProps = {
  height: 250
};

export default Scrollable;
