import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	Input,
	Button,
	Pagination,
	PaginationItem,
	Alert,
	PaginationLink,
	Modal,
	ModalBody,
	FormFeedback,
	Table,
	Spinner
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTrashAlt,
	faUserCircle,
	faCheck
} from '@fortawesome/free-solid-svg-icons';
import { faExpeditedssl } from '@fortawesome/free-brands-svg-icons';
import { toast } from 'react-toastify';
import axios from '../../axios';

// Components
import ContentWrapper from '../../components/ContentWrapper';
import SpinnerLoader from '../../components/SpinnerLoader';

import './AudiencePage.sass';

class AudiencePage extends Component {

	state = {
		subscribersList: [],
		subscribersLoading: false,
		subscribersErrorLoading: false,
		subscribersSearchValid: true,
		subscribersPage: 0,
		subscribersPerPage: 10,
		addToExceptionLoading: false,
		showSubscibersList: true,
		deleteSubscriberModalOpen: false,
		addToExceptionModalOpen: false,
		restoreExceptionModalOpen: false,
		exceptionModalOpen: false,
		exportModalOpen: false,
		subscriberToDelete: null,
		subscriberToException: null,		
		exceptionToRestore: null,
		subscribersToSearchValue: '',
		addMailsToExceptions: false,
		subscribersSearchMode: false,
		exportParams: {
			status: false,
			subscribeDate: false,
			unsubscribeDate: false,
			from: false,
			orders: false,
			income: false,
			lastAction: false
		},
		exportLoading: false
	};

	componentDidMount () {
		this.getSubscribers();
	}

	getSubscribers = () => {
		this.setState({
			subscribersLoading: true,
			subscribersErrorLoading: false
		});

		axios.get('/shop/audience').then(response => {
			this.setState({
				subscribersLoading: false
			});

			if (response.data.success) {
				this.setState({
					subscribersList: response.data.data.users
				});
			} else {
				this.setState({
					subscribersErrorLoading: true
				});
			}
		}).catch(error => {
			this.setState({
				subscribersLoading: false,
				subscribersErrorLoading: true
			});
		});
	};

	getSubscribersList = () => {
		const { 
			subscribersList, 
			subscribersPage, 
			subscribersPerPage 
		} = this.state;

		let renderSubscribersList = null;

		if (subscribersList.length === 0) {
			renderSubscribersList = (
				<tr>
					<td colSpan="5">
						<div className="text-center">Не найдено.</div>
					</td>
				</tr>
			);

			return renderSubscribersList;
		}

		renderSubscribersList = 
			subscribersList
				.slice(subscribersPage * subscribersPerPage, subscribersPerPage * (subscribersPage + 1))
				.map((subscriber, i) => (
					<tr key={subscriber.id}>
						<td>{subscriber.uuid}</td>
						<td>{subscriber.email}</td>
						<td>{subscriber.sourceID}</td>
						<td>{subscriber.subscribeDate}</td>
						<td className="text-right">
							<ul className="subscribers-controls-list">
								<li className="control-item"><Link to={`/audience/profile/${subscriber.userID}/${subscriber.uuid}`}><FontAwesomeIcon title="Перейти в профиль пользователя" icon={faUserCircle} /></Link></li>
								<li className="control-item" onClick={() => this.triggerModalException(subscriber.id)}><FontAwesomeIcon title="Добавить в список исключений" icon={faExpeditedssl} /></li>
								<li className="control-item" onClick={() => this.triggerModalSubscriberDelete(subscriber.id)}><FontAwesomeIcon title="Удалить пользователя" icon={faTrashAlt} /></li>
							</ul>
						</td>
					</tr>
				));

		return renderSubscribersList;
	};

	getSubscribersListPagination = () => {
		const { 
			subscribersList, 
			subscribersPerPage, 
			subscribersPage 
		} = this.state;

		let renderSubscribersPagination = null;

		const pageQuantity = Math.ceil(subscribersList.length / subscribersPerPage);

		renderSubscribersPagination = (
			<Pagination>
				<PaginationItem>
					<PaginationLink first onClick={() => this.setSubscribersListPage(1)} disabled={subscribersPage === 0} />
				</PaginationItem>

				<PaginationItem>
					<PaginationLink previous onClick={() => this.setSubscribersListPage(subscribersPage + 1 - 1)} disabled={subscribersPage === 0} />
				</PaginationItem>
				
				{Array.from(Array(pageQuantity).keys())
					.map(page => Number(page) + 1)
					.map(page => (
						<PaginationItem active={subscribersPage + 1 === page}>
							<PaginationLink
								onClick={() => this.setSubscribersListPage(page)}
								disabled={subscribersPage + 1 === page}
							>
								{page}
							</PaginationLink>
						</PaginationItem>
					))}
							
				<PaginationItem>
					<PaginationLink next onClick={() => this.setSubscribersListPage(subscribersPage + 1 + 1)} disabled={subscribersPage + 1 === pageQuantity} />
				</PaginationItem>
					
				<PaginationItem>
					<PaginationLink last onClick={() => this.setSubscribersListPage(pageQuantity)} disabled={subscribersPage + 1 === pageQuantity} />
				</PaginationItem>
			</Pagination>
		);

		return renderSubscribersPagination;
	};

	setSubscribersListPage = page => {
		if (page > 0) {

			const lastPage = Math.ceil(this.state.subscribersList.length / this.state.subscribersPerPage);

			if (page > lastPage) {
				this.setState({
					subscribersPage: lastPage
				});
			} else {
				this.setState({
					subscribersPage: page - 1
				});
			}
		}
	};

	deleteSubscriber = id => {
		const { 
			subscribersList, 
			addMailsToExceptions, 
			deleteSubscriberModalOpen 
		} = this.state;

		let subscribersListTemp = subscribersList;

		const deleteIndex = subscribersListTemp.findIndex(subscriber => subscriber.id === id);

		axios.post(`/shop/user/${subscribersListTemp[deleteIndex].userID}/delete`, {
			blacklisted: addMailsToExceptions
		}).then(response => {
			if (response.data.success) {
				subscribersListTemp.splice(deleteIndex, 1);

				this.setState({
					deleteSubscriberModalOpen: !deleteSubscriberModalOpen,
					subscribersList: subscribersListTemp
				});

				if (addMailsToExceptions) {
					toast.success('Пользователь удален. Все адреса добавлены в список исключений.');
				} else {
					toast.success('Пользователь удален.');
				}
			} else {
				toast.error('Ошибка при удалении пользователя');
			}
		}).catch(error => {
			toast.error('Ошибка при удалении пользователя');
		});
	};

	addToException = id => {
		const { subscribersList, addToExceptionModalOpen } = this.state;

		let subscribersListTemp = subscribersList;

		const toExceptionIndex = subscribersListTemp.findIndex(subscriber => subscriber.id === id);

		axios.post(`/shop/user/${subscribersListTemp[toExceptionIndex].userID}/blacklist/add`)
			.then(response => {
				if (response.data.success) {
					subscribersListTemp.splice(toExceptionIndex, 1);

					this.setState({
						subscribersList: subscribersListTemp,
						addToExceptionModalOpen: !addToExceptionModalOpen
					});

					toast.success('Все адреса добавлены в список исключений.');
				} else {
					toast.error('Ошибка при добавлении пользователя в исключение');
				}
			}).catch(error => {
				toast.error('Ошибка при добавлении пользователя в исключение');
			});
	};

	triggerModalSubscriberDelete = id => {
		this.setState({
			deleteSubscriberModalOpen: !this.state.deleteSubscriberModalOpen,
			subscriberToDelete: id
		});
	};

	triggerModalException = id => {
		this.setState({
			addToExceptionModalOpen: !this.state.addToExceptionModalOpen,
			subscriberToException: id
		});
	};

	triggerModalExport = () => {
		this.setState({
			exportModalOpen: !this.state.exportModalOpen
		});
	};

	getSubscriberUuid = id => {
		const user = this.state.subscribersList.find(subscriber=> subscriber.id === id);

		return user ? user.uuid : null;
	};

	subscribersSearchValueChange = e => {
		if (e.target.value.length < 3) {
			this.setState({
				subscribersSearchValid: false
			});
		} else {
			this.setState({
				subscribersSearchValid: true
			});
		}

		this.setState({
			subscribersToSearchValue: e.target.value
		});

		if (e.target.value.length === 0 && this.state.subscribersSearchMode) {
			this.getSubscribers();

			this.setState({
				subscribersSearchMode: false,
				subscribersSearchValid: true
			});
		}
	};

	searchSubscribers = e => {
		e.preventDefault();

		const { subscribersToSearchValue } = this.state;

		if (subscribersToSearchValue.length < 3) {
			this.setState({
				subscribersSearchValid: false
			});

			return;
		}

		this.setState({
			searchSubscribersLoading: true,
			subscribersLoading: true
		});

		axios.get(`/shop/audience?search=${subscribersToSearchValue}`)
			.then(response => {
				this.setState({
					searchSubscribersLoading: false,
					subscribersLoading: false
				});

				if (response.data.success) {
					this.setState({
						subscribersList: response.data.data.users,
						subscribersSearchMode: true
					});
				} else {
					toast.error('Системная ошибка при поиске');
				}
			}).catch(error => {
				this.setState({
					searchSubscribersLoading: false,
					subscribersLoading: false
				});
				
				toast.error('Системная ошибка при поиске');
			});
	};

	exportAudience = () => {
		const { exportParams } = this.state;

		const params = {
			status: exportParams.status,
			subscribe_date: exportParams.subscribeDate,
			unsubscribe_date: exportParams.unsubscribeDate,
			from: exportParams.from,
			orders: exportParams.orders,
			income: exportParams.income,
			last_action: exportParams.lastAction
		};

		let queryParamsArr = [];

		for (let param in params) {
			queryParamsArr.push(`${param}=${params[param]}`);
		}

		const queryParams = `?${queryParamsArr.join('&')}`;

		this.setState({
			exportLoading: true
		});

		axios.get(`/shop/audience/export${queryParams}`).then(response => {
			this.setState({
				exportLoading: false
			});

			if (response.data.success) {
				if (typeof window !== 'undefined') {
					window.location.href = response.data.data.url;
				}
			} else {
				toast.error('Ошибка при экспорте');
			}
		}).catch(error => {
			this.setState({
				exportLoading: false
			});

			toast.error('Ошибка при экспорте');
		});
	};

	exportParamChange = (name, value) => {
		this.setState({
			exportParams: {
				...this.state.exportParams,
				[name]: value
			}
		});
	};

	render () {
		const {
			subscribersList,
			deleteSubscriberModalOpen,
			subscribersSearchValid,
			addToExceptionModalOpen,
			exportModalOpen,
			subscriberToDelete,
			subscriberToException,
			subscribersToSearchValue,
			addMailsToExceptions,
			searchSubscribersLoading,
			subscribersPerPage,
			subscribersLoading,
			subscribersErrorLoading,
			exportParams,
			exportLoading
		} = this.state;

		return (
			<ContentWrapper>
				<Modal isOpen={deleteSubscriberModalOpen} toggle={() => this.setState({ deleteSubscriberModalOpen: !deleteSubscriberModalOpen })}>
					<ModalBody className="text-center">
						<h3>Удалить все сведения о пользователя?</h3>

						<p>Все данные пользователя uuid {subscriberToDelete ? this.getSubscriberUuid(subscriberToDelete) : null} будут удалены безвозвратно. Отменить данное действие невозможно.</p>

						<FormGroup>
							<label className="c-checkbox">
	                            <input 
	                            	type="checkbox" 
	                            	value={this.state.addMailsToExceptions} 
	                            	onChange={() => this.setState({ addMailsToExceptions: !addMailsToExceptions })}
	                            />

	                            <FontAwesomeIcon icon={faCheck} /> Добавить почтовые адреса в список исключений
	                        </label>
	                    </FormGroup>

						<div>
							<Button color="primary" onClick={() => this.deleteSubscriber(subscriberToDelete)}>Удалить</Button>
							<Button color="secondary" className="ml-2" onClick={() => this.setState({ deleteSubscriberModalOpen: !deleteSubscriberModalOpen })}>Отмена</Button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={addToExceptionModalOpen} toggle={() => this.setState({ addToExceptionModalOpen: !addToExceptionModalOpen })}>
					<ModalBody className="text-center">
						<h3>Добавить в список исключений?</h3>

						<p>Все известные почтовые адреса пользователя <b>uuid {subscriberToException ? this.getSubscriberUuid(subscriberToException) : null}</b> будут добавлены в список исключений. Добавленные вами в СИ адреса можно восстановить вручную в любой момент времени.</p>

						<div>
							<Button color="primary" onClick={() => this.addToException(subscriberToException)}>Добавить в исключение</Button>
							<Button color="secondary" className="ml-2" onClick={() => this.setState({ addToExceptionModalOpen: !addToExceptionModalOpen })}>Отмена</Button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={exportModalOpen} toggle={() => this.setState({ exportModalOpen: !exportModalOpen })}>
					<ModalBody className="text-center">
						<h3>Экспорт в формате CSV</h3>

						<p>Укажите набор данных для экспорта (по умолчанию экспортируются уникальный идентификатор и привязанные к нему почтовые адреса)</p>

						<FormGroup className="export-checkboxes-group">
							<div className="row">
								<div className="col-sm-6 text-left">

									<label className="c-checkbox">
		                                <input 
		                                	type="checkbox"
		                                	value={exportParams.status}
		                                	onChange={e => this.exportParamChange('status', !exportParams.status)}
		                                	checked={exportParams.status === true} 
		                                />

		                                <FontAwesomeIcon icon={faCheck} /> Статус подписчика
		                            </label>

		                            <label className="c-checkbox">
		                                <input 
		                                	type="checkbox"
		                                	value={exportParams.subscribeDate}
		                                	onChange={e => this.exportParamChange('subscribeDate', !exportParams.subscribeDate)}
		                                	checked={exportParams.subscribeDate === true} 
		                                />

		                                <FontAwesomeIcon icon={faCheck} /> Дата подписки
		                            </label>
									
									<label className="c-checkbox">
		                                <input 
		                                	type="checkbox"
		                                	value={exportParams.unsubscribeDate}
		                                	onChange={e => this.exportParamChange('unsubscribeDate', !exportParams.unsubscribeDate)}
		                                	checked={exportParams.unsubscribeDate === true} 
		                                />

		                                <FontAwesomeIcon icon={faCheck} /> Дата отписки
		                            </label>

		                            <label className="c-checkbox">
		                                <input 
		                                	type="checkbox"
		                                	value={exportParams.from}
		                                	onChange={e => this.exportParamChange('from', !exportParams.from)}
		                                	checked={exportParams.from === true} 
		                                />

		                                <FontAwesomeIcon icon={faCheck} /> Источник подписки
		                            </label>

								</div>
								<div className="col-sm-6 text-left">

		                            <label className="c-checkbox">
		                                <input 
		                                	type="checkbox"
		                                	value={exportParams.orders}
		                                	onChange={e => this.exportParamChange('orders', !exportParams.orders)}
		                                	checked={exportParams.orders === true} 
		                                />

		                                <FontAwesomeIcon icon={faCheck} /> Количество заказов
		                            </label>
									
									<label className="c-checkbox">
		                                <input 
		                                	type="checkbox"
		                                	value={exportParams.income}
		                                	onChange={e => this.exportParamChange('income', !exportParams.income)}
		                                	checked={exportParams.income === true} 
		                                />

		                                <FontAwesomeIcon icon={faCheck} /> Общий доход
		                            </label>

		                            <label className="c-checkbox">
		                                <input 
		                                	type="checkbox"
		                                	value={exportParams.lastAction}
		                                	onChange={e => this.exportParamChange('lastAction', !exportParams.lastAction)}
		                                	checked={exportParams.lastAction === true} 
		                                />

		                                <FontAwesomeIcon icon={faCheck} /> Дата последнего действия
		                            </label>

								</div>
							</div>
	                    </FormGroup>

						<div>
							<Button 
								color="primary" 
								onClick={() => this.exportAudience()} 
								disabled={exportLoading}
							>
								Экспорт {exportLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
							</Button>
							
							<Button 
								color="secondary" 
								className="ml-2" 
								onClick={() => this.setState({ exportModalOpen: !exportModalOpen })}
							>
								Отмена
							</Button>
						</div>
					</ModalBody>
				</Modal>

				{subscribersErrorLoading ? (
					<Alert className="text-center" color="danger">
						Ошибка при загрузке данных

						<br/>

						<Button 
							className="mt-2" 
							size="xs" 
							color="secondary" 
							onClick={() => this.getSubscribers()}
						>
							Повторить запрос
						</Button>
					</Alert>
				) : (
					<Card>
						<CardHeader className="audience-card-header">
							<form className="audience-search-form" onSubmit={e => this.searchSubscribers(e)}>
								<InputGroup>
									<Input 
										invalid={!subscribersSearchValid}
										value={subscribersToSearchValue} 
										onChange={e => this.subscribersSearchValueChange(e)} 
										placeholder="Введите email или uuid..." 
									>

									</Input>
									
									<InputGroupAddon addonType="append">
										<Button type="submit" disabled={searchSubscribersLoading}>
											Найти {searchSubscribersLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
										</Button>
									</InputGroupAddon>

									<FormFeedback invalid={subscribersSearchValid ? false : true}>Не меньше 3 символов для поиска</FormFeedback>
								</InputGroup>
							</form>

							<div className="header-btns-control">
								<Link to="/audience/exceptions"><Button color="primary" size="lg">Список исключений</Button></Link>
								<Button className="ml-3" onClick={() => this.setState({ exportModalOpen: !exportModalOpen })} color="success" size="lg">Экспорт</Button>
							</div>
						</CardHeader>

						<CardBody>

							<Table className="subscribers-list-table" striped responsive>
								<thead>
									<tr>
										<th>UUID</th>
										<th>Email</th>
										<th>Источник подписки (id)</th>
										<th>Дата подписки</th>
									</tr>
								</thead>

								<tbody>
									{subscribersLoading ? (
										<tr><td colSpan="4">
											<SpinnerLoader />
										</td></tr>
									) : this.getSubscribersList()}
								</tbody>
							</Table>

							{!subscribersLoading && subscribersList.length > subscribersPerPage && (
								<div className="mt-5">
									{this.getSubscribersListPagination()}
								</div>
							)}

						</CardBody>
					</Card>
				)}
			</ContentWrapper>
		);
	}
}

export default AudiencePage;
