import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Card,
	CardHeader,
	CardBody,
	InputGroup,
	InputGroupAddon,
	Input,
	Button,
	Pagination,
	PaginationItem,
	Alert,
	PaginationLink,
	Modal,
	Form,
	FormGroup,
	Label,
	ModalBody,
	FormFeedback,
	Table,
	Spinner,
	Row,
	Col
} from 'reactstrap';
import uuid from 'react-uuid';
import moment from 'moment';
import axios from '../../../axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUserCircle,
	faSearchPlus,
	faInfoCircle,
	faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { faExpeditedssl } from '@fortawesome/free-brands-svg-icons';
import DateRangePicker from 'react-bootstrap-daterangepicker';

// Components
import ContentWrapper from '../../../components/ContentWrapper';
import SpinnerLoader from '../../../components/SpinnerLoader';

import './AdminModerationPage.sass';

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const WEEKDAYS_SHORT = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вск'];

const usersListMock = [
	{
		id: uuid(),
		name: 'Amazon',
		email: 'email@email.ru',
		type: 'letter',
		date: '22.05.2019 23:22',
		dateModeration: '',
		status: 'pending'
	},
	{
		id: uuid(),
		name: 'Google',
		email: 'email2@email.ru',
		type: 'letter',
		date: '22.05.2019 23:22',
		dateModeration: '',
		status: 'pending'
	},
	{
		id: uuid(),
		name: 'Yandex',
		email: 'email3@email.ru',
		type: 'form',
		date: '22.05.2019 23:22',
		dateModeration: '22.05.2019 23:22',
		status: 'pending'
	},
	{
		id: uuid(),
		name: 'Фирма 1',
		email: 'email55@email.ru',
		type: 'sender',
		date: '22.05.2019 23:22',
		dateModeration: '',
		status: 'pending'
	},
	{
		id: uuid(),
		name: 'Фирма 2',
		email: 'em5@mail.ru',
		type: 'letter',
		date: '22.05.2019 23:22',
		dateModeration: '',
		status: 'approved'
	},
	{
		id: uuid(),
		name: 'Amazon',
		email: 'email@email.ru',
		type: 'letter',
		date: '22.05.2019 23:22',
		dateModeration: '22.05.2019 23:22',
		status: 'approved'
	},
	{
		id: uuid(),
		name: 'Google',
		email: 'email2@email.ru',
		type: 'letter',
		date: '22.05.2019 23:22',
		dateModeration: '22.05.2019 23:22',
		status: 'rejected'
	},
	{
		id: uuid(),
		name: 'Yandex',
		email: 'email3@email.ru',
		type: 'sender',
		date: '22.05.2019 23:22',
		dateModeration: '22.05.2019 23:22',
		status: 'rejected'
	},
	{
		id: uuid(),
		name: 'Фирма 1',
		email: 'email55@email.ru',
		type: 'letter',
		date: '22.05.2019 23:22',
		dateModeration: '',
		status: 'pending'
	},
	{
		id: uuid(),
		name: 'Фирма 2',
		email: 'em5@mail.ru',
		type: 'letter',
		date: '22.05.2019 23:22',
		dateModeration: '',
		status: 'pending'
	}
];

class AdminModerationPage extends Component {

	state = {
		usersList: [],
		usersLoading: false,
		usersErrorLoading: false,
		usersPage: 0,
		usersPerPage: 10,

		moderateUsersFilter: {
			companyName: '',
			email: '',
			type: 'all',
			status: 'all',

			dateFilterFrom: moment(new Date()).subtract(7, 'day'),
			dateFilterTo: moment(new Date()),
			dateInputValue: moment(new Date()).subtract(7, 'day').format('DD-MM-YYYY') + ' / ' + moment(new Date()).format('DD-MM-YYYY'),

			dateModerateFilterFrom: moment(new Date()).subtract(7, 'day'),
			dateModerateFilterTo: moment(new Date()),
			dateModerateInputValue: moment(new Date()).subtract(7, 'day').format('DD-MM-YYYY') + ' / ' + moment(new Date()).format('DD-MM-YYYY'),
		},

		moderateUsersFilterLoading: false,

		approveUserLoading: false,
		rejectUserLoading: false,
		approveSenderDataLoading: false,
		rejectSenderDataLoading: false,

		userToApprove: null,
		userToReject: null,
		senderDataToApprove: null,
		senderDataToReject: null,

		rejectUserCommentValue: '',

		approveUserModalOpen: false,
		rejectUserModalOpen: false,
		previewModalOpen: false,
		senderDataModalOpen: false
	};

	componentDidMount () {
		this.getUsers();
	}

	getUsers = () => {
		this.setState({
			usersLoading: true,
			usersErrorLoading: false
		});

		setTimeout(() => {
			this.setState({
				usersList: usersListMock,
				usersLoading: false
			});
		}, 500);

		// axios.get('/shop/audience').then(response => {
		// 	this.setState({
		// 		usersLoading: false
		// 	});

		// 	if (response.data.success) {
		// 		this.setState({
		// 			usersList: response.data.data.users
		// 		});
		// 	} else {
		// 		this.setState({
		// 			usersErrorLoading: true
		// 		});
		// 	}
		// }).catch(error => {
		// 	this.setState({
		// 		usersLoading: false,
		// 		usersErrorLoading: true
		// 	});
		// });
	};

	getTypeName = type => {
		let typeName = '';

		switch (type) {
			case 'letter':
				typeName = 'Письмо';

				break;

			case 'form':
				typeName = 'Форма подписки';
				
				break;

			case 'sender':
				typeName = 'Данные отправителя';
				
				break;

			default:
				typeName = '';
		}

		return typeName;
	};

	getStatusName = status => {
		let statusName = '';

		switch (status) {
			case 'approved':
				statusName = 'Принято';

				break;

			case 'rejected':
				statusName = 'Отклонено';
				
				break;

			case 'pending':
				statusName = 'Ожидает модерации';
				
				break;

			default:
				statusName = '';
		}

		return statusName;
	};

	getStatusColor = status => {
		let statusColor = '';

		switch (status) {
			case 'approved':
				statusColor = 'text-spring-green';

				break;

			case 'rejected':
				statusColor = 'text-luminous-red';
				
				break;

			case 'pending':
				statusColor = 'text-orange';
				
				break;

			default:
				statusColor = '';
		}

		return statusColor;
	};

	getUsersList = () => {
		const { 
			usersList, 
			usersPage, 
			usersPerPage 
		} = this.state;

		let renderUsersList = null;

		if (usersList.length === 0) {
			renderUsersList = (
				<tr>
					<td colSpan="7">
						<div className="text-center">Не найдено.</div>
					</td>
				</tr>
			);

			return renderUsersList;
		}

		renderUsersList = 
			usersList
				.slice(usersPage * usersPerPage, usersPerPage * (usersPage + 1))
				.map((user, i) => (
					<tr key={user.id}>
						<td>{user.name}</td>
						<td>{user.email}</td>
						<td>{this.getTypeName(user.type)}</td>
						<td>{user.date}</td>
						<td>{user.dateModeration}</td>
						<td className={this.getStatusColor(user.status)}>{this.getStatusName(user.status)}</td>
						<td className="text-right">
							{this.getUserControlsList(user.id, user.status)}
						</td>
					</tr>
				));

		return renderUsersList;
	};

	getUserControlsList = (userId, userStatus) => {
		let controls = [];

		switch (userStatus) {
			case 'approved':
				controls = ['preview', 'profile', 'approve', 'reject'];

				break;

			case 'rejected':
				controls = ['info'];

				break;

			default:
				controls = [];
		}

		controls = controls.map(control => ({
			id: uuid(),
			name: control
		}));

		const renderControlsList = controls.map((control, i) => {
			let controlItem = null;

			switch (control.name) {
				case 'preview':
					controlItem = <li key={control.id} className="control-item text-steel-blue" onClick={() => this.triggerModalPreview()}><FontAwesomeIcon title="Предпросмотр в модальном окне" icon={faSearchPlus} /></li>;
					
					break;

				case 'profile':
					controlItem = <li key={control.id} className="control-item"><Link to={`/admin/users/profile/${userId}`}><FontAwesomeIcon title="Перейти в ЛК пользователя" icon={faUserCircle} /></Link></li>;
					
					break;

				case 'approve':
					controlItem = <li key={control.id} className="control-item text-spring-green" onClick={() => this.triggerModalApprove(userId)}><FontAwesomeIcon title="Утвердить изменения" icon={faCheckCircle} /></li>;
					
					break;

				case 'reject':
					controlItem = <li key={control.id} className="control-item text-luminous-red" onClick={() => this.triggerModalReject(userId)}><FontAwesomeIcon title="Отклонить изменения" icon={faExpeditedssl} /></li>;
					
					break;

				case 'info':
					controlItem = <li key={control.id} className="control-item text-sky-blue" onClick={() => this.triggerModalSenderData(userId)}><FontAwesomeIcon title="Посмотреть причину отклонения" icon={faInfoCircle} /></li>;
					
					break;

				default:
					controlItem = null;
			}

			return controlItem;
		}).filter(control => !!control);

		return (
			<ul className="admin-moderate-users-controls-list">
				{renderControlsList}
			</ul>
		);
	};

	getUsersListPagination = () => {
		const { 
			usersList, 
			usersPerPage, 
			usersPage 
		} = this.state;

		let renderUsersPagination = null;

		const pageQuantity = Math.ceil(usersList.length / usersPerPage);

		renderUsersPagination = (
			<Pagination>
				<PaginationItem>
					<PaginationLink first onClick={() => this.setUsersListPage(1)} disabled={usersPage === 0} />
				</PaginationItem>

				<PaginationItem>
					<PaginationLink previous onClick={() => this.setUsersListPage(usersPage + 1 - 1)} disabled={usersPage === 0} />
				</PaginationItem>
				
				{Array.from(Array(pageQuantity).keys())
					.map(page => Number(page) + 1)
					.map(page => (
						<PaginationItem active={usersPage + 1 === page}>
							<PaginationLink
								onClick={() => this.setUsersListPage(page)}
								disabled={usersPage + 1 === page}
							>
								{page}
							</PaginationLink>
						</PaginationItem>
					))}
							
				<PaginationItem>
					<PaginationLink next onClick={() => this.setUsersListPage(usersPage + 1 + 1)} disabled={usersPage + 1 === pageQuantity} />
				</PaginationItem>
					
				<PaginationItem>
					<PaginationLink last onClick={() => this.setUsersListPage(pageQuantity)} disabled={usersPage + 1 === pageQuantity} />
				</PaginationItem>
			</Pagination>
		);

		return renderUsersPagination;
	};

	setUsersListPage = page => {
		const { usersList, usersPerPage } = this.state;

		if (page > 0) {

			const lastPage = Math.ceil(usersList.length / usersPerPage);

			if (page > lastPage) {
				this.setState({
					usersPage: lastPage
				});
			} else {
				this.setState({
					usersPage: page - 1
				});
			}
		}
	};

	moderateUsersFilterSubmit = e => {
		e.preventDefault();

		this.setState({
			moderateUsersFilterLoading: true
		});

		setTimeout(() => {
			this.setState({
				moderateUsersFilterLoading: false
			});

			console.log(this.state.moderateUsersFilter);
		}, 500);
	};

	moderateUserFilterElemChange = (name, value) => {
		const { moderateUsersFilter } = this.state;

		this.setState({
			moderateUsersFilter: {
				...moderateUsersFilter,
				[name]: value
			}
		});
	};

	dateFilterChange = (e, picker) => {
		e.preventDefault();

		picker.isShowing = true;

		const { moderateUsersFilter } = this.state;
		
		this.setState({
			moderateUsersFilter: {
				...moderateUsersFilter,
				dateFilterFrom: picker.startDate,
				dateFilterTo: picker.endDate,
				dateInputValue: moment(picker.startDate).format('DD-MM-YYYY') + ' / ' + moment(picker.endDate).format('DD-MM-YYYY'),
			}
		})
	};

	dateModerateFilterChange = (e, picker) => {
		e.preventDefault();
		
		picker.isShowing = true;

		const { moderateUsersFilter } = this.state;

		this.setState({
			moderateUsersFilter: {
				...moderateUsersFilter,
				dateModerateFilterFrom: picker.startDate,
				dateModerateFilterTo: picker.endDate,
				dateModerateInputValue: moment(picker.startDate).format('DD-MM-YYYY') + ' / ' + moment(picker.endDate).format('DD-MM-YYYY'),
			}
		})
	};

	rejectUserCommentChange = comment => {
		this.setState({
			rejectUserCommentValue: comment
		});
	};

	approveUser = () => {
		this.setState({
			approveUserLoading: true
		});

		setTimeout(() => {
			this.setState({
				approveUserLoading: false,
				approveUserModalOpen: !this.state.approveUserModalOpen
			});	

			toast.success('Данные пользователя утверждены.');		
		}, 500);
	};

	rejectUser = () => {
		this.setState({
			rejectUserLoading: true
		});

		setTimeout(() => {
			this.setState({
				rejectUserLoading: false,
				rejectUserModalOpen: !this.state.rejectUserModalOpen
			});	

			toast.success('Данные пользователя отклонены.');
		}, 500);
	};

	approveSenderData = () => {
		this.setState({
			approveSenderDataLoading: true
		});

		setTimeout(() => {
			this.setState({
				approveSenderDataLoading: false,
				senderDataModalOpen: !this.state.senderDataModalOpen
			});		

			toast.success('Данные отправителя утверждены.');	
		}, 500);
	};

	rejectSenderData = () => {
		this.setState({
			rejectSenderDataLoading: true
		});

		setTimeout(() => {
			this.setState({
				rejectSenderDataLoading: false,
				senderDataModalOpen: !this.state.senderDataModalOpen
			});	

			toast.success('Данные отправителя отклонены.');
		}, 500);
	};


	triggerModalPreview = () => {
		this.setState({
			previewModalOpen: !this.state.previewModalOpen
		});
	};

	triggerModalApprove = id => {
		this.setState({
			userToApprove: id,
			approveUserModalOpen: !this.state.approveUserModalOpen
		});
	};

	triggerModalReject = id => {
		this.setState({
			userToReject: id,
			rejectUserModalOpen: !this.state.rejectUserModalOpen
		});
	};

	triggerModalSenderData = id => {
		this.setState({
			senderDataToApprove: id,
			senderDataToReject: id,
			senderDataModalOpen: !this.state.senderDataModalOpen
		});
	};


	render () {
		const { 
			usersList,
			usersPerPage,
			usersLoading,
			usersErrorLoading,
			
			moderateUsersFilter,
			moderateUsersFilterLoading,
			
			approveUserModalOpen,
			rejectUserModalOpen,
			previewModalOpen,
			senderDataModalOpen,

			userToApprove,
			userToReject,
			senderDataToApprove,
			senderDataToReject,

			approveUserLoading,
			rejectUserLoading,
			approveSenderDataLoading,
			rejectSenderDataLoading,

			rejectUserCommentValue
		} = this.state;

		return (
			<ContentWrapper>
				<Modal isOpen={approveUserModalOpen} toggle={() => this.triggerModalApprove()}>
					<ModalBody className="text-center">
						<h3>Утвердить изменения?</h3>

						<p>Нажимая "утвердить" вы подтверждаете, что внимательно изучили изменения которые внес пользователь.</p>

						<div>
							<Button 
								color="primary" 
								onClick={() => this.approveUser(userToApprove)}
								disabled={approveUserLoading}
							>
								Утвердить {approveUserLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
							</Button>

							<Button 
								color="secondary" 
								className="ml-2" 
								onClick={() => this.triggerModalApprove()}
							>
								Отмена
							</Button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={rejectUserModalOpen} toggle={() => this.triggerModalReject()}>
					<ModalBody className="text-center">
						<h3>Добавить в список исключений</h3>

						<p>Данные адреса можно будет восстановить вручную (удалить из списка исключений)</p>

						<FormGroup>
							<Input 
								type="textarea" 
								rows="3" 
								value={rejectUserCommentValue} 
								onChange={e => this.rejectUserCommentChange(e.target.value)} 
								placeholder="Поле для ввода комментария для пользователя - он будет отражен в уведомлении, в его личном кабинете"
							/>
						</FormGroup>

						<div className="mt-3">
							<Button 
								type="submit"
								color="primary" 
								onClick={e => this.rejectUser(userToReject)}
								disabled={rejectUserLoading}
							>
								Отклонить {rejectUserLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
							</Button>
							
							<Button 
								color="secondary" 
								className="ml-2" 
								onClick={() => this.triggerModalReject()}
								disabled={rejectUserLoading}
							>
								Отмена
							</Button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={previewModalOpen} toggle={() => this.triggerModalPreview()}>
					<ModalBody className="text-center">
						<h3>Предпросмотр</h3>

						<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio tempore cum dignissimos maiores autem pariatur molestias corrupti, doloribus aut delectus, dolorem est odit. Doloremque consequatur perspiciatis, non quod adipisci tenetur!</p>
					</ModalBody>
				</Modal>

				<Modal isOpen={senderDataModalOpen} toggle={() => this.triggerModalSenderData()}>
					<ModalBody className="text-center">
						<h3>Утверждение данных отправителя</h3>

						<p><b>Имя отправителя:</b> Amazon</p>
						<p><b>Email отправителя:</b> info@amazon.com</p>

						<div className="mt-4">
							<Button 
								color="success" 
								onClick={() => this.approveSenderData(senderDataToApprove)}
								disabled={approveSenderDataLoading}
							>
								Утвердить {approveSenderDataLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
							</Button>

							<Button 
								color="danger" 
								className="ml-2"
								onClick={() => this.rejectSenderData(senderDataToReject)}
								disabled={rejectSenderDataLoading}
							>
								Отклонить {rejectSenderDataLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
							</Button>

							<Button 
								color="secondary" 
								className="ml-2" 
								onClick={() => this.triggerModalSenderData()}
							>
								Закрыть
							</Button>
						</div>
					</ModalBody>
				</Modal>

				{usersErrorLoading ? (
					<Alert className="text-center" color="danger">
						Ошибка при загрузке данных

						<br/>

						<Button 
							className="mt-2" 
							size="xs" 
							color="secondary" 
							onClick={() => this.getUsers()}
						>
							Повторить запрос
						</Button>
					</Alert>
				) : (
					<Card>
						<CardHeader className="admin-moderate-users-card-header">
							<Form onSubmit={e => this.moderateUsersFilterSubmit(e)}>
								<Row>
									<Col xs={4}>

										<FormGroup>
											<Label for="adminModerateUsersCompanyInput">Имя комании</Label>

											<Input 
												id="adminModerateUsersCompanyInput" 
												type="text" 
												value={moderateUsersFilter.companyName}
												onChange={e => this.moderateUserFilterElemChange('companyName', e.target.value)}
											/>
										</FormGroup>

									</Col>
									<Col xs={4}>

										<FormGroup>
											<Label for="adminModerateUsersEmailInput">Email (логин)</Label>

											<Input 
												id="adminModerateUsersEmailInput" 
												type="text" 
												value={moderateUsersFilter.email}
												onChange={e => this.moderateUserFilterElemChange('email', e.target.value)}
											/>
										</FormGroup>

									</Col>
									<Col xs={4}>

										<FormGroup>
											<Label for="adminModerateUsersTypeInput">Тип</Label>

											<Input 
												id="adminModerateUsersTypeInput" 
												type="select"
												value={moderateUsersFilter.type}
												onChange={e => this.moderateUserFilterElemChange('type', e.target.value)}
											>
												<option>Все события</option>
												<option>Событие 1</option>
												<option>Событие 2</option>
												<option>Событие 3</option>
												<option>Событие 4</option>
											</Input>
										</FormGroup>

									</Col>
									<Col xs={4}>

										<FormGroup>
											<Label for="adminModerateUsersStatusInput">Статус</Label>

											<Input 
												id="adminModerateUsersStatusInput" 
												type="select"
												value={moderateUsersFilter.status}
												onChange={e => this.moderateUserFilterElemChange('status', e.target.value)}
											>
												<option>Все статусы</option>
												<option>Статус 1</option>
												<option>Статус 2</option>
												<option>Статус 3</option>
												<option>Статус 4</option>
											</Input>
										</FormGroup>

									</Col>
									<Col xs={4}>

										<FormGroup>
											<Label>Дата поступления</Label>

											<DateRangePicker
									        	startDate={moderateUsersFilter.dateFilterFrom}
									        	endDate={moderateUsersFilter.dateFilterTo}
									        	onEvent={this.dateFilterChange}
	    										alwaysShowCalendars={true}
	    										autoclose={false}
	    										closeOnDateSelect={false}
									        	locale={{
									        		daysOfWeek: WEEKDAYS_SHORT,
									        		monthNames: MONTHS,
									        		applyLabel: 'Применить',
	    											cancelLabel: 'Сброс',
	    											customRangeLabel: "Своя дата"

									        	}}
									        	ranges={{
									                'Сегодня': [moment(new Date()), moment(new Date())],
									                'Вчера': [moment(new Date()).subtract(1, 'days'), moment(new Date()).subtract(1, 'days')],
									                'Последняя неделя': [moment(new Date()).subtract(6, 'days'), moment()],                   
									                'Этот месяц': [moment(new Date()).startOf('month'), moment(new Date()).endOf('month')]                   
									            }}
									        >
										        <Input 
										        	type="text"
										        	value={moderateUsersFilter.dateInputValue} 
										        />
										    </DateRangePicker>
										</FormGroup>

									</Col>
									<Col xs={4}>

										<FormGroup>
											<Label>Дата модерации</Label>

											<DateRangePicker
									        	startDate={moderateUsersFilter.dateModerateFilterFrom}
									        	endDate={moderateUsersFilter.dateModerateFilterTo}
									        	onEvent={this.dateModerateFilterChange}
	    										alwaysShowCalendars={true}
	    										autoclose={false}
	    										closeOnDateSelect={false}
									        	locale={{
									        		daysOfWeek: WEEKDAYS_SHORT,
									        		monthNames: MONTHS,
									        		applyLabel: 'Применить',
	    											cancelLabel: 'Сброс',
	    											customRangeLabel: "Своя дата"

									        	}}
									        	ranges={{
									                'Сегодня': [moment(new Date()), moment(new Date())],
									                'Вчера': [moment(new Date()).subtract(1, 'days'), moment(new Date()).subtract(1, 'days')],
									                'Последняя неделя': [moment(new Date()).subtract(6, 'days'), moment()],                   
									                'Этот месяц': [moment(new Date()).startOf('month'), moment(new Date()).endOf('month')]                   
									            }}
									        >
										        <Input 
										        	type="text" 
										        	value={moderateUsersFilter.dateModerateInputValue} 
										        />
										    </DateRangePicker>
										</FormGroup>

									</Col>
									<Col xs={12}>

										<Button 
											type="submit" 
											className="text-uppercase" 
											color="primary" 
											size="lg" 
											block
											disabled={moderateUsersFilterLoading}
										>
											Фильтр {moderateUsersFilterLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
										</Button>

									</Col>
								</Row>
							</Form>
						</CardHeader>

						<CardBody>
							<Table className="admin-moderate-users-list-table" striped responsive>
								<thead>
									<tr>
										<th>Имя компании</th>
										<th>Email (логин)</th>
										<th>Тип</th>
										<th>Дата поступления</th>
										<th>Дата модерации</th>
										<th>Статус</th>
									</tr>
								</thead>

								<tbody>
									{usersLoading ? (
										<tr><td colSpan="6">
											<SpinnerLoader />
										</td></tr>
									) : this.getUsersList()}
								</tbody>
							</Table>

							{!usersLoading && usersList.length > usersPerPage && (
								<div className="mt-5">
									{this.getUsersListPagination()}
								</div>
							)}
						</CardBody>
					</Card>
				)}
			</ContentWrapper>
		);
	}
}

export default AdminModerationPage;
