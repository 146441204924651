import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import AuthRoute from './components/AuthRoute';

// Components
import Layout from './hoc/Layout';
import BasePage from './hoc/BasePage';
import FullPageLoader from './components/FullPageLoader';

// Views
import InformationPage from './views/InformationPage';
import FormsListPage from './views/Forms/FormsListPage';
import EditForm from './views/Forms/EditForm';
import EditFormEditor from './views/Forms/EditFormEditor';
import TriggersListPage from './views/Triggers/TriggersListPage';
import TriggerEditPage from './views/Triggers/TriggerEditPage';
import LoginPage from './views/LoginPage';
import RegisterPage from './views/RegisterPage';
import RecoverPage from './views/RecoverPage';
import RecoverNewPage from './views/RecoverNewPage';
import TagsListPage from './views/TagsListPage';
import AudiencePage from './views/AudiencePage';
import AudienceExceptions from './views/AudienceExceptions';
import SubscriberProfilePage from './views/SubscriberProfilePage';
import ProfileIntegrationSettings from './views/Profile/ProfileIntegrationSettings';
import ProfileAccountSettings from './views/Profile/ProfileAccountSettings';
import ProfileShopSettings from './views/Profile/ProfileShopSettings';
import ConfirmRegister from './views/ConfirmRegister';
import ConfirmEmailPage from './views/ConfirmEmailPage';
import PaymentPage from './views/PaymentPage';
import PaymentSuccessPage from './views/PaymentSuccessPage';
import PaymentErrorPage from './views/PaymentErrorPage';
import NotFound from './views/NotFound';
import InternalErrorPage from './views/InternalErrorPage';

import AdminUsersListPage from './views/Admin/AdminUsersListPage';
import AdminUserProfilePage from './views/Admin/AdminUserProfilePage';
import AdminModerationPage from './views/Admin/AdminModerationPage';
import AdminPaymentPage from './views/Admin/AdminPaymentPage';
import AdminAudienceExceptionsPage from './views/Admin/AdminAudienceExceptionsPage';

const listOfPages = [
    '/login',
    '/register',
    '/register/confirm',
    '/email/confirm',
    '/recover',
    '/recover/new',
    '/notfound',
    '/internal-error'
];

class Routes extends Component {

    isAuthRoute = () => {
        let isAuthRoute = false;

        listOfPages.forEach(page => {
            if (this.props.location.pathname.indexOf(page) > -1) {
                isAuthRoute = true;
            }
        });

        return isAuthRoute;
    };

    render () {
        let routes = null;

        if (this.props.authPending) {
            routes = <FullPageLoader />;

            return routes;
        }

        if (this.isAuthRoute()) {
            routes =  (
                <BasePage>
                    <Switch>
                        <AuthRoute path="/login" component={LoginPage} exact />
                        <AuthRoute path="/register" component={RegisterPage} exact />
                        <AuthRoute path="/register/confirm" component={ConfirmRegister} exact />
                        <AuthRoute path="/recover" component={RecoverPage} exact />
                        <AuthRoute path="/recover/new" component={RecoverNewPage} exact />

                        <PrivateRoute path="/email/confirm" component={ConfirmEmailPage} exact />
                        
                        <Route path="/notfound" component={NotFound} exact />
                        <Route path="/internal-error" component={InternalErrorPage} exact />
                    </Switch>
                </BasePage>
            )
        } else {
            routes = (
                <Layout>
                    <Switch>
                        <PrivateRoute path="/information" component={InformationPage} exact />
                        <PrivateRoute path="/subscriptions" component={FormsListPage} exact />
                        <PrivateRoute path="/form/:formId/edit" component={EditForm} exact />
                        <PrivateRoute path="/form/:formId/editor/edit" component={EditFormEditor} exact />
                        <PrivateRoute path="/triggers" component={TriggersListPage} exact />
                        <PrivateRoute path="/trigger/:triggerId/edit" component={TriggerEditPage} exact />
                        <PrivateRoute path="/tags" component={TagsListPage} exact />
                        <PrivateRoute path="/audience" component={AudiencePage} exact />
                        <PrivateRoute path="/audience/exceptions" component={AudienceExceptions} exact />
                        <PrivateRoute path="/audience/profile/:profileId/:uuid" component={SubscriberProfilePage} exact />
                        <PrivateRoute path="/profile/settings/account" component={ProfileAccountSettings} exact />
                        <PrivateRoute path="/profile/settings/shop" component={ProfileShopSettings} exact />
                        <PrivateRoute path="/profile/settings/integration" component={ProfileIntegrationSettings} exact />
                        <PrivateRoute path="/payment" component={PaymentPage} exact />
                        <PrivateRoute path="/payment/success" component={PaymentSuccessPage} exact />
                        <PrivateRoute path="/payment/error" component={PaymentErrorPage} exact />

                        <PrivateRoute path="/" component={AdminUsersListPage} exact />
                        <PrivateRoute path="/admin/users/profile/:userId" component={AdminUserProfilePage} exact />
                        <PrivateRoute path="/admin/audience/exceptions" component={AdminAudienceExceptionsPage} exact />
                        <PrivateRoute path="/admin/payment" component={AdminPaymentPage} exact />
                        <PrivateRoute path="/admin/moderation" component={AdminModerationPage} exact />

                        <Redirect to="/notfound"/>
                    </Switch>
                </Layout>
            );
        }

        return routes
    }
}

export default connect(
    state => ({
        isAuthenticated: state.auth.isAuthenticated,
        authPending: state.auth.authPending
    }),
    null
)(withRouter(Routes));
