import React, { Component } from 'react';
import { Input, Alert, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import axios from '../../axios';

// Components
import FormValidator from '../../components/FormValidator';
import RecoverSent from '../../components/Auth/RecoverSent';

import './RecoverPage.sass';

class RecoverPage extends Component {

    state = {
        formRecover: {
            email: ''
        },
        recoverSent: false,
        recoverLoading: false,
        recoverError: false,
        recoverErrors: []
    };

    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });

    };

    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    };

    recoverSubmit = e => {
        e.preventDefault();

        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors, hasError } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        if (!hasError) {
            this.setState({
                recoverLoading: true
            });

            const token = localStorage.getItem('authToken');

            axios.post(`/user/password/reset`, {
                email: this.state.formRecover.email
            }, {
                headers: { Authorization: 'Bearer ' + token }
            }).then(response => {
                this.setState({
                    recoverLoading: false
                });

                if (response.data.success) {
                    this.setState({
                        recoverSent: true
                    });
                } else {
                    let recoverErrors = [];

                    for (let error in response.data.errors) {
                        recoverErrors.push({ id: uuid(), error: response.data.errors[error] });
                    }

                    this.setState({
                        recoverError: true,
                        recoverErrors,
                    });
                }
            }).catch(error => {
                toast.error('Возникла ошибка при восстановлении пароля');
            });
        }
    };

	render () {
		return (
			<div className="block-center mt-4 wd-xl">

                <Helmet>
                    <title>Boostmy.Email - авторизация</title>
                </Helmet>

                {this.state.recoverSent
                    ?
                        <RecoverSent />
                    :
                        <div className="card card-flat">
                            <div className="card-header text-center bg-dark">
                                <a href="!#">
                                    <img className="block-center rounded" src={require('../../assets/img/logo.svg')} alt="Logo"/>
                                </a>
                            </div>

                            <div className="card-body">
                                <p className="text-center py-2">СБРОСИТЬ ПАРОЛЬ</p>

                                {this.state.recoverError
                                    ?
                                        <Alert className="mb-4" color="danger">
                                            {this.state.recoverErrors.map(error => <div key={error.id}>{error.error}</div>)}
                                        </Alert>
                                    : 
                                        null
                                }

                                <form name="formRecover" onSubmit={this.recoverSubmit}>
                                    <p className="text-center">Введите email, чтобы получить инструкции как сбросить свой пароль</p>
                                    
                                    <div className="form-group">
                                        <label className="text-muted" htmlFor="resetInputEmail1">Email</label>
                                    
                                        <div className="input-group with-focus">
                                            <Input
                                                className="form-control border-right-0"
                                                id="resetInputEmail1"
                                                name="email"
                                                type="email"
                                                placeholder="Введите email"
                                                autoComplete="off"
                                                invalid={this.hasError('formRecover','email','required') || this.hasError('formRecover','email','email') ? true : false}
                                                onChange={this.validateOnChange}
                                                data-validate='["required", "email"]'
                                                value={this.state.formRecover.email}
                                            />
                                    
                                            <div className="input-group-append">
                                                <span className="input-group-text text-muted bg-transparent border-left-0">
                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                </span>
                                            </div>

                                            {this.hasError('formRecover','email','required') && <span className="invalid-feedback">Поле обязательно</span>}
                                            {this.hasError('formRecover','email','email') && <span className="invalid-feedback">Неверно введен email</span>}
                                        </div>
                                    </div>
                                    
                                    <button 
                                        className="btn btn-danger btn-block" 
                                        type="submit" 
                                        disabled={this.state.recoverLoading}
                                    >
                                        Сбросить {this.state.recoverLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
                                    </button>
                                </form>
                            </div>
                        </div>
                }
            </div>
		);
	}
}

export default RecoverPage;
