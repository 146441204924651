import React, { Component } from 'react';
import axios from '../../../axios';
import { Link } from 'react-router-dom';
import {
	Card,
	CardHeader,
	CardBody,
	InputGroup,
	InputGroupAddon,
	Input,
	Button,
	Pagination,
	PaginationItem,
	Form,
	Spinner,
	FormFeedback,
	PaginationLink,
	Modal,
	ModalBody,
	Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

// Components
import ContentWrapper from '../../../components/ContentWrapper';
import SpinnerLoader from '../../../components/SpinnerLoader';
import FormValidator from '../../../components/FormValidator';

import './AdminAudienceExceptionsPage.sass';

class AdminAudienceExceptions extends Component {

	state = {
		subscribersExceptionsList: [],
		subscribersExceptionsLoading: false,
		subscribersExceptionsErrorLoading: false,
		subscribersExceptionsSearchValid: true,
		subscribersExceptionsPerPage: 10,
		subscribersExceptionsPage: 0,
		exceptionToRestore: null,
		restoreExceptionLoading: false,
		restoreExceptionModalOpen: false,
		addAddressesToExceptionsLoading: false,
		addAddressesToExceptionsModalOpen: false,
		addressesToExceptionsForm: {
			addressesToExceptionsValue: ''
		}
	};

	componentDidMount () {
		this.getSubscribersExceptions();
	}

	getSubscribersExceptions = () => {
		this.setState({
			subscribersExceptionsLoading: true,
			subscribersExceptionsErrorLoading: false
		});

		axios.get('/shop/blacklist').then(response => {	
			this.setState({
				subscribersExceptionsLoading: false
			});

			if (response.data.success) {
				this.setState({
					subscribersExceptionsList: response.data.data.emails
				});
			} else {
				this.setState({
					subscribersExceptionsErrorLoading: true
				});
			}
		}).catch(error => {
			this.setState({
				subscribersExceptionsLoading: false,
				subscribersExceptionsErrorLoading: true
			});
		});
	};

	getSubscribersExceptionsList = () => {
		const { 
			subscribersExceptionsList, 
			subscribersExceptionsPage, 
			subscribersExceptionsPerPage 
		} = this.state;

		let renderSubscribersExceptionsList = null;

		if (subscribersExceptionsList.length === 0) {
			renderSubscribersExceptionsList = (
				<tr>
					<td colSpan="4">
						<div className="text-center">Не найдено.</div>
					</td>
				</tr>
			);

			return renderSubscribersExceptionsList;
		}

		renderSubscribersExceptionsList = 
			subscribersExceptionsList
				.slice(subscribersExceptionsPage * subscribersExceptionsPerPage, subscribersExceptionsPerPage * (subscribersExceptionsPage + 1))
				.map((exception, i) => (
					<tr key={exception.id}>
						<td>{exception.email}</td>
						<td>{exception.source}</td>
						<td>{exception.add_date}</td>
						<td className="text-right">
							<ul className="exceptions-controls-list">
								<li className="control-item" onClick={() => this.triggerModalRestoreException(exception.id)}><FontAwesomeIcon title="Восстановить из списка исключений" icon={faTrashAlt} /></li>
							</ul>
						</td>
					</tr>
				));

		return renderSubscribersExceptionsList;
	};

	getSubscribersListPagination = () => {
		const { subscribersExceptionsList, subscribersExceptionsPerPage, subscribersExceptionsPage } = this.state;

		const pageQuantity = Math.ceil(subscribersExceptionsList.length / subscribersExceptionsPerPage);

		let renderSubscribersExceptionsPagination = null;

		renderSubscribersExceptionsPagination = (
			<Pagination>
				<PaginationItem>
					<PaginationLink first onClick={() => this.setSubscribersExceptionsListPage(1)} disabled={subscribersExceptionsPage === 0} />
				</PaginationItem>

				<PaginationItem>
					<PaginationLink previous onClick={() => this.setSubscribersExceptionsListPage(subscribersExceptionsPage + 1 - 1)} disabled={subscribersExceptionsPage === 0} />
				</PaginationItem>
				
				{Array.from(Array(pageQuantity).keys())
					.map(page => Number(page) + 1)
					.map(page => (
						<PaginationItem active={subscribersExceptionsPage + 1 === page}>
							<PaginationLink
								onClick={() => this.setSubscribersExceptionsListPage(page)}
								disabled={subscribersExceptionsPage + 1 === page}
							>
								{page}
							</PaginationLink>
						</PaginationItem>
					))}
							
				<PaginationItem>
					<PaginationLink next onClick={() => this.setSubscribersExceptionsListPage(subscribersExceptionsPage + 1 + 1)} disabled={subscribersExceptionsPage + 1 === pageQuantity} />
				</PaginationItem>
					
				<PaginationItem>
					<PaginationLink last onClick={() => this.setSubscribersExceptionsListPage(pageQuantity)} disabled={subscribersExceptionsPage + 1 === pageQuantity} />
				</PaginationItem>
			</Pagination>
		);

		return renderSubscribersExceptionsPagination;
	};

	setSubscribersExceptionsListPage = page => {
		if (page > 0) {

			const lastPage = Math.ceil(this.state.subscribersExceptionsList.length / this.state.subscribersExceptionsPerPage);

			if (page > lastPage) {
				this.setState({
					subscribersExceptionsPage: lastPage
				});
			} else {
				this.setState({
					subscribersExceptionsPage: page - 1
				});
			}
		}
	};

	restoreException = id => {
		const { subscribersExceptionsList, restoreExceptionModalOpen } = this.state;

		let subscribersExceptionsListTemp = subscribersExceptionsList;

		const exceptionToDeleteIndex = subscribersExceptionsListTemp.findIndex(exception => exception.id === id);

		this.setState({
			restoreExceptionLoading: true
		});

		axios.post(`/shop/blacklist/email/${subscribersExceptionsListTemp[exceptionToDeleteIndex].id}/remove`)
			.then(response => {
				this.setState({
					restoreExceptionLoading: false
				});

				if (response.data.success) {
					subscribersExceptionsListTemp.splice(exceptionToDeleteIndex, 1);

					toast.success('Почтовый адрес восстановлен из списка исключений.');

					this.setState({
						subscribersExceptionsList: subscribersExceptionsListTemp,
						restoreExceptionModalOpen: !restoreExceptionModalOpen
					});
				} else {
					toast.error('Ошибка при восстановлении адреса');
				}
			}).catch(error => {
				this.setState({
					restoreExceptionLoading: false
				});
				
				toast.error('Ошибка при восстановлении адреса');
			});
	};

	addressesToExceptionsFormSubmit = e => {
		e.preventDefault();

		const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT', 'TEXTAREA'].includes(i.nodeName));

        const { errors, hasError } = FormValidator.bulkValidate(inputs);

        const { addressesToExceptionsForm, addAddressesToExceptionsModalOpen } = this.state;

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        if (!hasError) {
			const params = {
				list: addressesToExceptionsForm.addressesToExceptionsValue.split(/\r?\n/)
			};

			this.setState({
				addAddressesToExceptionsLoading: true
			});

			axios.post(`/shop/audience/blacklist/import`, params).then(response => {
				this.setState({
					addAddressesToExceptionsLoading: false,
					addressesToExceptionsForm: {
						addressesToExceptionsValue: ''
					}
				});

				if (response.data.success) {
					this.getSubscribersExceptions();

					toast.success('Почтовые адреса добавлены в список исключений');

					this.setState({
						addAddressesToExceptionsModalOpen: !addAddressesToExceptionsModalOpen
					});
				} else {
					toast.error('Ошибка при добавлении адресов в список исключений');
				}
			}).catch(error => {
				this.setState({
					addAddressesToExceptionsLoading: false,
					addressesToExceptionsForm: {
						addressesToExceptionsValue: ''
					}
				});

				toast.error('Ошибка при добавлении адресов в список исключений');
			});
		}
	};

	subscribersExceptionsSearchValueChange = e => {
		if (e.target.value.length < 4) {
			this.setState({
				subscribersExceptionsSearchValid: false
			});
		} else {
			this.setState({
				subscribersExceptionsSearchValid: true
			});
		}

		this.setState({
			subscribersExceptionsToSearchValue: e.target.value
		});

		if (e.target.value.length === 0 && this.state.subscribersExceptionsSearchMode) {
			this.getSubscribersExceptions();

			this.setState({
				subscribersExceptionsSearchMode: false,
				subscribersExceptionsSearchValid: true
			});
		}
	};


	searchSubscribersExceptions = e => {
		e.preventDefault();

		const { subscribersExceptionsToSearchValue } = this.state;

		if (subscribersExceptionsToSearchValue.length < 4) {
			this.setState({
				subscribersExceptionsSearchValid: false
			});

			return;
		}

		this.setState({
			searchSubscribersExceptionsLoading: true,
			subscribersExceptionsLoading: true
		});

		axios.get(`/shop/blacklist?search=${subscribersExceptionsToSearchValue}`)
			.then(response => {
				this.setState({
					searchSubscribersExceptionsLoading: false,
					subscribersExceptionsLoading: false
				});

				if (response.data.success) {
					this.setState({
						subscribersExceptionsList: response.data.data.emails,
						subscribersExceptionsSearchMode: true
					});
				} else {
					toast.error('Системная ошибка при поиске');
				}
			}).catch(error => {
				this.setState({
					searchSubscribersExceptionsLoading: false,
					subscribersExceptionsLoading: false
				});

				toast.error('Системная ошибка при поиске');
			});
	};

	validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });

    };

    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    };

	triggerModalRestoreException = id => {
		this.setState({
			restoreExceptionModalOpen: !this.state.restoreExceptionModalOpen,
			exceptionToRestore: id
		});
	};

	triggerModalAddAddressesToExceptions = () => {
		this.setState({
			addAddressesToExceptionsModalOpen: !this.state.addAddressesToExceptionsModalOpen
		});
	};

	render () {
		const {
			subscribersExceptionsList,
			restoreExceptionModalOpen,
			addAddressesToExceptionsModalOpen,
			exceptionToRestore,
			addAddressesToExceptionsLoading,
			subscribersExceptionsLoading,
			subscribersExceptionsPerPage,
			restoreExceptionLoading,
			searchSubscribersExceptionsLoading,
			subscribersExceptionsSearchValid,
			subscribersExceptionsToSearchValue,
			addressesToExceptionsValue
		} = this.state;

		return (
			<ContentWrapper>
				<Card>
					<Modal isOpen={restoreExceptionModalOpen} toggle={() => this.setState({ restoreExceptionModalOpen: !restoreExceptionModalOpen })}>
						<ModalBody className="text-center">
							<h3>Восстановить из списка исключений?</h3>

							<p>Данный почтовый адрес будет исключен из списка исключений. После этого данный адрес снова можно будет использовать в системе.</p>

							<div>
								<Button 
									color="primary" 
									onClick={() => this.restoreException(exceptionToRestore)}
									disabled={restoreExceptionLoading}
								>
									Восстановить {restoreExceptionLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
								</Button>
								
								<Button 
									color="secondary" 
									className="ml-2" 
									onClick={() => this.setState({ restoreExceptionModalOpen: !restoreExceptionModalOpen })}
								>
									Отмена
								</Button>
							</div>
						</ModalBody>
					</Modal>

					<Modal isOpen={addAddressesToExceptionsModalOpen} toggle={() => this.setState({ addAddressesToExceptionsModalOpen: !addAddressesToExceptionsModalOpen })}>
						<ModalBody className="text-center">
							<h3>Добавить в список исключений</h3>

							<p>Данные адреса можно будет восстановить вручную (удалить из списка исключений)</p>

							<form name="addressesToExceptionsForm" onSubmit={this.addressesToExceptionsFormSubmit}>
								<div className="form-group">
									<div className="input-group with-focus">
										<Input
											type="textarea"
											name="addressesToExceptionsValue"
											value={addressesToExceptionsValue} 
											onChange={this.validateOnChange} 
											invalid={this.hasError('addressesToExceptionsForm','addressesToExceptionsValue','required') ? true : false}
					                        data-validate='["required"]'
											placeholder="Вставьте почтовые адреса сюда - каждый с новой строки"
										/>

										<span className="invalid-feedback">Поле обязательно для заполнения</span>
									</div>
								</div>

								<div className="mt-3">
									<Button 
										type="submit"
										color="primary" 
										disabled={addAddressesToExceptionsLoading}
									>
										Добавить {addAddressesToExceptionsLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
									</Button>
									
									<Button 
										color="secondary" 
										className="ml-2" 
										onClick={() => this.setState({ addAddressesToExceptionsModalOpen: !addAddressesToExceptionsModalOpen })}
										disabled={addAddressesToExceptionsLoading}
									>
										Отмена
									</Button>
								</div>
							</form>
						</ModalBody>
					</Modal>

					<CardHeader className="exceptions-card-header">
						<div className="exceptions-header">
							<h3>Список исключений</h3>
						</div>

						<Form className="exceptions-search-form" onSubmit={e => this.searchSubscribersExceptions(e)}>
							<InputGroup>
								<Input 
									invalid={!subscribersExceptionsSearchValid ? true : false}
									value={subscribersExceptionsToSearchValue} 
									onChange={e => this.subscribersExceptionsSearchValueChange(e)} 
									placeholder="Введите email..." 
								/>
								
								<InputGroupAddon addonType="append">
									<Button disabled={searchSubscribersExceptionsLoading}>
										Найти {searchSubscribersExceptionsLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
									</Button>
								</InputGroupAddon>

								<FormFeedback invalid={!subscribersExceptionsSearchValid ? true : false}>Не меньше 3 символов для поиска</FormFeedback>
							</InputGroup>
						</Form>
					</CardHeader>

					<CardBody>
						<Table className="exceptions-list-table" striped responsive>
							<thead>
								<tr>
									<th>Email</th>
									<th>Источник исключения</th>
									<th>Дата добавления</th>
								</tr>
							</thead>

							<tbody>
								{subscribersExceptionsLoading ? (
									<tr><td colSpan="3">
										<SpinnerLoader />
									</td></tr>
								) : this.getSubscribersExceptionsList()}
							</tbody>
						</Table>

						<Button 
							className="mt-3" 
							onClick={() => this.triggerModalAddAddressesToExceptions()} 
							color="primary" 
							size="lg" 
							block
						>
							Добавить адреса в список исключений
						</Button>

						{!subscribersExceptionsLoading && subscribersExceptionsList.length > subscribersExceptionsPerPage && (
							<div className="mt-5">
								{this.getSubscribersListPagination()}
							</div>
						)}

					</CardBody>
				</Card>
			</ContentWrapper>
		);
	}
}

export default AdminAudienceExceptions;
