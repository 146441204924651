import React, { Component } from 'react';
import {
	Alert,
	Table,
	Card,
	CardBody,
	FormGroup,
	Input,
	Button,
	Tooltip,
	Modal,
	ModalBody,
	Spinner
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faQuestionCircle,
	faCog,
	faTrashAlt,
	faCircle
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import axios from '../../axios';

// Components
import ContentWrapper from '../../components/ContentWrapper';
import Aux from '../../hoc/Auxilary';
import SpinnerLoader from '../../components/SpinnerLoader';

import './TagsListPage.sass';

class TagsListPage extends Component {

	state = {
		tagsList: [],
		tagsLoading: false,
		tagsErrorLoading: false,
		tagEditLoading: false,
		tagToDelete: null,
		tagToEdit: null,
		tagEditData: {
			tagType: '',
			tagName: '',
			tagAddress: '',
			tagDataType: 'text',
			tagValue: '',
			tagDefaultValue: '',
			tagCoupons: '',
			tagUsedCoupons: ''
		},
		tagModalType: '',
		editTagError: false,
		editTagErrors: [],
		createTagError: false,
		createTagErrors: [],
		editTagLoading: false,
		editTagErrorLoading: false,
		createTagLoading: false
	};

	componentDidMount () {
		this.getTags();
	}

	getTags = () => {
		this.setState({
			tagsLoading: true
		});

		axios.get('/tags/get').then(response => {
			this.setState({
				tagsLoading: false
			});

			if (response.data.success) {
				this.setState({
					tagsList: response.data.data.tags.map(tag => ({
						...tag,
						tooltopOpen: false
					})),
				});
			} else {
				this.setState({
					tagsErrorLoading: true
				});
			}
		}).catch(error => {
			this.setState({
				tagsLoading: false,
				tagsErrorLoading: true
			});
		});
	};

	tagEditElementChange = (name, value) => {
		this.setState({
			tagEditData: {
				...this.state.tagEditData,
				[name]: value
			}
		});
	};

	tagRemove = id => {
		axios.post(`/tags/${id}/delete`).then(response => {
			if (response.data.success) {
				const { tagsList, deleteTagModalOpen } = this.state;

				let tagsListTemp = tagsList;

				const tagToEditIndex = tagsListTemp.findIndex(tag => tag.id === id);

				toast.success(`Тег ${tagsListTemp[tagToEditIndex].name} удален`);

				tagsListTemp.splice(tagToEditIndex, 1);

				this.setState({
					tagsList: tagsListTemp,
					deleteTagModalOpen: !deleteTagModalOpen
				});
			} else {
				toast.error('Ошибка при удалении тега');
			}
		}).catch(error => {
			toast.error('Ошибка при удалении тега');
		});
	};

	toggleTagTooltip = id => {
		let tagsListNew = this.state.tagsList;

		const tagIndex = tagsListNew.findIndex(tag => tag.id === id);

		tagsListNew[tagIndex].tooltipOpen = !tagsListNew[tagIndex].tooltipOpen;

		this.setState({ 
			tagsList: tagsListNew 
		});
	};

	getTagTooltipState = id => {
		return this.state.tagsList.find(tag => tag.id === id).tooltipOpen;
	};

	triggerModalDeleteTag = id => {
		this.setState({
			deleteTagModalOpen: !this.state.deleteTagModalOpen,
			tagToDelete: id
		});
	};

	triggerModalCreateTag = () => {
		const { createTagModalOpen, tagEditData } = this.state;

		this.setState({
			createTagModalOpen: !createTagModalOpen,
			tagEditData: {
				...tagEditData,
				tagType: '',
				tagName: '',
				tagAddress: '',
				tagDataType: 'text',
				tagValue: '',
				tagDefaultValue: '',
				tagCoupons: ''
			},
			tagModalType: 'create'
		});
	};

	triggerModalEditTag = tagId => {
		const { createTagModalOpen, tagEditData } = this.state;

		this.setState({
			tagEditLoading: true,
			tagEditErrorLoading: false,
			createTagModalOpen: !createTagModalOpen,
			tagToEdit: tagId
		});

		axios.get(`/tags/${tagId}/get`)
			.then(response => {
				this.setState({
					tagEditLoading: false
				});

				if (response.data.success) {
					this.setState({
						tagEditData: {
							...tagEditData,
							tagDataType: response.data.data.data_type,
							tagName: response.data.data.name,
							tagAddress: response.data.data.code,
							tagValue: response.data.data.value ? response.data.data.value : '',
							tagDefaultValue: response.data.data.default ? response.data.data.default : '',
							tagCoupons: response.data.data.values ? response.data.data.values.filter(el => el.used !== 'Y').map(el => el.value).join('\n') : '',
							tagUsedCoupons: response.data.data.values ? response.data.data.values.filter(el => el.used === 'Y').map(el => el.value).join('\n') : ''
						},
						tagModalType: 'edit'
					});
				} else {
					this.setState({
						tagEditErrorLoading: true
					});
				}
			}).catch(error => {
				this.setState({
					tagEditLoading: false,
					tagEditErrorLoading: true
				});
			});
	};

	createTag = e => {
		e.preventDefault();

		const { tagEditData, createTagModalOpen } = this.state;

		this.setState({
			createTagLoading: true,
			tagEditErrorLoading: false,
			createTagError: false,
			createTagErrors: []
		});

		if (tagEditData.tagDataType === 'text') {

			axios.post(`/tags/add`, {
				type: 'text',
				name: tagEditData.tagName,
				tag: tagEditData.tagAddress,
				value: tagEditData.tagValue
			}).then(response => {

				this.setState({
					createTagLoading: false
				});

				if (response.data.success) {
					
					this.setState({
						createTagModalOpen: !createTagModalOpen
					});

					toast.success(`Новый тег успешно добавлен.`);

					this.getTags();

				} else {
					let createTagErrors = [];

                    for (let error in response.data.errors) {
                        createTagErrors.push({ id: uuid(), error: response.data.errors[error] });
                    }

                    this.setState({
                    	createTagErrors,
                    	createTagError: true
                    });
				}
			}).catch(error => {
				this.setState({
					createTagLoading: false
				});

				toast.error('Ошибка при создании тега');
			});
		
		} else if (tagEditData.tagDataType === 'coupon') {

			axios.post(`/tags/add`, {
				type: 'coupon',
				name: tagEditData.tagName,
				tag: tagEditData.tagAddress,
				'default': tagEditData.tagDefaultValue,
				values: tagEditData.tagCoupons.split(/\r?\n/)
			}).then(response => {

				this.setState({
					createTagLoading: false,
					createTagErrors: []
				});

				if (response.data.success) {

					this.setState({
						createTagModalOpen: !createTagModalOpen
					});

					toast.success('Новый тег успешно добавлен.');

					this.getTags();

				} else {
					let createTagErrors = [];

                    for (let error in response.data.errors) {
                        createTagErrors.push({ id: uuid(), error: response.data.errors[error] });
                    }

                    this.setState({
                    	createTagErrors,
                    	createTagError: true
                    });
				}
			}).catch(error => {
				this.setState({
					createTagLoading: false
				});

				toast.error('Ошибка при создании тега');
			});
		}
	};

	editTag = e => {
		e.preventDefault();

		const { 
			tagsList, 
			tagToEdit, 
			tagEditData, 
			createTagModalOpen 
		} = this.state;

		let tempTagsList = tagsList;
		const tagToEditIndex = tagsList.findIndex(tag => tag.id === tagToEdit);

		this.setState({
			editTagLoading: true,
			editTagErrorLoading: false,
			editTagError: false
		});

		if (tagEditData.tagDataType === 'text') {

			axios.post(`/tags/${tagToEdit}/save`, {
				name: tagEditData.tagName,
				value: tagEditData.tagValue
			}).then(response => {

				this.setState({
					editTagLoading: false,
					editTagErrors: []
				});

				if (response.data.success) {
					tempTagsList[tagToEditIndex] = {
						...tempTagsList[tagToEditIndex],
						name: tagEditData.tagName,
						tag: tagEditData.tagAddress
					};

					this.setState({
						tagsList: tempTagsList,
						createTagModalOpen: !createTagModalOpen,
						editTagError: false
					});

					toast.success('Настройки тега обновлены.');
				} else {
					let editTagErrors = [];

                    for (let error in response.data.errors) {
                        editTagErrors.push({ id: uuid(), error: response.data.errors[error] });
                    }

                    this.setState({
                    	editTagErrors,
                    	editTagError: true
                    });
				}
			}).catch(error => {
				this.setState({
					editTagLoading: false,
					editTagErrorLoading: true
                });
			});
		} else if (tagEditData.tagDataType === 'coupon') {

			axios.post(`/tags/${tagToEdit}/save`, {
				'name': tagEditData.tagName,
				'default': tagEditData.tagDefaultValue,
				'values': tagEditData.tagCoupons.split(/\r?\n/)
			}).then(response => {

				this.setState({
					editTagLoading: false,
					editTagErrors: []
				});

				if (response.data.success) {
					tempTagsList[tagToEditIndex] = {
						...tempTagsList[tagToEditIndex],
						name: tagEditData.tagName,
						tag: tagEditData.tagAddress
					};

					this.setState({
						tagsList: tempTagsList,
						createTagModalOpen: !createTagModalOpen,
						editTagError: false
					});

					toast.success('Настройки тега обновлены.');
				} else {
					let editTagErrors = [];

                    for (let error in response.data.errors) {
                        editTagErrors.push({ id: uuid(), error: response.data.errors[error] });
                    }

                    this.setState({
                    	editTagErrors,
                    	editTagError: true
                    });
				}
			}).catch(error => {
				this.setState({
					editTagLoading: false,
					editTagErrorLoading: true
                });
			});
		}
	};

	tagTypeChange = type => {
		this.setState({
			tagEditData: {
				...this.state.tagEditData,
				tagType: type
			}
		});
	};

	getTagsList = () => {
		let renderTagsList = null;

		renderTagsList = this.state.tagsList.map((tag, i) => {
			let tooltip = null;

			if (tag.description.length > 0) {
				tooltip = (
					<Aux>
						<span id={`tagTooltop${tag.id}`}>
							<FontAwesomeIcon icon={faQuestionCircle} />
						</span>
						
						<Tooltip 
							placement="right" 
							isOpen={this.getTagTooltipState(tag.id)} 
							target={`tagTooltop${tag.id}`}
							toggle={() => this.toggleTagTooltip(tag.id)}
						>
							{tag.description}
						</Tooltip>
					</Aux>
				);
			}

			return (
				<tr key={tag.id}>
					<td>{tag.name} {tooltip}</td>
					<td>{tag.tag}</td>
					<td>{tag.dataType}</td>
					<td>{tag.tagType}</td>
					<td className="text-right">{this.getTagControlsList(tag.tagType, tag.id)}</td>
				</tr>
			);
		});

		return renderTagsList;
	};

	getTagControlsList = (tagType, tagId) => {
		let controls = [];

		if (tagType === 'Пользовательский') {
			controls = ['settings', 'delete'];
		}

		controls = controls.map(control => ({
			id: uuid(),
			name: control
		}));

		const renderControlsList = controls.map(control => {
			let controlItem = null;

			switch (control.name) {
				case 'settings':
					controlItem = <li key={control.id} className="control-item" onClick={() => this.triggerModalEditTag(tagId)}><FontAwesomeIcon icon={faCog} /></li>;
					break;
					
				case 'delete':
					controlItem = <li key={control.id} className="control-item" onClick={() => this.triggerModalDeleteTag(tagId)}><FontAwesomeIcon icon={faTrashAlt} /></li>;
					break;

				default:
			}

			return controlItem;
		});

		return <ul className="tags-controls-list">{renderControlsList}</ul>;
	};

	render () {
		const {
			tagEditData,
			deleteTagModalOpen,
			tagToDelete,
			createTagModalOpen,
			tagModalType,
			tagEditLoading,
			tagEditErrorLoading,
			tagsErrorLoading,
			editTagLoading,
			createTagLoading,
			tagToEdit,
			editTagErrors,
			createTagErrors,
			editTagError,
			createTagError
		} = this.state;

		return (
			<ContentWrapper>
				<Modal isOpen={deleteTagModalOpen} toggle={() => this.setState({ deleteTagModalOpen: !deleteTagModalOpen })}>
					<ModalBody className="text-center">
						<h3>Удалить тег?</h3>

						<p>Все настройки тега будут удалены. Отменить данное действие невозможно.</p>

						<div>
							<Button color="primary" onClick={() => this.tagRemove(tagToDelete)}>Удалить</Button>
							<Button color="secondary" className="ml-2" onClick={() => this.setState({ deleteTagModalOpen: !deleteTagModalOpen })}>Отмена</Button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={createTagModalOpen} toggle={!editTagLoading && !createTagLoading ? () => this.triggerModalCreateTag() : null}>
					<ModalBody>
						{tagEditLoading
							?
								<SpinnerLoader />
							:
								<Aux>
									{tagEditErrorLoading ? (
										<Alert color="danger">
											Ошибка при загрузке данных

											<br/>

											<Button 
												className="mt-2" 
												size="xs" 
												color="secondary" 
												onClick={() => this.triggerModalEditTag(tagToEdit)}
											>
												Повторить запрос
											</Button>
										</Alert>
									) : (
										<Aux>
											<h3 className="text-center">{tagModalType === 'edit' ? 'Редактировать тег' : 'Создать новый тег'}</h3>

											{editTagError && (
												<Alert color="danger">
													{editTagErrors.map(error => <div key={error.id}>{error.error}</div>)}
												</Alert>
											)}

											{createTagError && (
												<Alert color="danger">
													{createTagErrors.map(error => <div key={error.id}>{error.error}</div>)}
												</Alert>
											)}

											<form onSubmit={e => tagModalType === 'edit' ? this.editTag(e) : this.createTag(e)}>
												<FormGroup>
													<label>Тип</label>

													<FormGroup>
														<label className="c-radio">
															<Input
																id="tagTypeInput1"
																type="radio"
																name="tagTypeInput"
																value="text"
																onChange={e => this.tagEditElementChange('tagDataType', e.target.value)}
																checked={tagEditData.tagDataType === 'text'}
																disabled={tagModalType === 'edit'}
															/>

															<FontAwesomeIcon icon={faCircle} /> Текст
														</label>

														<label className="c-radio">
															<Input 
																id="tagTypeInput2" 
																type="radio" 
																name="tagTypeInput" 
																value="coupon" 
																onChange={e => this.tagEditElementChange('tagDataType', e.target.value)} 
																checked={tagEditData.tagDataType === 'coupon'}
																disabled={tagModalType === 'edit'}
															/>

															<FontAwesomeIcon icon={faCircle} /> Купоны
														</label>
													</FormGroup>
												</FormGroup>

												<FormGroup>
													<label>Имя</label>

													<Input type="text" value={!tagEditData.tagName ? '' : tagEditData.tagName} onChange={e => this.setState({ tagEditData: { ...tagEditData, tagName: e.target.value } })} placeholder="Введите название тега" required />
												</FormGroup>

												<FormGroup>
													<label>Тег (латинские буквы и цифры без пробелов, можно со знаком - или _)</label>

													<Input 
														type="text" 
														value={!tagEditData.tagAddress ? '' : tagEditData.tagAddress} 
														onChange={e => this.tagEditElementChange('tagAddress', e.target.value)} 
														placeholder="Введите идентификатор тега" 
														required
														disabled={tagModalType === 'edit'}
													/>
												</FormGroup>

												{tagEditData.tagDataType === 'text' ? (
													<FormGroup>
														<label>Значение</label>

														<Input type="textarea" value={!tagEditData.tagValue ? '' : tagEditData.tagValue} onChange={e => this.setState({ tagEditData: { ...tagEditData, tagValue: e.target.value } })} placeholder="Введите значение тега" />
													</FormGroup>
												) : null}

												{tagEditData.tagDataType === 'coupon' ? (
													<Aux>
														<FormGroup>
															<label>Значение по умолчанию (когда в массиве все значения использованы)</label>

															<Input
																type="text"
																value={!tagEditData.tagDefaultValue ? '' : tagEditData.tagDefaultValue}
																onChange={e => this.tagEditElementChange('tagDefaultValue', e.target.value)}
																placeholder="Введите значение по умолчанию"
															/>
														</FormGroup>

														<FormGroup>
															<label>Массив купонов (каждое значение будет использовано только один раз, при использовании ставится отметка "*")</label>

															<h6>Неиспользованные купоны</h6>

															<Input
																type="textarea"
																rows="8"
																value={tagEditData.tagCoupons}
																onChange={e => this.tagEditElementChange('tagCoupons', e.target.value)}
																placeholder="Введите массив купонов, каждый с новой строки"
															/>
														</FormGroup>

														<FormGroup>
															<h6>Использованные купоны</h6>

															<Input
																type="textarea"
																rows="8"
																value={tagEditData.tagUsedCoupons}
																placeholder="Здесь будут отображены использованные купоны, когда они появятся"
																disabled
															/>
														</FormGroup>
													</Aux>
												) : null}

												<div className="text-right">
													<Button
														type="submit"
														color="primary"
														disabled={editTagLoading || createTagLoading}
													>
														{tagModalType === 'edit' ? 'Сохранить' : 'Создать'} {editTagLoading || createTagLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
													</Button>
													
													<Button
														color="secondary"
														className="ml-2"
														onClick={() => this.triggerModalCreateTag()}
														disabled={editTagLoading || createTagLoading}
													>
														Отмена
													</Button>
												</div>
											</form>
										</Aux>
									)}
								</Aux>
						}
					</ModalBody>
				</Modal>

				{tagsErrorLoading ? (
					<Alert className="text-center" color="danger">
						Ошибка при загрузке данных

						<br/>

						<Button 
							className="mt-2" 
							size="xs" 
							color="secondary" 
							onClick={() => this.getTags()}
						>
							Повторить запрос
						</Button>
					</Alert>
				) : (
					<Card>
						<CardBody>
							<Table className="tags-list-table" striped responsive>
								<thead>
									<tr>
										<th>Имя</th>
										<th>Тег</th>
										<th>Тип данных</th>
										<th>Тип тега</th>
									</tr>
								</thead>

								<tbody>
									{this.state.tagsLoading ? (
										<tr><td colSpan="4">
											<SpinnerLoader />
										</td></tr>
									) : this.getTagsList()}
								</tbody>
							</Table>

							<Button 
								onClick={() => this.triggerModalCreateTag()} 
								color="primary" 
								size="lg" 
								block
							>
								Добавить тег
							</Button>
						</CardBody>
					</Card>
				)}
			</ContentWrapper>
		);
	}
}

export default TagsListPage;
