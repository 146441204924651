const formFilterShow = {
	sourceInput: [
		{
			label: 'UTM-SOURCE',
			value: 'utm-source',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' },
				{ label: 'включает', value: 'includes' },
				{ label: 'не включает', value: 'not-includes' },
				{ label: 'заполнен', value: 'contained' },
				{ label: 'не заполнен', value: 'not-contained' }
			],
			valueInput: { label: 'Введите UTM', value: 'inputText' }
		},
		{
			label: 'UTM-MEDIUM',
			value: 'utm-medium',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' },
				{ label: 'включает', value: 'includes' },
				{ label: 'не включает', value: 'not-includes' },
				{ label: 'заполнен', value: 'contained' },
				{ label: 'не заполнен', value: 'not-contained' }
			],
			valueInput: { label: 'Введите UTM', value: 'inputText' }
		},
		{
			label: 'UTM-TERM',
			value: 'utm-term',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' },
				{ label: 'включает', value: 'includes' },
				{ label: 'не включает', value: 'not-includes' },
				{ label: 'заполнен', value: 'contained' },
				{ label: 'не заполнен', value: 'not-contained' }
			],
			valueInput: { label: 'Введите UTM', value: 'inputText' }
		},
		{
			label: 'UTM-CAMPAIGN',
			value: 'utm-campaign',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' },
				{ label: 'включает', value: 'includes' },
				{ label: 'не включает', value: 'not-includes' },
				{ label: 'заполнен', value: 'contained' },
				{ label: 'не заполнен', value: 'not-contained' }
			],
			valueInput: { label: 'Введите UTM', value: 'inputText' }
		},
		{
			label: 'UTM-CONTENT',
			value: 'utm-content',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' },
				{ label: 'включает', value: 'includes' },
				{ label: 'не включает', value: 'not-includes' },
				{ label: 'заполнен', value: 'contained' },
				{ label: 'не заполнен', value: 'not-contained' }
			],
			valueInput: { label: 'Введите UTM', value: 'inputText' }
		},
		{
			label: 'Браузер',
			value: 'browser',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' }
			],
			valueInput: [
				{ label: 'Chrome', value: 'chrome' },
				{ label: 'Firefox', value: 'firefox' },
				{ label: 'Internet Explorer', value: 'internet-explorer' },
				{ label: 'MS Edge', value: 'ms-edge' },
				{ label: 'Safari', value: 'safari' },
				{ label: 'Yandex', value: 'yandex' },
				{ label: 'Opera', value: 'opera' }
			]
		},
		{
			label: 'Версия браузера',
			value: 'browserV-version',
			equalInput: [
				{ label: 'больше или равно', value: 'more-or-equal' },
				{ label: 'меньше или равно', value: 'less-or-equal' }
			],
			valueInput: { label: 'Введите версию браузера', value: 'inputNumber' }
		},
		{
			label: 'ОС',
			value: 'os',
			equalInput: [
				{ label: 'равно', value: 'equal' },
				{ label: 'не равно', value: 'not-equal' }
			],
			valueInput: [
				{ label: 'MacOS', value: 'macos' },
				{ label: 'Windows', value: 'windows' },
				{ label: 'Linux', value: 'linux' },
				{ label: 'Windows Phone', value: 'windows-phone' },
				{ label: 'IOS', value: 'ios' },
				{ label: 'Android', value: 'android' }
			]
		},
		{
			label: 'Текущий путь на странице',
			value: 'current-path-on-page',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' },
				{ label: 'включает', value: 'includes' },
				{ label: 'не включает', value: 'not-includes' }
			],
			valueInput: { label: 'Введите URL', value: 'inputText' }
		},
		{
			label: 'Страница входа',
			value: 'entry-page',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' },
				{ label: 'включает', value: 'includes' },
				{ label: 'не включает', value: 'not-includes' }
			],
			valueInput: { label: 'Введите URL', value: 'inputText' }
		},
		{
			label: 'Время пребывания на сайте (сек)',
			value: 'time-on-site',
			equalInput: [
				{ label: 'больше', value: 'more' },
				{ label: 'меньше', value: 'less' }
			],
			valueInput: { label: 'Введите время', value: 'inputNumber' }
		},
		{
			label: 'Кол-во просмотренных страниц',
			value: 'number-page-views',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' },
				{ label: 'больше', value: 'more' },
				{ label: 'меньше', value: 'less' }
			],
			valueInput: { label: 'Введите номер', value: 'inputNumber' }
		},
		{
			label: 'Кол-во просмотренных товаров',
			value: 'number-products-views',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' },
				{ label: 'больше', value: 'more' },
				{ label: 'меньше', value: 'less' }
			],
			valueInput: { label: 'Введите номер', value: 'inputNumber' }
		},
		{
			label: 'Кол-во показов виджетов',
			value: 'number-widgets-views',
			equalInput: [
				{ label: 'равен', value: 'equal' },
				{ label: 'не равен', value: 'not-equal' },
				{ label: 'больше', value: 'more' },
				{ label: 'меньше', value: 'less' }
			],
			valueInput: { label: 'Введите номер', value: 'inputNumber' }
		}
	]
};

export default formFilterShow;
