import React, { Component } from 'react';
import { 
	Form, 
	FormGroup, 
	Label, 
	Input, 
	Button,
	Table,
	Spinner,
	Alert,
	Pagination,
	PaginationItem,
	PaginationLink
} from 'reactstrap';
import { toast } from 'react-toastify';
import axios from '../../axios';

// Components
import ContentWrapper from '../../components/ContentWrapper';
import SpinnerLoader from '../../components/SpinnerLoader';
import Aux from '../../hoc/Auxilary';

import './PaymentPage.sass';

class PaymentPage extends Component {

	state = {
		paymentsHistoryList: [],
		paymentsHistoryPerPage: 5,
		paymentsHistoryPage: 0,
		paymentInfoLoading: true,
		paymentsHistoryLoading: false,
		paymentInfoErrorLoading: false,
		paymentsHistoryErrorLoading: false,
		visitsPackages: [],
		visitsPackageValue: '',
		visitsBalance: null,
		visitsName: '',
		visitsPackagePayLoading: false
	};

	componentDidMount () {
		this.getPaymentInfo();
		this.getPaymentsHistory();
	}

	getPaymentInfo = () => {
		this.setState({
			paymentInfoLoading: true,
			paymentInfoErrorLoading: false
		});

		axios.get('/payment/info/get').then(response => {
			this.setState({
				paymentInfoLoading: false
			});

			if (response.data.success) {
				this.setState({
					visitsPackages: response.data.data.packages,
					visitsPackageValue: response.data.data.packages[0].id,
					visitsBalance: response.data.data.billing.count,
					visitsName: response.data.data.billing.name
				});
			} else {
				this.setState({
					paymentInfoErrorLoading: true
				});
			}
		}).catch(error => {
			this.setState({
				paymentInfoLoading: false,
				paymentInfoErrorLoading: true
			});
		});
	};

	getPaymentsHistory = () => {
		this.setState({
			paymentsHistoryLoading: true,
			paymentsHistoryErrorLoading: false
		});

		axios.get('/payment/log/get').then(response => {
			this.setState({
				paymentsHistoryLoading: false
			});

			if (response.data.success) {
				this.setState({
					paymentsHistoryLoading: false,
					paymentsHistoryList: response.data.data
				});
			} else {
				this.setState({
					paymentsHistoryErrorLoading: true
				});
			}
		}).catch(error => {
			this.setState({
				paymentsHistoryLoading: false,
				paymentsHistoryErrorLoading: true
			});
		});
	};

	getPaymentsHistoryList = () => {
		const { paymentsHistoryList } = this.state;

		let renderPaymentsHistoryList = null;

		if (paymentsHistoryList.length === 0) {
			renderPaymentsHistoryList = (
				<tr>
					<td colSpan="3" className="text-center">
						Не найдено
					</td>
				</tr>
			);		

			return renderPaymentsHistoryList;	
		}

		renderPaymentsHistoryList = paymentsHistoryList.map((payment, i) => {
			return (
				<tr key={payment.id}>
					<td>{payment.name}</td>
					<td>{payment.price}</td>
					<td>{payment.date}</td>
				</tr>
			);
		});

		return renderPaymentsHistoryList;
	};

	getPaymentsHistoryListPagination = () => {
		const { 
			paymentsHistoryList, 
			paymentsHistoryPerPage, 
			paymentsHistoryPage 
		} = this.state;

		const pageQuantity = Math.ceil(paymentsHistoryList.length / paymentsHistoryPerPage);

		let renderPaymentsHistoryPagination = null;

		renderPaymentsHistoryPagination = (
			<Pagination>
				<PaginationItem>
					<PaginationLink first onClick={() => this.setPaymentsHistoryListPage(1)} disabled={paymentsHistoryPage === 0} />
				</PaginationItem>

				<PaginationItem>
					<PaginationLink previous onClick={() => this.setPaymentsHistoryListPage(paymentsHistoryPage + 1 - 1)} disabled={paymentsHistoryPage === 0} />
				</PaginationItem>
				
				{Array.from(Array(pageQuantity).keys())
					.map(page => Number(page) + 1)
					.map(page => (
						<PaginationItem active={paymentsHistoryPage + 1 === page}>
							<PaginationLink
								onClick={() => this.setPaymentsHistoryListPage(page)}
								disabled={paymentsHistoryPage + 1 === page}
							>
								{page}
							</PaginationLink>
						</PaginationItem>
					))}
							
				<PaginationItem>
					<PaginationLink next onClick={() => this.setPaymentsHistoryListPage(paymentsHistoryPage + 1 + 1)} disabled={paymentsHistoryPage + 1 === pageQuantity} />
				</PaginationItem>
					
				<PaginationItem>
					<PaginationLink last onClick={() => this.setPaymentsHistoryListPage(pageQuantity)} disabled={paymentsHistoryPage + 1 === pageQuantity} />
				</PaginationItem>
			</Pagination>
		);

		return renderPaymentsHistoryPagination;
	};

	setPaymentsHistoryListPage = page => {
		const { paymentsHistoryList, paymentsHistoryPage } = this.state;

		if (page > 0) {

			const lastPage = Math.ceil(paymentsHistoryList.length / paymentsHistoryPage);

			if (page > lastPage) {
				this.setState({
					paymentsHistoryPage: lastPage
				});
			} else {
				this.setState({
					paymentsHistoryPage: page - 1
				});
			}
		}
	};

	getVisitsPackagesOptionsList = () => {
		const { visitsPackages } = this.state;

		return visitsPackages.map(visitsPackage => (
			<option key={visitsPackage.id} value={visitsPackage.id}>{visitsPackage.name}</option>
		));
	};

	visitsPackageSelectChange = e => {
		this.setState({
			visitsPackageValue: e.target.value
		});
	};

	payVisitsPackageFormSubmit = e => {
		e.preventDefault();

		this.setState({
			visitsPackagePayLoading: true
		});

		axios.get(`/payment/package/${this.state.visitsPackageValue}/pay/link`)
			.then(response => {
				this.setState({
					visitsPackagePayLoading: false
				});

				if (response.data.success) {
					const windowObject = window.open(response.data.data, '_blank');

					if (windowObject) {
						windowObject.focus();
					}
				} else {
					toast.error('Ошибка получения ссылки на оплату');
				}
			}).catch(error => {
				this.setState({
					visitsPackagePayLoading: false
				});

				toast.error('Ошибка получения ссылки на оплату');
			});
	};

	render () {
		const { 
			visitsPackageValue, 
			paymentInfoErrorLoading,
			paymentInfoLoading,
			visitsBalance,
			visitsName,
			paymentsHistoryList,
			paymentsHistoryPerPage,
			paymentsHistoryErrorLoading,
			paymentsHistoryLoading, 
			visitsPackagePayLoading 
		} = this.state;

		return (
			<ContentWrapper>
				<h1>Оплата</h1>

				{paymentInfoErrorLoading ? (
					<Alert color="danger">
						Ошибка при загрузке данных

						<br/>

						<Button 
							className="mt-2" 
							size="xs" 
							color="secondary" 
							onClick={() => this.getPaymentInfo()}
						>
							Повторить запрос
						</Button>
					</Alert>
				) : (
					<Aux>
						{paymentInfoLoading ? <SpinnerLoader /> : (
							<Aux>
								<div className="balance-block">
									<h4>Баланс</h4>

									<p className="text-spring-green">{visitsName}: {visitsBalance}</p>
								</div>

								<div className="replenish-balance-block mt-4">
									<h4>Пополнить баланс</h4>

									<Form className="pay-visits-form" onSubmit={e => this.payVisitsPackageFormSubmit(e)}>
										<FormGroup>
											<Label for="visitsPackageSelect">Выберите пакет посещений</Label>

											<Input 
												id="visitsPackageSelect" 
												type="select" 
												value={visitsPackageValue} 
												onChange={e => this.visitsPackageSelectChange(e)}
											>
												{this.getVisitsPackagesOptionsList()}
											</Input>
										</FormGroup>

										<Button 
											className="pay-visit-btn" 
											size="lg" 
											color="primary" 
											disabled={visitsPackagePayLoading}
										>
											Оплатить {visitsPackagePayLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
										</Button>
									</Form>
								</div>
							</Aux>
						)}
					</Aux>
				)}

				<div className="payment-history-block mt-4">
					<h4>История покупок</h4>
				
					{paymentsHistoryErrorLoading ? (
						<Alert color="danger">
							Ошибка при загрузке данных

							<br/>

							<Button 
								className="mt-2" 
								size="xs" 
								color="secondary" 
								onClick={() => this.getPaymentsHistory()}
							>
								Повторить запрос
							</Button>
						</Alert>
					) : (
						<Aux>
							<Table className="payment-history-table" striped responsive>
								<thead>
									<tr>
										<th>Пакет посещений</th>
										<th>Стоимость</th>
										<th>Дата оплаты</th>
									</tr>
								</thead>

								<tbody>
									{paymentsHistoryLoading ? (
										<tr><td colSpan="3">
											<SpinnerLoader />
										</td></tr>
									) : this.getPaymentsHistoryList()}
								</tbody>
							</Table>

							{!paymentsHistoryLoading && paymentsHistoryList.length > paymentsHistoryPerPage && (
								<div className="mt-3">
									{this.getPaymentsHistoryListPagination()}
								</div>
							)}
						</Aux>
					)}
				</div>
			</ContentWrapper>
		);
	}
} 

export default PaymentPage;
