import React, { Component } from 'react';
import { 
	Form, 
	FormGroup, 
	Label, 
	Input, 
	Button,
	Table,
	Spinner,
	Row,
	Col,
	Alert,
	Modal,
	ModalBody,
	Pagination,
	PaginationItem,
	PaginationLink
} from 'reactstrap';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import axios from '../../../axios';

import './AdminPaymentPage.sass';

// Components
import ContentWrapper from '../../../components/ContentWrapper';
import SpinnerLoader from '../../../components/SpinnerLoader';
import Aux from '../../../hoc/Auxilary';

const visitsPackages = [
	{
		id: uuid(),
		name: 'Неограничено',
		letters: '5000',
		price: '0 рублей'
	},
	{
		id: uuid(),
		name: '10 000 посещений',
		letters: 'Неограничено',
		price: '2000 рублей'
	},
	{
		id: uuid(),
		name: '20 000 посещений',
		letters: 'Неограничено',
		price: '4000 рублей'
	}
];

class AdminPaymentPage extends Component {

	state = {
		paymentsHistoryList: [],
		paymentsHistoryPerPage: 5,
		paymentsHistoryPage: 0,

		paymentInfoLoading: true,
		paymentsHistoryLoading: false,
		paymentInfoErrorLoading: false,
		paymentsHistoryErrorLoading: false,
		
		usersToAddVisitsList: [],
		userToAddVisits: '',
		
		visitsToAddUser: '',
		
		visitsPackagesList: [],
		visitsPackagesErrorLoading: false,
		visitsPackagesLoading: false,

		addNewPackageForm: {
			visitsNum: '',
			visitsPrice: ''
		},

		userAddVisitsLoading: false,

		addNewPackageModalOpen: false,
		userAddVisitsModalOpen: false
	};

	componentDidMount () {
		this.getPaymentsHistory();
		this.getVisitPackages();
	}

	getPaymentsHistory = () => {
		this.setState({
			paymentsHistoryLoading: true,
			paymentsHistoryErrorLoading: false
		});

		axios.get('/payment/log/get').then(response => {
			this.setState({
				paymentsHistoryLoading: false
			});

			if (response.data.success) {
				this.setState({
					paymentsHistoryLoading: false,
					paymentsHistoryList: response.data.data
				});
			} else {
				this.setState({
					paymentsHistoryErrorLoading: true
				});
			}
		}).catch(error => {
			this.setState({
				paymentsHistoryLoading: false,
				paymentsHistoryErrorLoading: true
			});
		});
	};

	getVisitPackages = () => {
		this.setState({
			visitsPackagesLoading: true
		});

		setTimeout(() => {
			this.setState({
				visitsPackagesLoading: false,
				visitsPackagesList: visitsPackages
			});
		}, 500);
	};

	getPaymentsHistoryList = () => {
		const { paymentsHistoryList } = this.state;

		let renderPaymentsHistoryList = null;

		if (paymentsHistoryList.length === 0) {
			renderPaymentsHistoryList = (
				<tr>
					<td colSpan="3" className="text-center">
						Не найдено
					</td>
				</tr>
			);		

			return renderPaymentsHistoryList;	
		}

		renderPaymentsHistoryList = paymentsHistoryList.map((payment, i) => {
			return (
				<tr key={payment.id}>
					<td>{payment.name}</td>
					<td>{payment.price}</td>
					<td>{payment.date}</td>
				</tr>
			);
		});

		return renderPaymentsHistoryList;
	};

	getPaymentsHistoryListPagination = () => {
		const { 
			paymentsHistoryList, 
			paymentsHistoryPerPage, 
			paymentsHistoryPage 
		} = this.state;

		const pageQuantity = Math.ceil(paymentsHistoryList.length / paymentsHistoryPerPage);

		let renderPaymentsHistoryPagination = null;

		renderPaymentsHistoryPagination = (
			<Pagination>
				<PaginationItem>
					<PaginationLink first onClick={() => this.setPaymentsHistoryListPage(1)} disabled={paymentsHistoryPage === 0} />
				</PaginationItem>

				<PaginationItem>
					<PaginationLink previous onClick={() => this.setPaymentsHistoryListPage(paymentsHistoryPage + 1 - 1)} disabled={paymentsHistoryPage === 0} />
				</PaginationItem>
				
				{Array.from(Array(pageQuantity).keys())
					.map(page => Number(page) + 1)
					.map(page => (
						<PaginationItem active={paymentsHistoryPage + 1 === page}>
							<PaginationLink
								onClick={() => this.setPaymentsHistoryListPage(page)}
								disabled={paymentsHistoryPage + 1 === page}
							>
								{page}
							</PaginationLink>
						</PaginationItem>
					))}
							
				<PaginationItem>
					<PaginationLink next onClick={() => this.setPaymentsHistoryListPage(paymentsHistoryPage + 1 + 1)} disabled={paymentsHistoryPage + 1 === pageQuantity} />
				</PaginationItem>
					
				<PaginationItem>
					<PaginationLink last onClick={() => this.setPaymentsHistoryListPage(pageQuantity)} disabled={paymentsHistoryPage + 1 === pageQuantity} />
				</PaginationItem>
			</Pagination>
		);

		return renderPaymentsHistoryPagination;
	};

	setPaymentsHistoryListPage = page => {
		const { paymentsHistoryList, paymentsHistoryPage } = this.state;

		if (page > 0) {

			const lastPage = Math.ceil(paymentsHistoryList.length / paymentsHistoryPage);

			if (page > lastPage) {
				this.setState({
					paymentsHistoryPage: lastPage
				});
			} else {
				this.setState({
					paymentsHistoryPage: page - 1
				});
			}
		}
	};

	getVisitsPackagesList = () => {
		const { visitsPackagesList } = this.state;

		let renderVisitsPackagesList = null;

		if (visitsPackagesList.length === 0) {
			renderVisitsPackagesList = (
				<tr>
					<td colSpan="4" className="text-center">
						Не найдено
					</td>
				</tr>
			);		

			return renderVisitsPackagesList;	
		}

		renderVisitsPackagesList = visitsPackagesList.map((visitsPackage, i) => {
			return (
				<tr key={visitsPackage.id}>
					<td>{visitsPackage.name}</td>
					<td>{visitsPackage.letters}</td>
					<td>{visitsPackage.price}</td>
				</tr>
			);
		});

		return renderVisitsPackagesList;
	};

	visitsPackageSelectChange = visitsPackage => {
		this.setState({
			visitsToAddUser: visitsPackage
		});
	};

	userToAddVisitsSelectChange = user => {
		this.setState({
			userToAddVisits: user
		});
	};

	addNewPackage = () => {
		this.setState({
			addNewPackageLoading: true
		});

		setTimeout(() => {
			this.setState({
				addNewPackageLoading: false,
				addNewPackageModalOpen: !this.state.addNewPackageModalOpen
			});

			toast.success('Новый пакет был успешно добавлен');
		}, 500);
	};

	userAddVisits = () => {
		this.setState({
			userAddVisitsLoading: true
		});

		setTimeout(() => {
			this.setState({
				userAddVisitsLoading: false,
				userAddVisitsModalOpen: !this.state.userAddVisitsModalOpen
			});

			toast.success('Посещения пользователю были успешно добавлены.');
		}, 500);
	};

	addNewPackageElemChange = (name, value) => {
		this.setState({
			addNewPackageForm: {
				...this.state.addNewPackageForm,
				[name]: value
			}
		});
	};

	triggerModalAddNewPackage = () => {
		this.setState({
			addNewPackageModalOpen: !this.state.addNewPackageModalOpen
		});
	};

	triggerModalUserAddVisits = () => {
		this.setState({
			userAddVisitsModalOpen: !this.state.userAddVisitsModalOpen
		});
	};

	render () {
		const { 
			visitsPackageValue, 
			paymentsHistoryList,
			paymentsHistoryPerPage,
			paymentsHistoryErrorLoading,
			paymentsHistoryLoading, 
			userToAddVisits,
			visitsToAddUser,
			visitsPackagesErrorLoading,
			addNewPackageLoading,
			visitsPackagesLoading,
			addNewPackageForm,
			userAddVisitsLoading,
			addNewPackageModalOpen,
			userAddVisitsModalOpen
		} = this.state;

		return (
			<ContentWrapper>
				<Modal isOpen={userAddVisitsModalOpen} toggle={() => this.triggerModalUserAddVisits()}>
					<ModalBody className="text-center">
						<h3>Пополнение баланса</h3>

						<p>Пользователю <b>{userToAddVisits}</b> будет зачислен следующий пакет <b>{visitsToAddUser}</b>. Вы действительно хотите выполнить данную операцию?</p>

						<div className="mt-4">
							<Button 
								color="primary" 
								onClick={() => this.userAddVisits()}
								disabled={userAddVisitsLoading}
							>
								Пополнить {userAddVisitsLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
							</Button>
							
							<Button 
								color="secondary" 
								className="ml-2" 
								onClick={() => this.triggerModalUserAddVisits()}
							>
								Отмена
							</Button>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={addNewPackageModalOpen} toggle={() => this.triggerModalAddNewPackage()}>
					<ModalBody className="text-center">
						<h3>Изменить/добавить пакет</h3>

						<p>Укажите количество уникальных посещений для данного пакета, а также его стоимост в рублях. Кол-во посещений и стоимость должны быть больше нуля.</p>

						<FormGroup>
							<Label for="addNewPackageVisitsNumInput">Количество посещений</Label>

							<Input 
								id="addNewPackageVisitsNumInput"
								type="number" 
								value={addNewPackageForm.visitsNum} 
								onChange={e => this.addNewPackageElemChange('visitsNum', e.target.value)} 
							/>
						</FormGroup>

						<FormGroup>
							<Label for="addNewPackagePriceInput">Стоимость в рублях</Label>

							<Input 
								id="addNewPackagePriceInput"
								type="number" 
								value={addNewPackageForm.visitsPrice} 
								onChange={e => this.addNewPackageElemChange('visitsPrice', e.target.value)} 
							/>
						</FormGroup>

						<div>
							<Button 
								color="primary" 
								onClick={() => this.addNewPackage()}
								disabled={addNewPackageLoading}
							>
								Добавить {addNewPackageLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
							</Button>
							
							<Button 
								color="secondary" 
								className="ml-2" 
								onClick={() => this.triggerModalAddNewPackage()}
							>
								Отмена
							</Button>
						</div>
					</ModalBody>
				</Modal>

				<h1>Деньги</h1>

				<div className="payment-history-block mt-4">
					<h4>Пакеты посещений</h4>
				
					{visitsPackagesErrorLoading ? (
						<Alert color="danger">
							Ошибка при загрузке данных

							<br/>

							<Button 
								className="mt-2" 
								size="xs" 
								color="secondary" 
								onClick={() => this.getVisitPackages()}
							>
								Повторить запрос
							</Button>
						</Alert>
					) : (
						<Aux>
							<Table className="payment-visits-packages-table" striped responsive>
								<thead>
									<tr>
										<th>Пакет посещений</th>
										<th>Отправок писем</th>
										<th>Стоимость</th>
									</tr>
								</thead>

								<tbody>
									{visitsPackagesLoading ? (
										<tr><td colSpan="3">
											<SpinnerLoader />
										</td></tr>
									) : this.getVisitsPackagesList()}
								</tbody>
							</Table>

							<Button 
								color="primary" 
								size="lg" 
								onClick={() => this.triggerModalAddNewPackage()}
							>
								Добавить новый пакет
							</Button>
						</Aux>
					)}
				</div>

				<div className="replenish-balance-block mt-4">
					<h4>Пополнить баланс пользователя</h4>

					<Form className="add-visits-user-form">
						<Row>
							<Col lg={6} xl={3}>

								<FormGroup>
									<Label for="userToAddVisitsSelect">Выберите пользователя</Label>

									<Input 
										id="userToAddVisitsSelect" 
										type="select" 
										value={userToAddVisits} 
										onChange={e => this.userToAddVisitsSelectChange(e.target.value)} 
									>
										<option>Amazon (email@mail.ru)</option>
										<option>Amazon 1 (email@mail.ru)</option>
										<option>Amazon 2 (email@mail.ru)</option>
										<option>Amazon 3 (email@mail.ru)</option>
										<option>Amazon 4 (email@mail.ru)</option>
									</Input>
								</FormGroup>

							</Col>
							<Col lg={6} xl={3}>

								<FormGroup>
									<Label for="visitsPackageSelect">Выберите пакет посещений</Label>

									<Input 
										id="visitsPackageSelect" 
										type="select" 
										value={visitsToAddUser} 
										onChange={e => this.visitsPackageSelectChange(e.target.value)}
									>
										<option>10 000 посещений (2000 рублей)</option>
										<option>20 000 посещений (3000 рублей)</option>
										<option>30 000 посещений (4000 рублей)</option>
										<option>40 000 посещений (5000 рублей)</option>
										<option>50 000 посещений (6000 рублей)</option>
									</Input>
								</FormGroup>

							</Col>
							<Col xs={12} xl={3}>

								<Button 
									className="add-visits-user-btn"
									block
									size="lg" 
									color="primary" 
									onClick={() => this.triggerModalUserAddVisits()}
								>
									Добавить
								</Button>

							</Col>
						</Row>
					</Form>
				</div>

				<div className="payment-history-block mt-4">
					<h4>История покупок</h4>
				
					{paymentsHistoryErrorLoading ? (
						<Alert color="danger">
							Ошибка при загрузке данных

							<br/>

							<Button 
								className="mt-2" 
								size="xs" 
								color="secondary" 
								onClick={() => this.getPaymentsHistory()}
							>
								Повторить запрос
							</Button>
						</Alert>
					) : (
						<Aux>
							<Table className="payment-history-table" striped responsive>
								<thead>
									<tr>
										<th>Пакет посещений</th>
										<th>Стоимость</th>
										<th>Дата оплаты</th>
									</tr>
								</thead>

								<tbody>
									{paymentsHistoryLoading ? (
										<tr><td colSpan="3">
											<SpinnerLoader />
										</td></tr>
									) : this.getPaymentsHistoryList()}
								</tbody>
							</Table>

							{!paymentsHistoryLoading && paymentsHistoryList.length > paymentsHistoryPerPage && (
								<div className="mt-3">
									{this.getPaymentsHistoryListPagination()}
								</div>
							)}
						</Aux>
					)}
				</div>
			</ContentWrapper>
		);
	}
}

export default AdminPaymentPage;
