import React from 'react';
import { Link } from 'react-router-dom';

const InternalErrorPage = props => (
    <div className="abs-center wd-xl">
        <div className="text-center mb-4">
            <div className="mb-3">
                <em className="fa fa-wrench fa-5x text-muted"></em>
            </div>
            
            <div className="text-lg mb-3">500</div>
            <p className="lead m-0">Что-то пошло не так :(</p>

            <p>Не беспокойтесь, мы уже исправляем это</p>
            <p>Сейчас, пожалуйста, воспользуйтесь ссылками нижи или попробуйте позже</p>
        </div>

        <ul className="list-inline text-center text-sm mb-4">
            <li className="list-inline-item">
                <Link to="/" className="text-muted">Главная</Link>
            </li>
        
            <li className="text-muted list-inline-item">|</li>
        
            <li className="list-inline-item">
                <Link to="/login" className="text-muted">Вход</Link>
            </li>
        
            <li className="text-muted list-inline-item">|</li>
        
            <li className="list-inline-item">
                <Link to="/register" className="text-muted">Регистрация</Link>
            </li>
        </ul>
    </div>
)

export default InternalErrorPage;
