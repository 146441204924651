import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import axios from '../../axios';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../store/actions/actions';

import HeaderRun from './Header.run'

import './Header.sass';

class Header extends Component {

    state = {
      profileMenuOpen: false
    };

    componentDidMount() {
        HeaderRun();
    }

    toggleCollapsed = e => {
        e.preventDefault();
        
        this.props.actions.toggleSetting('isCollapsed');
        
        this.resize();
    };

    toggleAside = e => {
        e.preventDefault();

        this.props.actions.toggleSetting('asideToggled');
    };

    resize () {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
      
        evt.initUIEvent('resize', true, false, window, 0);
      
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    userLogout = () => {
      let token = localStorage.getItem('authToken');

      axios.post(`/user/logout`, {}, {
        headers: { Authorization: 'Bearer ' + token }
      }).then(response => {
        if (response.data.success) {
          this.props.actions.userLogout();
        }
      });
    };

    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */ }
                <nav className="navbar topnavbar">

                    { /* START navbar header */ }
                    <div className="navbar-header">
                      <span className="navbar-brand">
                        <Link to="/">
                          <div className="brand-logo">
                              <img className="img-fluid" src={require('../../assets/img/logo.svg')} alt="App Logo" />
                          </div>
                        </Link>

                        <Link to="/">
                          <div className="brand-logo-collapsed">
                              <img className="img-fluid" src={require('../../assets/img/logo.svg')} alt="App Logo" />
                          </div>
                        </Link>
                      </span>
                    </div>
                    { /* END navbar header */ }

                    { /* START Left navbar */ }
                    <ul className="navbar-nav mr-auto flex-row">
                      <li className="nav-item">
                          { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
                          <span className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ this.toggleCollapsed }>
                              <FontAwesomeIcon className="sidebar-bar-icon" icon={faBars} />
                          </span>

                          { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                          <span  className="nav-link sidebar-toggle d-md-none" onClick={ this.toggleAside }>
                              <FontAwesomeIcon className="sidebar-bar-icon" icon={faBars} />
                          </span>
                      </li>
                    </ul>

                    { /* END Left navbar */ }
                    { /* START Right Navbar */ }
                    <ul className="navbar-nav flex-row">

                        <Dropdown className="profile-dropdown" isOpen={this.state.profileMenuOpen} toggle={() => this.setState({ profileMenuOpen: !this.state.profileMenuOpen })}>
                          <DropdownToggle id="header-profile-submenu" caret>
                            <span id="header-profile-text">Профиль</span> 
                            <span id="header-profile-email" className="d-none d-sm-inline-block">{this.props.auth.userEmail}</span>
                          </DropdownToggle>
                          
                          <DropdownMenu>
                            <NavLink to="/profile/settings/account" activeClassName="is-active"><DropdownItem>Настройки аккаунта</DropdownItem></NavLink>
                            <NavLink to="/profile/settings/shop" activeClassName="is-active"><DropdownItem>Настройки магазина</DropdownItem></NavLink>
                            <NavLink to="/profile/settings/integration" activeClassName="is-active"><DropdownItem>Интеграция</DropdownItem></NavLink>
                          </DropdownMenu>
                        </Dropdown>

                        { /* END Alert menu */ }
                        { /* START Offsidebar button */ }
                        <li className="nav-item">
                            <span className="nav-link logout-link" onClick={this.userLogout}>
                                <span className="mr-2">Выход</span>
                                <em className="logout-icon icon-power"></em>
                            </span>
                        </li>
                        { /* END Offsidebar menu */ }

                    </ul>
                    { /* END Right Navbar */ }
                </nav>
                { /* END Top Navbar */ }
            </header>
            );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings, auth: state.auth })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
