import {
	USER_LOGIN,
	USER_LOGOUT,
	AUTH_CHECKING_PENDING,
	SET_USER_DATA
} from '../actions/actions';

const initialState = {
	isAuthenticated: false,
	userToken: '',
	userId: '',
	userName: '',
	userEmail: '',
	authPending: false
};

const authenticationReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_CHECKING_PENDING:
			return {
				...state,
				authPending: action.pending ? action.pending : false
			};

		case USER_LOGIN:
			return {
				...state,
				isAuthenticated: true,
				userToken: action.token
			};

		case USER_LOGOUT:
			localStorage.removeItem('authToken');

			return {
				...state,
				isAuthenticated: false,
				userToken: ''
			};

		case SET_USER_DATA:
			return {
				...state,
				userId: action.id,
				userName: action.name,
				userEmail: action.email
			};
			
		default:
			return state;
	}
};

export default authenticationReducer;
