import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Table,
	Card,
	CardBody,
	Button,
	Tooltip,
	Alert,
	Modal,
	ModalBody
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faQuestionCircle,
	faChartBar,
	faClone,
	faPlay,
	faPause,
	faCog,
	faTrashAlt,
	faWrench
} from '@fortawesome/free-solid-svg-icons';
import uuid from 'react-uuid';
import axios from '../../../axios';
import { toast } from 'react-toastify';

// Components
import ContentWrapper from '../../../components/ContentWrapper';
import Aux from '../../../hoc/Auxilary';
import SpinnerLoader from '../../../components/SpinnerLoader';

import './TriggersListPage.sass';

class TriggersListPage extends Component {

	state = {
		triggersList: [],
		deleteTriggerModalOpen: false,
		triggerToDelete: null,
		triggersLoading: true,
		triggersErrorLoading: true
	};

	componentDidMount () {
		this.getTrigggers();
	}

	getTrigggers = () => {
		this.setState({
			triggersLoading: true,
			triggersErrorLoading: false
		});

		axios.get('/triggers/get').then(response => {
			this.setState({
				triggersLoading: false
			});

			if (response.data.success) {
				this.setState({
					triggersList: response.data.data.map(trigger => ({
						...trigger,
						tooltipOpen: false
					}))
				});
			} else {
				this.setState({
					triggersErrorLoading: true
				});
			}
		}).catch(error => {
			this.setState({
				triggersErrorLoading: true,
				triggersLoading: false
			});
		});
	};

	triggerRemove = id => {
		const { deleteTriggerModalOpen } = this.state;

		axios.post(`/triggers/${id}/delete`).then(response => {
			if (response.data.success) {
				let triggersListTemp = this.state.triggersList;

				const triggerToEditIndex = this.state.triggersList.findIndex(trigger => trigger.id === id);

				toast.success(`Триггер ${triggersListTemp[triggerToEditIndex].name} удален`);

				triggersListTemp.splice(triggerToEditIndex, 1);

				this.setState({
					triggersList: triggersListTemp,
					deleteTriggerModalOpen: !deleteTriggerModalOpen
				});
			} else {
				toast.error('Ошибка при удалении триггера');
			}
		}).catch(error => {
			toast.error('Ошибка при удалении триггера');
		});
	};

	triggerStart = id => {
		axios.post(`/triggers/${id}/start`).then(response => {
			if (response.data.success) {
				let triggersListNew = this.state.triggersList;

				const triggerToStartIndex = triggersListNew.findIndex(trigger => trigger.id === id);

				if (triggersListNew[triggerToStartIndex].status === 'S') {
					triggersListNew[triggerToStartIndex].status = 'A';
				} else if (triggersListNew[triggerToStartIndex].status === 'SB') {
					triggersListNew[triggerToStartIndex].status = 'AB';
				}

				toast.success(`Триггер ${triggersListNew[triggerToStartIndex].name} запущен.`);

				this.setState({
					triggersList: triggersListNew
				});
			} else {
				toast.error('Ошибка при запуске триггера');
			}
		}).catch(error => {
			toast.error('Ошибка при запуске триггера');
		});
	};

	triggerPause = id => {
		axios.post(`/triggers/${id}/stop`).then(response => {
			if (response.data.success) {
				let triggersListNew = this.state.triggersList;

				const triggerToStopIndex = triggersListNew.findIndex(trigger => trigger.id === id);

				if (triggersListNew[triggerToStopIndex].status === 'A') {
					triggersListNew[triggerToStopIndex].status = 'S';
				} else if (triggersListNew[triggerToStopIndex].status === 'AB') {
					triggersListNew[triggerToStopIndex].status = 'SB';
				}

				toast.success(`Триггер ${triggersListNew[triggerToStopIndex].name} приостановлен.`);

				this.setState({
					triggersList: triggersListNew
				});
			} else {
				toast.error('Ошибка при остановке триггера');
			}
		}).catch(error => {
			toast.error('Ошибка при остановке триггера');
		});
	};

	triggerModalDeleteTag = id => {
		this.setState({
			deleteTriggerModalOpen: !this.state.deleteTriggerModalOpen,
			triggerToDelete: id
		});
	};

	triggerDuplicate = id => {
		axios.post(`/triggers/${id}/add/child`).then(response => {
			if (response.data.success) {
				let triggersListNew = this.state.triggersList;

				const triggerToDuplicateIndex = triggersListNew.findIndex(trigger => trigger.id === id);

				toast.success(`Триггер ${triggersListNew[triggerToDuplicateIndex].name} дублирован.`);

				this.getTrigggers();
			} else {
				toast.error('Ошибка при дублировании триггера');
			}
		}).catch(error => {
			toast.error('Ошибка при дублировании триггера');
		});
	};

	toggleTriggerTooltip = id => {
		let triggersListNew = this.state.triggersList;

		const triggerIndex = triggersListNew.findIndex(trigger => trigger.id === id);

		triggersListNew[triggerIndex].tooltipOpen = !triggersListNew[triggerIndex].tooltipOpen;

		this.setState({ triggersList: triggersListNew });
	};

	getTriggerTooltipState = id => {
		return this.state.triggersList.find(trigger => trigger.id === id).tooltipOpen;
	};

	getTriggerList = () => {
		let renderTriggerList = null;

		renderTriggerList = this.state.triggersList.map((trigger, i) => {
			let tooltip = null;

			if (trigger.info) {
				tooltip = (
					<Aux>
						<span id={`triggerTooltip${trigger.id}`}>
							<FontAwesomeIcon icon={faQuestionCircle} />
						</span>
						
						<Tooltip
							placement="right"
							isOpen={this.getTriggerTooltipState(trigger.id)}
							target={`triggerTooltip${trigger.id}`}
							toggle={() => this.toggleTriggerTooltip(trigger.id)}
						>
							{trigger.info}
						</Tooltip>
					</Aux>
				);
			}

			return (
				<tr key={trigger.id}>
					<td>{trigger.name} {tooltip}</td>
					<td>{this.getTriggerStatusName(trigger.status)}</td>
					<td className="text-right">{this.getTriggerControlsList(trigger.id, trigger.status, trigger.type)}</td>
				</tr>
			);
		});

		return renderTriggerList;
	};

	getTriggerControlsList = (triggerId, status, type) => {
		let controls = [];

		switch (status) {
			case 'A':
				controls = [
					'information',
					'duplicate',
					'pause',
					'settings'
				];

				break;

			case 'S':
				controls = [
					'information',
					'duplicate',
					'play',
					'settings'
				];

				break;

			case 'W':
				controls = ['integration'];

				break;

			case 'B':
				const renderControlsList = <div>Доступно на платном тарифе</div>;
				
				return renderControlsList;

			case 'AB':
				controls = [
					'information',
					'pause',
					'settings'
				];

				break;

			case 'SB':
				controls = [
					'information',
					'play',
					'settings'
				];

				break;

			default:
		}

		if (type === 'child') {
			controls.push('delete');
		}

		controls = controls.map(control => ({
			id: uuid(),
			name: control
		}));

		const renderControlsList = controls.map((control, i) => {
			let controlItem = null;

			switch (control.name) {
				case 'integration':
					controlItem = <li key={control.id} className="control-item"><Link to="/profile/settings/integration"><FontAwesomeIcon icon={faWrench} /></Link></li>;

					break;
					
				case 'information':
					controlItem = <li key={control.id} className="control-item"><Link to="/?stat=trigger"><FontAwesomeIcon icon={faChartBar} /></Link></li>;

					break;
					
				case 'duplicate':
					controlItem = <li key={control.id} className="control-item" onClick={() => this.triggerDuplicate(triggerId)}><FontAwesomeIcon icon={faClone} /></li>;

					break;
					
				case 'pause':
					controlItem = <li key={control.id} className="control-item" onClick={() => this.triggerPause(triggerId)}><FontAwesomeIcon icon={faPause} /></li>;

					break;
					
				case 'play':
					controlItem = <li key={control.id} className="control-item" onClick={() => this.triggerStart(triggerId)}><FontAwesomeIcon icon={faPlay} /></li>;

					break;
					
				case 'settings':
					controlItem = <li key={control.id} className="control-item"><Link to={`/trigger/${triggerId}/edit`}><FontAwesomeIcon icon={faCog} /></Link></li>;

					break;

				case 'delete':
					controlItem = <li key={control.id} className="control-item" onClick={() => this.triggerModalDeleteTag(triggerId)}><FontAwesomeIcon icon={faTrashAlt} /></li>;					

					break;

				default:
			}

			return controlItem;	
		});

		return <ul className="triggers-controls-list">{renderControlsList}</ul>;
	};

	getTriggerStatusName = status => {
		let statusName = '';

		switch (status) {
			case 'A':
				statusName = 'Активен';

				break;

			case 'S':
				statusName = 'Приостановлен';

				break;

			case 'notactive':
				statusName = 'Не активен';

				break;

			case 'W':
				statusName = 'Требует интеграции';

				break;

			default:
		}

		return statusName;
	};

	render () {
		const { 
			deleteTriggerModalOpen, 
			triggerToDelete, 
			triggersLoading,
			triggersErrorLoading 
		} = this.state;

		return (
			<ContentWrapper>
				<Modal isOpen={deleteTriggerModalOpen} toggle={() => this.setState({ deleteTriggerModalOpen: !deleteTriggerModalOpen })}>
					<ModalBody className="text-center">
						<h3>Удалить триггер?</h3>

						<p>Все настройки триггера будут удалены. Отменить данное действие невозможно.</p>

						<div>
							<Button color="primary" onClick={() => this.triggerRemove(triggerToDelete)}>Удалить</Button>
							
							<Button 
								color="secondary" 
								className="ml-2" 
								onClick={() => this.setState({ deleteTriggerModalOpen: !deleteTriggerModalOpen })}
							>
								Отмена
							</Button>
						</div>
					</ModalBody>
				</Modal>

				<Card>
					<CardBody>
						{triggersErrorLoading ? (
							<Alert color="danger" className="text-center">
								Ошибка при загрузке данных
								
								<br/>
								
								<Button 
									className="mt-2" 
									color="secondary" 
									size="xs" 
									onClick={() => this.getTrigggers()}
								>
									Повторить запрос
								</Button>
							</Alert>	
						) : (
							<Table className="triggers-list-table" striped responsive>
								<thead>
									<tr>
										<th>Название триггера</th>
										<th>Статус</th>
									</tr>
								</thead>

								<tbody>
									{triggersLoading ? (
										<tr><td colSpan="3">
											<SpinnerLoader />
										</td></tr>
									) : this.getTriggerList()}
								</tbody>
							</Table>
						)}
					</CardBody>
				</Card>
			</ContentWrapper>
		);
	}
}

export default TriggersListPage;
