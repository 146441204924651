import React, { Component } from 'react';
import { Card, CardBody, Alert, Button } from 'reactstrap';
import { userLogin, setUserData } from '../../store/actions/actions';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import axios from '../../axios';

// Components
import Aux from '../../hoc/Auxilary';
import SpinnerLoader from '../../components/SpinnerLoader';

class ConfirmRegister extends Component {

	state = {
		registerConfirmLoading: true,
		registerConfirmErrorLoading: false,
		registerConfirmError: false, 
		confirmRegisterErrors: []
	};

	componentDidMount () {
		this.registerConfirm();
	}

	registerConfirm = () => {
		const { 
			location,
			userLogin,
			setUserData,
			history
		} = this.props;

		const params = queryString.parse(location.search);

		this.setState({
			registerConfirmLoading: true,
			registerConfirmErrorLoading: false
		});

		const token = localStorage.getItem('authToken');

		axios.post(`/user/register/confirm`, {
			email: params.email, 
			token: params.token
		}, {
			headers: { Authorization: 'Bearer ' + token }
		}).then(response => {
			this.setState({
				registerConfirmLoading: false
			});

			if (response.data.success) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.auth_token}`;

				userLogin(response.data.data.auth_token);
				setUserData(response.data.data.user.id, response.data.data.user.name, response.data.data.user.email);

				localStorage.setItem('authToken', response.data.data.auth_token);

				setTimeout(() => history.push('/'), 3000);
			} else {
				let confirmRegisterErrors = [];

                for (let error in response.data.errors) {
                    confirmRegisterErrors.push({ id: uuid(), error: response.data.errors[error] });
                }

                this.setState({
                	confirmRegisterErrors,
                	registerConfirmError: true
                });
			}
		}).catch(error => {
			this.setState({
				registerConfirmLoading: false
			});

			toast.error('Возникла ошибка при подтверждении регистрации');
		});
	};

	render () {
		const { 
			registerConfirmLoading,
			registerConfirmErrorLoading,
			registerConfirmError,
			confirmRegisterErrors 
		} = this.state;

		return (
			<div className="block-center mt-4 wd-xl">
				{registerConfirmErrorLoading ? (
					<Alert color="danger">
						Ошибка при активации

						<br/>

						<Button 
							className="mt-2"
							color="secondary" 
							size="xs" 
							onClick={() => this.registerConfirm()}
						>
							Повторить запрос
						</Button>
					</Alert>
				) : (
					<Card>
						<CardBody className="text-center">
							{registerConfirmLoading
								?
									<SpinnerLoader />
								:
									<Alert color={registerConfirmError ? 'danger' : 'success'}>
										{registerConfirmError
											?
												<Aux>
													<h3 style={{ lineHeight: '35px' }}>Ошибка активации :(</h3>
													<div>{confirmRegisterErrors.map(error => <div key={error.id}>{error.error}</div>)}</div>
												</Aux>
											:
												<Aux>
													<h3 style={{ lineHeight: '35px' }}>Аккаунт успешно активирован!</h3>
													<p>Желаем успешной работы!</p>
													<p>Через 3 секунды Мы перенаправим Вас на Главную страницу</p>
												</Aux>									
										}
										
									</Alert>
							}
						</CardBody>
					</Card>
				)}
			</div>
		);
	}
}

export default connect(
	null,
	{ userLogin, setUserData }
)(ConfirmRegister);
