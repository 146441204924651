import React, { Component } from 'react';
import { Spinner, Alert } from 'reactstrap';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import uuid from 'react-uuid';
import axios from '../../axios';

// Components
import FormValidator from '../../components/FormValidator';
import PasswordNewChangedMessage from '../../components/Auth/PasswordNewChangedMessage';

class RecoverNewPage extends Component {

	state = {
		formRecoverNew: {
			password: '',
            password2: ''
		},
		userRecoverNewLoading: false,
		recoverNewError: false,
        recoverNewErrors: [],
        passwordNewChanged: false
	};

	validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });

    };

    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    };

    onSubmitRecoverNew = e => {
    	e.preventDefault()

        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

        const { errors, hasError } = FormValidator.bulkValidate(inputs);

        const { formRecoverNew } = this.state;
        const { location, history } = this.props;

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        if (!hasError) {
        	this.setState({
        		userRecoverNewLoading: true
        	});

        	const params = queryString.parse(location.search);

        	const token = localStorage.getItem('authToken');

        	axios.post(`/user/password/reset/confirm`, {
        		email: params.email,
        		password: formRecoverNew.password,
        		password_confirm: formRecoverNew.password2
        	}, {
        		headers: { Authorization: 'Bearer ' + token }
        	}).then(response => {
    			this.setState({
    				userRecoverNewLoading: false
    			});

    			if (response.data.success) {
    				
    				this.setState({
    					passwordNewChanged: true
    				});

    				setTimeout(() => history.push('/login'), 3000);

    			} else {
    				let recoverNewErrors = [];

                    for (let error in response.data.errors) {
                        recoverNewErrors.push({ id: uuid(), error: response.data.errors[error] });
                    }

                    this.setState({
                    	recoverNewErrors,
                    	recoverNewError: true
                    });
    			}
    		});
        }
    };

	render () {
		return (
			<div className="block-center mt-4 wd-xl">

				<Helmet>
                    <title>Boostmy.Email - авторизация</title>
                </Helmet>

				{this.state.passwordNewChanged
					?
						<PasswordNewChangedMessage />
					:
						<div className="card card-flat">
			                <div className="card-header text-center bg-dark">
			                    <a href="!#">
			                        <img className="block-center" src={require('../../assets/img/logo.svg')} alt="Logo"/>
			                    </a>
			                </div>

			                <div className="card-body">
			                    <p className="text-center py-2">НОВЫЙ ПАРОЛЬ</p>

			                     {this.state.recoverNewError
		                            ?
		                                <Alert className="mb-4" color="danger">
		                                    {this.state.recoverNewErrors.map(error => <div key={error.id}>{error.error}</div>)}
		                                </Alert>
		                            : 
		                                null
		                        }

			                    <form className="mb-3" name="formRecoverNew" onSubmit={this.onSubmitRecoverNew}>
			                    	<div className="form-group">
			                            <label className="text-muted" htmlFor="signupInputPassword1">Пароль</label>
			                            
			                            <div className="input-group with-focus">
			                                <Input type="password"
			                                    id="id-password"
			                                    name="password"
			                                    className="border-right-0"
			                                    placeholder="Введите пароль"
			                                    invalid={this.hasError('formRecoverNew','password','required') ? true : false}
			                                    onChange={this.validateOnChange}
			                                    data-validate='["required"]'
			                                    value={this.state.formRecoverNew.password}
			                                />
			                            
			                                <div className="input-group-append">
			                                    <span className="input-group-text text-muted bg-transparent border-left-0">
			                                        <FontAwesomeIcon icon={faLock} />
			                                    </span>
			                                </div>
			                            
			                                <span className="invalid-feedback">Поле обязательно</span>
			                            </div>
			                        </div>

			                        <div className="form-group">
			                            <label className="text-muted" htmlFor="signupInputRePassword1">Подтвердите пароль</label>
			                            
			                            <div className="input-group with-focus">
			                                <Input type="password" name="password2"
			                                    className="border-right-0"
			                                    placeholder="Подтвердите пароль"
			                                    invalid={this.hasError('formRecoverNew','password2','equalto') ? true : false}
			                                    onChange={this.validateOnChange}
			                                    data-validate='["equalto"]'
			                                    value={this.state.formRecoverNew.password2}
			                                    data-param="id-password"
			                                />
			                            
			                                <div className="input-group-append">
			                                    <span className="input-group-text text-muted bg-transparent border-left-0">
			                                        <FontAwesomeIcon icon={faLock} />
			                                    </span>
			                                </div>
			                            
			                                <span className="invalid-feedback">Поле должно совпадать с предыдущим</span>
			                            </div>
			                        </div>

			                        <button className="btn btn-block btn-primary mt-3" type="submit" disabled={this.state.userRecoverNewLoading}>Обновить {this.state.userRecoverNewLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}</button>
			                    </form>
			                </div>
		                </div>
				}
			</div>
		);
	}
}

export default RecoverNewPage;
