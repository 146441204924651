import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Card,
	CardHeader,
	CardBody,
	Button,
	ButtonGroup,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Input,
	Alert,
	Spinner,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCircle, faSmile } from '@fortawesome/free-solid-svg-icons';
import AceEditor from "react-ace";
import { Picker } from 'emoji-mart';
import axios from '../../../axios';
import { toast } from 'react-toastify';

import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-monokai";

// Components
import ContentWrapper from '../../../components/ContentWrapper';
import SpinnerLoader from '../../../components/SpinnerLoader';
import Aux from '../../../hoc/Auxilary';

import 'emoji-mart/css/emoji-mart.css';

import './TriggerEditPage.sass';

class TriggerEditPage extends Component {

	state = {
		isMainTemplate: true,
		maintTemplateHTML: '',
		productsTemplateHTML: '',
		mainTemplateCodes: [],
		productsTemplateCodes: [
			{
				id: 1,
				name: 'Ссылка на товар',
				tag: '{{ITEM.LINK}}'
			},
			{
				id: 2,
				name: 'Изображение',
				tag: '{{ITEM.IMAGE}}'
			},
			{
				id: 3,
				name: 'Цена',
				tag: '{{ITEM.PRICE}}'
			},
			{
				id: 4,
				name: 'Название',
				tag: '{{ITEM.NAME}}'
			}
		],
		isProductsTemplateAvailable: false,
		productsList: [],
		triggerSettingsModalOpen: false,
		isEmojiPickerOpen: false,
		triggerRulesData: {
			triggerName: '',
			triggerSubject: '',
			triggerUtm: '',
			triggerTimeType: '',
			triggerTimeDays: 0,
			triggerTimeHours: 0,
			triggerTimeMinutes: 0
		},
		shortcodesMenuOpen: false,
		mainTemplateEditorRef: React.createRef(),
		productsTemplateEditorRef: React.createRef(),
		triggerLetterLoading: true,
		triggerLetterErrorLoading: false,
		triggerRulesLoading: true,
		triggerRulesErrorLoading: false,
		triggersRulesSaveLoading: false,
		triggerLetterSaveLoading: false,
		saveTriggerErrors: [],
		saveTriggerError: false
	};

	componentDidMount () {
		document.body.classList.add('trigger-edit-page');

		this.getTriggerRules();
		this.getTriggerLetter();
	}
	
	componentWillUnmount () {
		document.body.classList.remove('trigger-edit-page');
	}

	getTriggerRules = () => {
		this.setState({
			triggerRulesLoading: true,
			triggerRulesErrorLoading: false
		});

		axios.get(`/triggers/${this.props.match.params.triggerId}/rules`)
			.then(response => {
				this.setState({
					triggerRulesLoading: false
				});

				if (response.data.success) {
					const rulesData = response.data.data;

					let triggerRulesData = {
						triggerName: rulesData.name,
						triggerSubject: rulesData.subject,
						triggerUtm: rulesData.utm,
						triggerTimeType: rulesData.time.type,
						triggerTimeDays: 0,
						triggerTimeHours: 0,
						triggerTimeMinutes: 0
					};

					if (rulesData.time.type === 'schedule') {
						triggerRulesData = {
							...triggerRulesData,
							triggerTimeDays: rulesData.time.value.days,
							triggerTimeHours: rulesData.time.value.hours,
							triggerTimeMinutes: rulesData.time.value.minutes
						};
					}

					this.setState({
						triggerRulesData
					});
				} else {
					this.setState({
						triggerRulesErrorLoading: true
					});
				}
			}).catch(error => {
				this.setState({
					triggerRulesErrorLoading: true,
					triggerRulesLoading: false
				});
			});
	};

	getTriggerLetter = () => {
		this.setState({
			triggerLetterLoading: true,
			triggerLetterErrorLoading: false
		});

		axios.get(`/triggers/${this.props.match.params.triggerId}/letter`)
			.then(response => {
				this.setState({
					triggerLetterLoading: false
				});

				if (response.data.success) {
					this.setState({
						maintTemplateHTML: response.data.data.main.code,
						mainTemplateCodes: response.data.data.main.tags
					});

					if (response.data.data.items) {
						this.setState({
							isProductsTemplateAvailable: true,
							productsTemplateHTML: response.data.data.items.code,
							productsList: response.data.data.items.testItems,
							mainTemplateCodes: [
								...response.data.data.main.tags,
								{ 
									id: response.data.data.main.tags.map(tag => tag.id).reduce((tagPrevId, tagNextId) => Number(tagPrevId) + Number(tagNextId)),
									name: 'Блок товаров',
									tag: '{{ITEMS_BLOCK}}'
								}
							]
						});
					}
				} else {
					this.setState({
						triggerLetterErrorLoading: true
					});

					document.body.classList.remove('trigger-edit-page');
				}
			}).catch(error => {
				this.setState({
					triggerLetterErrorLoading: true,
					triggerLetterLoading: false
				});

				document.body.classList.remove('trigger-edit-page');
			});
	};

	saveTriggerRules = () => {
		const { triggerRulesData } = this.state;

		let params = {
			subject: triggerRulesData.triggerSubject,
			utm: triggerRulesData.triggerUtm,
			time: triggerRulesData.triggerTimeType
		};

		if (triggerRulesData.triggerTimeType === 'schedule') {
			params.timerDays = triggerRulesData.triggerTimeDays;
			params.timerHours = triggerRulesData.triggerTimeHours;
			params.timerMinutes = triggerRulesData.triggerTimeMinutes;
		}

		this.setState({
			triggersRulesSaveLoading: true,
			saveTriggerError: false,
			saveTriggerErrors: []
		});

		axios.post(`/triggers/${this.props.match.params.triggerId}/save/rules`, params)
			.then(response => {
				this.setState({
					triggersRulesSaveLoading: false
				});

				if (response.data.success) {
					toast.success('Настройки триггера успешно сохранены.');

					this.setState({
						triggerSettingsModalOpen: false
					});
				} else {
					let saveTriggerErrors = [];

                    for (let error in response.data.errors) {
                        saveTriggerErrors.push(response.data.errors[error]);
                    }

                    this.setState({
                    	saveTriggerErrors,
                    	saveTriggerError: true
                    });
				}
			}).catch(error => {
				this.setState({
					triggersRulesSaveLoading: false
				});

				toast.error('Ошибка при сохранении настроек триггера');
			});
	};

	triggerLetterSave = () => {
		const { maintTemplateHTML, productsTemplateHTML } = this.state;

		this.setState({
			triggerLetterSaveLoading: true
		});

		let params = {
			main: maintTemplateHTML
		};

		if (this.state.isProductsTemplateAvailable) {
			params.items = productsTemplateHTML;
		}

		axios.post(`/triggers/${this.props.match.params.triggerId}/save/letter`, params)
			.then(response => {
				this.setState({
					triggerLetterSaveLoading: false
				});

				if (response.data.success) {
					toast.success('Настройки письма успешно сохранены.');
				} else {
                    toast.error('Ошибка при сохранении настроек письма');
				}
			}).catch(error => {
				this.setState({
					triggerLetterSaveLoading: false
				});
			});
	};

	triggerRulesElementChange = (name, value) => {
		this.setState({
			triggerRulesData: {
				...this.state.triggerRulesData,
				[name]: value
			}
		});
	};

	setTemplateType = type => {
		if (type === 'main') {
			this.setState({
				isMainTemplate: true
			});
		} else {
			this.setState({
				isMainTemplate: false
			});
		}
	};

	templateChange = (type, value) => {
		if (type === 'main') {
			this.setState({
				maintTemplateHTML: value
			});
		} else if (type === 'products') {
			this.setState({
				productsTemplateHTML: value
			});
		}
	};

	mainTemplateRender = value => {
		return value.replace('{{ITEMS_BLOCK}}', this.productsListRender())
			.replace(/(<br\/>|<br>)+/g,"<br/>");
	};

	productsTemplateRender = value => {
		const product = this.state.productsList[0];

		return value
				.replace(/{{ITEM.LINK}}/g, product.url)
				.replace(/{{ITEM.IMAGE}}/g, product.img)
				.replace(/{{ITEM.PRICE}}/g, product.price)
				.replace(/{{ITEM.NAME}}/g, product.name);
	};

	productsListRender = () => {
		const { 
			productsList, 
			productsTemplateHTML
		} = this.state;

		return productsList.map(product => {
			return productsTemplateHTML
						.replace(/{{ITEM.LINK}}/g, product.url)
						.replace(/{{ITEM.IMAGE}}/g, product.img)
						.replace(/{{ITEM.PRICE}}/g, product.price)
						.replace(/{{ITEM.NAME}}/g, product.name);
		}).join('');
	};

	toggleTriggerSettingsModal = () => {
		this.setState({
			triggerSettingsModalOpen : !this.state.triggerSettingsModalOpen
		});
	};

	triggerSettingsModalClose = () => {
		this.setState({
			triggerSettingsModalOpen: false
		});
	};

	addEmoji = e => {
		const { triggerRulesData } = this.state;

		const emoji = e.native;

		this.setState({
			triggerRulesData: {
				...triggerRulesData,
				triggerSubject: triggerRulesData.triggerSubject + emoji
			}
		});	
	};

	emojiPickerOpen = () => {
		this.setState({
			isEmojiPickerOpen: !this.state.isEmojiPickerOpen
		});
	};

	getShortcodesList = () => {
		const { 
			isMainTemplate,
			mainTemplateCodes,
			productsTemplateCodes
		} = this.state;

		const codesList = isMainTemplate ? mainTemplateCodes : productsTemplateCodes;

		return codesList.map(tag => (
			<DropdownItem key={tag.id} onClick={() => this.addMainShortcode(tag.id)}>{tag.name}</DropdownItem>
		));
	};

	addMainShortcode = id => {
		const { 
			isMainTemplate,
			mainTemplateCodes,
			productsTemplateCodes
		} = this.state;

		const codes = isMainTemplate ? mainTemplateCodes : productsTemplateCodes;
		const tag = codes.find(code => code.id === id);
		const tagCode = tag.tag;
		const editor = window.ace.edit(isMainTemplate ? 'mainTemplateEditor' : 'productsTemplateEditor');

		editor.session.insert(editor.getCursorPosition(), tagCode);
	};

	render () {
		const { 
			triggerRulesData, 
			triggerLetterLoading,
			triggerRulesLoading, 
			isEmojiPickerOpen, 
			triggerSettingsModalOpen,
			triggersRulesSaveLoading,
			triggerLetterSaveLoading,
			isProductsTemplateAvailable,
			shortcodesMenuOpen,
			isMainTemplate,
			maintTemplateHTML,
			productsTemplateHTML,
			triggerLetterErrorLoading,
			triggerRulesErrorLoading,
			saveTriggerErrors,
			saveTriggerError
		} = this.state;

		return (
			<ContentWrapper>
				{triggerLetterErrorLoading ? (
					<Alert color="danger" className="text-center">
						Ошибка при загрузке данных
						
						<br/>
						
						<Button 
							className="mt-2" 
							color="secondary" 
							size="xs" 
							onClick={() => this.getTriggerLetter()}
						>
							Повторить запрос
						</Button>
					</Alert>	
				) : (
					<Aux>
						{triggerLetterLoading
							?
								<div className="mt-4"><SpinnerLoader /></div>
							: 
								<Card className="trigger-editor-card">
									<CardHeader className="trigger-edit-header">
										<div className="container-fluid">
											<div className="row">
												<div className="col-lg-6">

													<h3 className="trigger-edit-title">
														{triggerRulesData.triggerName}
														
														<FontAwesomeIcon 
															style={{ cursor: 'pointer' }}
															className="ml-2" 
															onClick={() => this.toggleTriggerSettingsModal()} 
															icon={faCog}
														/>
													</h3>

													<p>
														Письмо отправляется 
													
														<span className="underline">
															{triggerRulesData.triggerTimeType === 'normal' || 
															(
																triggerRulesData.triggerTimeDays === 0 && 
																triggerRulesData.triggerTimeHours === 0 && 
																triggerRulesData.triggerTimeMinutes === 0
															) 
																? 
																	' мгновенно ' 
																: 
																	` через 
																	${triggerRulesData.triggerTimeDays !== 0 ? triggerRulesData.triggerTimeDays + ' дн,' : ''} 
																	${triggerRulesData.triggerTimeHours !== 0 ? triggerRulesData.triggerTimeHours + ' ч,' : ''} 
																	${triggerRulesData.triggerTimeMinutes !== 0 ? triggerRulesData.triggerTimeMinutes + ' мин' : ''}`
															}
														</span> 
													
														&nbsp; после срабатывания триггера
													</p>

													<Modal isOpen={triggerSettingsModalOpen} toggle={() => this.toggleTriggerSettingsModal()}>
														{triggerRulesErrorLoading ? (
															<ModalBody>
																<Alert color="danger">
																	Ошибка при загрузке данных

																	<br/>
																
																	<Button 
																		className="mt-2" 
																		size="xs" 
																		color="secondary" 
																		onClick={() => this.getTriggerRules()}
																	>
																		Повторить запрос
																	</Button>
																</Alert>
															</ModalBody>
														) : (
															<Aux>
																{triggerRulesLoading
																	? 
																		<ModalBody>
																			<SpinnerLoader />
																		</ModalBody>
																	:
																		<Aux>
																			<ModalHeader>
																				<h3>{triggerRulesData.triggerName}</h3>
																			</ModalHeader>
											
																			<ModalBody>
																				{saveTriggerError && (
																					<Alert color="danger">
																						{saveTriggerErrors.map(error => <div>{error}</div>)}
																					</Alert>
																				)}

																				<FormGroup className="letter-subject-title-fg">
																					<Label>Тема письма</Label>
																					
																					<Input 
																						type="text" 
																						value={triggerRulesData.triggerSubject} 
																						onChange={e => this.triggerRulesElementChange('triggerSubject', e.target.value)} 
																						placeholder="Введите тему"
																					/>

																					<span 
																						className="emoji-picker-trigger" 
																						onClick={() => this.emojiPickerOpen()}
																					>
																						<FontAwesomeIcon icon={faSmile} />
																					</span>

																					<Picker 
																						className="emoji-picker" 
																						style={{ display: isEmojiPickerOpen ? 'block' : 'none' }} 
																						onSelect={e => this.addEmoji(e)}
																					/>
																				</FormGroup>

																				<FormGroup>
																					<Label>utm_campaign</Label>
																					
																					<Input 
																						type="text" 
																						value={triggerRulesData.triggerUtm} 
																						onChange={e => this.triggerRulesElementChange('triggerUtm', e.target.value)} 
																						placeholder="Название utm_campaign"
																					/>
																				</FormGroup>

																				<FormGroup>
																					<label>Через какое время отправлять</label>

																					<FormGroup>
																						<label className="c-radio">
											                                            	<Input 
											                                            		id="timeToSendInput1" 
											                                            		type="radio" 
											                                            		name="timeToSendInput"
											                                            		value='normal'
											                                            		onChange={e => this.triggerRulesElementChange('triggerTimeType', e.target.value)} 
											                                            		checked={triggerRulesData.triggerTimeType === 'normal'}
											                                            	/>

											                                            	<FontAwesomeIcon icon={faCircle} /> Мгновенно
											                                            </label>
											                                        	
											                                        	<label className="c-radio">
											                                            	<Input 
											                                            		id="timeToSendInput2" 
											                                            		type="radio" 
											                                            		name="timeToSendInput"
											                                            		value='schedule'
											                                            		onChange={e => this.triggerRulesElementChange('triggerTimeType', e.target.value)} 
											                                            		checked={triggerRulesData.triggerTimeType === 'schedule'}
											                                            	/>

											                                            	<FontAwesomeIcon icon={faCircle} /> Через заданное время
											                                           </label>
											                                        </FormGroup>
										                                        </FormGroup>

										                                        {triggerRulesData.triggerTimeType === 'schedule' ? (
										                                        	<div className="row">
											                                        	<div className="col-4">

											                                        		<FormGroup>
																								<Label>Дней</Label>
																								
																								<Input 
																									type="number"
																									min={0}
																									value={triggerRulesData.triggerTimeDays}
																									onChange={e => this.triggerRulesElementChange('triggerTimeDays', e.target.value)} 
																								/>
																							</FormGroup>

											                                        	</div>
											                                        	<div className="col-4">

											                                        		<FormGroup>
																								<Label>Часов</Label>
																								
																								<Input 
																									type="number"
																									min={0}
																									max={23}
																									value={triggerRulesData.triggerTimeHours}
																									onChange={e => this.triggerRulesElementChange('triggerTimeHours', e.target.value)} 
																								/>
																							</FormGroup>

											                                        	</div>
											                                        	<div className="col-4">

											                                        		<FormGroup>
																								<Label>Минут</Label>
																								
																								<Input 
																									type="number"
																									min={0}
																									max={59}
																									value={triggerRulesData.triggerTimeMinutes}
																									onChange={e => this.triggerRulesElementChange('triggerTimeMinutes', e.target.value)} 
																								/>
																							</FormGroup>

											                                        	</div>
											                                        </div>
										                                        ) : null}

										                                        <div className="mt-3 text-right">
										                                        	<Button 
										                                        		color="primary" 
										                                        		size="lg" 
										                                        		onClick={() => this.saveTriggerRules()} 
										                                        		disabled={triggersRulesSaveLoading}
										                                        	>
										                                        		Сохранить {triggersRulesSaveLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
										                                        	</Button>

										                                        	<Button 
										                                        		className="ml-2" 
										                                        		color="secondary" 
										                                        		size="lg" 
										                                        		onClick={() => this.triggerSettingsModalClose()}
										                                        	>
										                                        		Отмена
										                                        	</Button>
										                                        </div>
																			</ModalBody>
																		</Aux>
																}
															</Aux>
														)}
													</Modal>

												</div>
												<div className="col-lg-6 mt-3 text-lg-right">

													<Button 
														color="primary" 
														size="lg" 
														onClick={() => this.triggerLetterSave()}
														disabled={triggerLetterSaveLoading}
													>
														Сохранить {triggerLetterSaveLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
													</Button>

													<Link to='/triggers'><Button className="ml-3" color="secondary" size="lg">Отмена</Button></Link>

												</div>
											</div>
										</div>
									</CardHeader>

									<CardBody>
										<div className="container-fluid">
											<div className="row">
												<div className="col-xl-6 template-editor-col">

													<ButtonGroup>
														<Button 
															color="primary" 
															size="lg" 
															onClick={() => this.setTemplateType('main')} 
															active={this.state.isMainTemplate}
														>
															Основной шаблон
														</Button>

														{isProductsTemplateAvailable ? (
															<Button 
																color="primary" 
																size="lg" 
																onClick={() => this.setTemplateType('products')} 
																active={!this.state.isMainTemplate}
															>
																Карточка товара
															</Button>
														) : null}
													</ButtonGroup>

													<div className="main-template-editor-wrap">
														<Dropdown 
															className="main-template-shortcodes-dropdown" 
															isOpen={shortcodesMenuOpen} 
															toggle={() => this.setState({ shortcodesMenuOpen: !shortcodesMenuOpen })}
														>
															<DropdownToggle caret>Шорткоды</DropdownToggle>

															<DropdownMenu>
																{this.getShortcodesList()}
															</DropdownMenu>
														</Dropdown>

														<AceEditor
															className="main-template-editor mt-4"
															placeholder="HTML шаблон"
															mode="html"
															theme="monokai"
															value={isMainTemplate ? maintTemplateHTML : productsTemplateHTML}
															onChange={e => this.templateChange(isMainTemplate ? 'main' : 'products', e)}
															name={isMainTemplate ? 'mainTemplateEditor' : 'productsTemplateEditor'}
															fontSize={14}
															showPrintMargin={true}
															showGutter={true}
															highlightActiveLine={true}
															setOptions={{
																enableBasicAutocompletion: true,
																enableLiveAutocompletion: true,
																enableSnippets: true,
																showLineNumbers: true,
																tabSize: 2
															}}
														/>
													</div>

												</div>
												<div className="col-xl-6 template-render-col">

													<div 
														className="template-render" 
														dangerouslySetInnerHTML={{
															__html: isMainTemplate 
																? 
																	this.mainTemplateRender(maintTemplateHTML) 
																: 
																	this.productsTemplateRender(productsTemplateHTML) 
														}}>
													</div>

												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							
						}
					</Aux>
				)}
			</ContentWrapper>
		);
	}
}

export default TriggerEditPage;
