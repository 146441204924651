import React, { Component } from 'react';
import { Card, CardBody, Alert, Button } from 'reactstrap';
import { userLogin, setUserData } from '../../store/actions/actions';
import queryString from 'query-string';
import { connect } from 'react-redux';
import axios from '../../axios';

// Components
import Aux from '../../hoc/Auxilary';
import SpinnerLoader from '../../components/SpinnerLoader';

class ConfirmEmailPage extends Component {

	state = {
		emailConfirmLoading: true,
		emailConfirmErrorLoading: false
	};

	componentDidMount () {
		this.confirmEmail();
	}

	confirmEmail = () => {
		const { location, userLogin, history } = this.props;

		this.setState({
			emailConfirmLoading: true,
			emailConfirmErrorLoading: false
		});

		const params = queryString.parse(location.search);

		const token = localStorage.getItem('authToken');

		axios.get(`/settings/user/email/confirm?email=${params.email}&token=${params.token}`, {
			headers: { Authorization: 'Bearer ' + token }
		}).then(response => {
			this.setState({
				emailConfirmLoading: false
			});

			if (response.data.success) {
				userLogin(params.token);

				localStorage.setItem('authToken', params.token);

				setTimeout(() => history.push('/'), 3000);
			} else {
				this.setState({
					emailConfirmErrorLoading: true
				});
			}
		}).catch(error => {
			this.setState({
				emailConfirmErrorLoading: true
			});
		});
	};

	render () {
		const { emailConfirmLoading, emailConfirmErrorLoading } = this.state;

		return (
			<div className="block-center mt-4 wd-xl">
				<Card>
					<CardBody className="text-center">
						{emailConfirmLoading
							?
								<SpinnerLoader />
							:
								<Alert className="text-center" color={emailConfirmErrorLoading ? 'danger' : 'success'}>
									{emailConfirmErrorLoading
										?
											<Aux>
												<h3 style={{ lineHeight: '35px' }}>Ошибка активации :(</h3>
												
												<Button 
													className="mt-2"
													color="secondary" 
													size="xs" 
													onClick={() => this.confirmEmail()}
												>
													Повторить запрос
												</Button>
											</Aux>
										:
											<Aux>
												<h3 style={{ lineHeight: '35px' }}>Почта успешно активирована!</h3>
												<p>Желаем успешной работы!</p>
												<p>Через 3 секунды Мы перенаправим Вас на Главную страницу</p>
											</Aux>									
									}
									
								</Alert>
						}
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default connect(
	null,
	{ userLogin, setUserData }
)(ConfirmEmailPage);
