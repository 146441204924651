import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

// Components
import ContentWrapper from '../../components/ContentWrapper';

import './PaymentSuccessPage.sass';

const PaymentSuccessPage = () => (
	<ContentWrapper>
		<div className="payment-result-page-wrapper">

			<div className="payment-result-info-block">
				<img src={require('../../assets/img/success-icon.png')} alt="" />
				
				<h2 className="info-title">Оплата прошла успешно!</h2>
				
				<Link to="/"><Button className="info-link" color="success" size="lg">Вернуться в личный кабинет</Button></Link>
			</div>

		</div>
	</ContentWrapper>
);

export default PaymentSuccessPage;
