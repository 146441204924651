import React from 'react';
import { Card, CardBody, Alert } from 'reactstrap';

const PasswordNewChangedMessage = () => (
	<div className="block-center mt-4 wd-xl">
		<Card>
			<CardBody>
				<Alert color="success">
					<h3 className="text-center" style={{ lineHeight: '35px' }}>Новый пароль установлен</h3>
					<p>Через 3 секунды Мы перенаправим Вас на страницу Логина.</p>
				</Alert>
			</CardBody>
		</Card>
	</div>
);

export default PasswordNewChangedMessage;
