import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin } from '../../store/actions/actions';
import { Alert, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Input, CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import axios from '../../axios';

// Components
import FormValidator from '../../components/FormValidator';
import Aux from '../../hoc/Auxilary';
import RegistrationCreateMessage from '../../components/Auth/RegistrationCreateMessage';

import './RegisterPage.sass';

class RegisterPage extends Component {

	state = {
        registerComplete: false,
        formRegister: {
            userName: '',
            email: '',
            password: '',
            password2: '',
            terms: false,
            generatePassword: false
        },
        userRegisterLoading: false,
        userRegisterError: false,
        userRegisterErrors: []
    };

    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });

    };

    onSubmit = e => {
        e.preventDefault()

        const { formRegister } = this.state;

        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors, hasError } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        if (!hasError) {
            this.setState({
                userRegisterLoading: true
            });

            const token = localStorage.getItem('authToken');

            axios.post(`/user/register`, {
                email: formRegister.email,
                name: formRegister.userName,
                password: formRegister.password,
                generate: formRegister.generatePassword
            }, {
                headers: { Authorization: 'Bearer ' + token }
            }).then(response => {
                this.setState({
                    userRegisterLoading: false
                });

                if (response.data.success) {
                    this.setState({
                        registerComplete: true
                    });
                } else {
                    let userRegisterErrors = [];

                    for (let error in response.data.errors) {
                        userRegisterErrors.push({ id: uuid(), error: response.data.errors[error] });
                    }

                    this.setState({
                        userRegisterError: true,
                        userRegisterErrors
                    });
                }
            }).catch(error => {
                this.setState({
                    userRegisterLoading: false
                });
                
                toast.error('Возникла ошибка при попыптке регистрации');
            });
        }
    };

    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    };

	render () {
        const { 
            userRegisterErrors, 
            formRegister, 
            userRegisterLoading 
        } = this.state;

		return (
			<div className="block-center mt-4 wd-xl">

                <Helmet>
                    <title>Boostmy.Email - авторизация</title>
                </Helmet>

                {this.state.registerComplete
                    ?
                        <RegistrationCreateMessage />
                    :
                        <div className="card card-flat">
                            <div className="card-header text-center bg-dark">
                                <a href="!#">
                                    <img className="block-center" src={require('../../assets/img/logo.svg')} alt="Logo"/>
                                </a>
                            </div>

                            <div className="card-body">
                                <p className="text-center py-2">РЕГИСТРАЦИЯ</p>

                                {this.state.userRegisterError
                                    ?
                                        <Alert className="mb-4" color="danger">
                                            {userRegisterErrors.map(error => <div key={error.id}>{error.error}</div>)}
                                        </Alert>
                                    : 
                                        null
                                }

                                <form className="mb-3" name="formRegister" onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <label className="text-muted">Имя</label>
                                       
                                        <div className="input-group with-focus">
                                            <Input
                                                type="text"
                                                name="userName"
                                                className="border-right-0"
                                                placeholder="Представьтесь (необязательно)"
                                                onChange={this.validateOnChange}
                                                value={formRegister.userName}/>
                                       
                                            <div className="input-group-append">
                                                <span className="input-group-text text-muted bg-transparent border-left-0">
                                                    <FontAwesomeIcon icon={faUser} />
                                                </span>
                                            </div>                               
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="text-muted" htmlFor="signupInputEmail1">Email</label>
                                       
                                        <div className="input-group with-focus">
                                            <Input type="email"
                                                name="email"
                                                className="border-right-0"
                                                placeholder="Введите email"
                                                invalid={this.hasError('formRegister','email','required') || this.hasError('formRegister','email','email') ? true : false}
                                                onChange={this.validateOnChange}
                                                data-validate='["required", "email"]'
                                                value={formRegister.email}/>
                                       
                                            <div className="input-group-append">
                                                <span className="input-group-text text-muted bg-transparent border-left-0">
                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                </span>
                                            </div>
                                       
                                            {this.hasError('formRegister','email','required') && <span className="invalid-feedback">Поле обязательно</span>}
                                            {this.hasError('formRegister','email','email') && <span className="invalid-feedback">Неверно введен email</span>}
                                        </div>
                                    </div>

                                    <CustomInput
                                        type="checkbox"
                                        id="generatePassword"
                                        name="generatePassword"
                                        label="Сгенерировать мне пароль"
                                        onChange={this.validateOnChange}
                                        checked={formRegister.generatePassword}>
                                    </CustomInput>

                                    {!formRegister.generatePassword
                                        ?
                                            <Aux>
                                                <div className="form-group">
                                                    <label className="text-muted" htmlFor="signupInputPassword1">Пароль</label>
                                                    
                                                    <div className="input-group with-focus">
                                                        <Input type="password"
                                                            id="id-password"
                                                            name="password"
                                                            className="border-right-0"
                                                            placeholder="Введите пароль"
                                                            invalid={this.hasError('formRegister','password','required') ? true : false}
                                                            onChange={this.validateOnChange}
                                                            data-validate='["required"]'
                                                            value={formRegister.password}
                                                        />
                                                    
                                                        <div className="input-group-append">
                                                            <span className="input-group-text text-muted bg-transparent border-left-0">
                                                                <FontAwesomeIcon icon={faLock} />
                                                            </span>
                                                        </div>
                                                    
                                                        <span className="invalid-feedback">Поле обязательно</span>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="text-muted" htmlFor="signupInputRePassword1">Подтвердите пароль</label>
                                                    
                                                    <div className="input-group with-focus">
                                                        <Input type="password" name="password2"
                                                            className="border-right-0"
                                                            placeholder="Подтвердите пароль"
                                                            invalid={this.hasError('formRegister','password2','equalto') ? true : false}
                                                            onChange={this.validateOnChange}
                                                            data-validate='["equalto"]'
                                                            value={formRegister.password2}
                                                            data-param="id-password"
                                                        />
                                                    
                                                        <div className="input-group-append">
                                                            <span className="input-group-text text-muted bg-transparent border-left-0">
                                                                <FontAwesomeIcon icon={faLock} />
                                                            </span>
                                                        </div>
                                                    
                                                        <span className="invalid-feedback">Поле должно совпадать с предыдущим</span>
                                                    </div>
                                                </div>
                                            </Aux>
                                        :
                                            null
                                    }
                                                               
                                    <button className="btn btn-block btn-primary mt-3" type="submit" disabled={userRegisterLoading}>Зарегистрироваться {userRegisterLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}</button>
                                </form>
                                
                                <p className="pt-3 text-center">Уже есть аккаунт?</p>
                                
                                <Link to="login" className="btn btn-block btn-secondary">Войти</Link>
                            </div>
                        </div>
                }
            </div>
		);
	}
}

export default connect(
    null,
    { userLogin }
)(RegisterPage);
