import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCircle } from '@fortawesome/free-solid-svg-icons';
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	Spinner,
	Label,
	Button,
	ButtonGroup,
	Alert,
	CustomInput
} from 'reactstrap';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { ChromePicker } from 'react-color';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { toast } from 'react-toastify';
import Scrollable from '../../../components/common/Scrollable';
import axios from '../../../axios';

// Components
import ContentWrapper from '../../../components/ContentWrapper';
import SpinnerLoader from '../../../components/SpinnerLoader';
import Aux from '../../../hoc/Auxilary';

import './EditFormEditor.sass';

class EditFormEditor extends Component {

	state = {
		formTitleEditing: true,

		formBgColor: false,
		formTextColor: false,
		formCloseIconColor: false,
		formButtonBgColor: false,

		formEditorText: {
			mobile: {
				title: EditorState.createWithContent(
					ContentState.createFromBlockArray(
						htmlToDraft('Форма заголовок').contentBlocks
					)
				),
				descr: EditorState.createWithContent(
					ContentState.createFromBlockArray(
						htmlToDraft('Форма Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, explicabo alias autem numquam doloribus. Tempora quaerat itaque, quis tenetur autem, cupiditate labore eos. Earum quae at iure, voluptatibus nostrum, alias.').contentBlocks
					)
				)
			},
			desktop: {
				title: EditorState.createWithContent(
					ContentState.createFromBlockArray(
						htmlToDraft('Форма заголовок').contentBlocks
					)
				),
				descr: EditorState.createWithContent(
					ContentState.createFromBlockArray(
						htmlToDraft('Форма Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, explicabo alias autem numquam doloribus. Tempora quaerat itaque, quis tenetur autem, cupiditate labore eos. Earum quae at iure, voluptatibus nostrum, alias.').contentBlocks
					)
				)
			}
		},

		formBgImageRef: {
			desktop: React.createRef(),
			mobile: React.createRef()
		},
		
		formEditorData: {},
		
		formEditorDataTemp: {
			formType: 'popup',
			formName: '',
			domain: '',
			formPreviewPageUrl: '',
			formSize: 'desktop',
			mobile: {
				formWidth: '',
				formHeight: 'auto',
				formBgColor: '#fff',
				formTextColor: '#656565',
				formIconColor: '#656565',
				formButtonBgColor: '#17a2b8',
				formBgImage: '',
				formTitle: 'Форма заголовок',
				formDescr: 'Форма Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, explicabo alias autem numquam doloribus. Tempora quaerat itaque, quis tenetur autem, cupiditate labore eos. Earum quae at iure, voluptatibus nostrum, alias.',
				formInputPlaceholder: 'Введите ваш email',
				formButtonText: 'Подписаться'
			},
			desktop: {
				formWidth: '400',
				formHeight: 'auto',
				formBgColor: '#fff',
				formTextColor: '#656565',
				formIconColor: '#656565',
				formButtonBgColor: '#17a2b8',
				formBgImage: '',
				formTitle: 'Форма заголовок',
				formDescr: 'Форма Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa, explicabo alias autem numquam doloribus. Tempora quaerat itaque, quis tenetur autem, cupiditate labore eos. Earum quae at iure, voluptatibus nostrum, alias.',
				formInputPlaceholder: 'Введите ваш email',
				formButtonText: 'Подписаться'
			}
		},

		formEditorDataLoading: true,
		formEditorDataErrorLoading: false,
		formEditorSaveLoading: false
	};

	componentDidMount () {
		document.body.classList.add('form-edit-page');

		this.getEditorFormData();
	}
	
	componentWillUnmount () {
		document.body.classList.remove('form-edit-page');
	}

	getEditorFormData = () => {
		this.setState({
			formEditorDataLoading: true,
			formEditorDataErrorLoading: false
		});

		axios.get(`/forms/${this.props.match.params.formId}/form`).then(response => {
			this.setState({
				formEditorDataLoading: false
			});

			if (response.data.success) {
				this.setState({
					formEditorData: {
						...response.data.data.template,
						desktop: !response.data.data.template.desktop ? null : JSON.parse(response.data.data.template.desktop),
						mobile: !response.data.data.template.mobile ? null : JSON.parse(response.data.data.template.mobile) 
					}
				});

				this.setEditorFormData();
			} else {
				this.setState({
					formEditorDataErrorLoading: true
				});

				document.body.classList.remove('form-edit-page');
			}
		}).catch(error => {
			this.setState({
				formEditorDataLoading: false,
				formEditorDataErrorLoading: true
			});

			document.body.classList.remove('form-edit-page');
		});
	};

	setEditorFormData = () => {
		const { formEditorDataTemp, formEditorData } = this.state;

		this.setState({
			formEditorDataTemp: {
				...formEditorDataTemp,
				formName: formEditorData.name,
				formType: formEditorData.type ? formEditorData.type : formEditorDataTemp.formType,
				domain: formEditorData.domain,
				mobile: !formEditorData.mobile ? {
					...formEditorDataTemp.mobile
				} : {
					...formEditorDataTemp.mobile,
					...formEditorData.mobile
				},
				desktop: !formEditorData.desktop ? {
					...formEditorDataTemp.desktop
				} : {
					...formEditorDataTemp.desktop,
					...formEditorData.desktop
				}
			}
		});

		this.editorElementsSet();
	};

	saveEditorFormData = () => {
		const { formEditorDataTemp } = this.state;

		this.setState({
			formEditorSaveLoading: true
		});

		axios.post(`/forms/${this.props.match.params.formId}/form/save`, {
			type: formEditorDataTemp.formType,
			mobile: JSON.stringify(formEditorDataTemp.mobile),
			desktop: JSON.stringify(formEditorDataTemp.desktop)
		}).then(response => {
			this.setState({
				formEditorSaveLoading: false
			});
			
			if (response.data.success) {
				toast.success('Настройки формы сохранены.');
			} else {
				toast.error('Ошибка при сохранении формы');
			}			
		}).catch(error => {
			this.setState({
				formEditorSaveLoading: false
			});
			
			toast.error('Ошибка при сохранении формы');
		});
	};

	formEditorElementChange = (name, value, size) => {
		const { formEditorDataTemp } = this.state;

		if (size) {
			this.setState({
				formEditorDataTemp: {
					...formEditorDataTemp,
					[size]: {
						...formEditorDataTemp[size],
						[name]: value
					}
				}
			});
		} else {
			this.setState({
				formEditorDataTemp: {
					...formEditorDataTemp,
					[name]: value
				}
			});
		}
	};

	editorElementChange = (name, value, size) => {
		const { formEditorDataTemp, formEditorText } = this.state;

		if (name === 'title') {

			this.setState({
				formEditorText: {
					...formEditorText,
					[size]: {
						...formEditorText[size],
						[name]: value
					}
				},
				formEditorDataTemp: {
					...formEditorDataTemp,
					[size]: {
						...formEditorDataTemp[size],
						formTitle: draftToHtml(convertToRaw(value.getCurrentContent()))
					}
				}
			});

		} else if (name === 'descr') {

			this.setState({
				formEditorText: {
					...formEditorText,
					[size]: {
						...formEditorText[size],
						[name]: value
					}
				},
				formEditorDataTemp: {
					...formEditorDataTemp,
					[size]: {
						...formEditorDataTemp[size],
						formDescr: draftToHtml(convertToRaw(value.getCurrentContent()))
					}
				}
			});

		}
	};

	editorElementsSet = () => {
		let { formEditorData, formEditorText } = this.state;

		if (formEditorData.desktop) {
			this.setState({
				formEditorText: {
					...formEditorText,
					desktop: {
						title: EditorState.createWithContent(
							ContentState.createFromBlockArray(
								htmlToDraft(formEditorData.desktop.formTitle).contentBlocks
							)
						),
						descr: EditorState.createWithContent(
							ContentState.createFromBlockArray(
								htmlToDraft(formEditorData.desktop.formDescr).contentBlocks
							)
						)
					}
				}
			});
		} else if (formEditorData.mobile) {
			this.setState({
				formEditorText: {
					...formEditorText,
					mobile: {
						title: EditorState.createWithContent(
							ContentState.createFromBlockArray(
								htmlToDraft(formEditorData.mobile.formTitle).contentBlocks
							)
						),
						descr: EditorState.createWithContent(
							ContentState.createFromBlockArray(
								htmlToDraft(formEditorData.mobile.formDescr).contentBlocks
							)
						)
					}
				}
			});
		} else if (formEditorData.mobile && formEditorData.desktop) {
			this.setState({
				formEditorText: {
					desktop: {
						title: EditorState.createWithContent(
							ContentState.createFromBlockArray(
								htmlToDraft(formEditorData.desktop.formTitle).contentBlocks
							)
						),
						descr: EditorState.createWithContent(
							ContentState.createFromBlockArray(
								htmlToDraft(formEditorData.desktop.formDescr).contentBlocks
							)
						)
					},
					mobile: {
						title: EditorState.createWithContent(
							ContentState.createFromBlockArray(
								htmlToDraft(formEditorData.mobile.formTitle).contentBlocks
							)
						),
						descr: EditorState.createWithContent(
							ContentState.createFromBlockArray(
								htmlToDraft(formEditorData.mobile.formDescr).contentBlocks
							)
						)
					}
				}
			});
		}
	};

	bgColorPickerClick = () => {
        this.setState({ formBgColor: !this.state.formBgColor });
    };

    textColorPickerClick = () => {
        this.setState({ formTextColor: !this.state.formTextColor });
    };

    closeIconPickerClick = () => {
        this.setState({ formCloseIconColor: !this.state.formCloseIconColor });
    };

    buttonBgPickerClick = () => {
        this.setState({ formButtonBgColor: !this.state.formButtonBgColor });
    };

	bgColorPickerClose = () => {
        this.setState({ formBgColor: false });
    };

    textColorPickerClose = () => {
        this.setState({ formTextColor: false });
    };

    closeIconPickerClose = () => {
        this.setState({ formCloseIconColor: false });
    };

	buttonBgPickerClose = () => {
        this.setState({ formButtonBgColor: false });
    };    

    setTitleEdit = () => {
    	this.setState({
    		formTitleEditing: true
    	});
    };

    setDescrEdit = () => {
    	this.setState({
    		formTitleEditing: false
    	});
    };

	render () {
		const {
			formBgImageRef,
			formEditorText,
			formEditorDataTemp,
			formTitleEditing,
			formBgColor,
			formTextColor,
			formEditorDataLoading,
			formEditorDataErrorLoading,
			formCloseIconColor,
			formButtonBgColor,
			formEditorSaveLoading
		} = this.state;

		const { formSize } = formEditorDataTemp;

		const popover = {
            position: 'absolute',
            zIndex: '302'
        };

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px'
        };

		return (
			<ContentWrapper>
				{formEditorDataErrorLoading ? (
					<Alert className="text-center" color="danger">
						Ошибка при загрузке данных

						<br/>

						<Button 
							className="mt-2" 
							size="xs" 
							color="secondary" 
							onClick={() => this.getEditorFormData()}
						>
							Повторить запрос
						</Button>
					</Alert>
				) : (
					<Aux>
						{formEditorDataLoading 
							? 
								<div className="mt-4"><SpinnerLoader /></div> 
							: 
								<Card className="form-editor-card">
									<CardHeader className="form-editor-controls-header">
										<div className="container-fluid">
											<div className="row">
												<div className="col-xl-6">

													<h3 className="edit-form-title">Редактор формы: {formEditorDataTemp.formName}</h3>

												</div>
												<div className="col-xl-6 mt-3 mt-xl-0 text-right">

													<div className="preview-form">
														<div id="previewInputGroupCol" className="flex-wrap flex-xs-nowrap">
															<span className="mr-2 d-block d-inline-xs">{formEditorDataTemp.domain}/</span>

															<InputGroup className="preview-input-group">
																<Input onChange={e => this.formEditorElementChange('formPreviewPageUrl', e.target.value)} placeholder="Введите адрес страницы (только на вашем домене)" />
																
																<InputGroupAddon addonType="append">
																	<Button type="secondary">Предпросмотр</Button>
																</InputGroupAddon>
															</InputGroup>
														</div>

														<div className="d-inline-flex mt-3 mt-lg-0 ml-lg-3">
															<Button 
																className="d-inline-flex"
																size="md" 
																color="primary" 
																onClick={() => this.saveEditorFormData()}
																disabled={formEditorSaveLoading}
															>
																Сохранить {formEditorSaveLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
															</Button>

															<Link to={`/form/${this.props.match.params.formId}/edit`}><Button size="md" color="secondary" className="ml-3">Отмена</Button></Link>
														</div>
													</div>

												</div>
											</div>
										</div>
									</CardHeader>
									
									<CardBody>
										<div className="container-fluid">
											<div className="row">
												<div className="col-lg-5 col-xl-4 col-xxl-3 form-controls-col">

													<Scrollable height="100%">
														<div className="form-controls-wrapper">
															<FormGroup>
																<Label>Тип формы</Label>

																<FormGroup>
																	<label className="c-radio">
						                                            	<Input 
						                                            		id="formTypeRadio1" 
						                                            		type="radio" 
						                                            		name="formTypeRadio" 
						                                            		value="popup" 
						                                            		onChange={e => this.formEditorElementChange('formType', e.target.value)} 
						                                            		checked={formEditorDataTemp.formType === 'popup'}
						                                            	/>
						                                            	<FontAwesomeIcon icon={faCircle} /> Popup
						                                            </label>
						                                        	
						                                        	<label className="c-radio">
						                                            	<Input 
						                                            		id="formTypeRadio2" 
						                                            		type="radio" 
						                                            		name="formTypeRadio" 
						                                            		value="top-bar"
						                                            		onChange={e => this.formEditorElementChange('formType', e.target.value)} 
						                                            		checked={formEditorDataTemp.formType === 'top-bar'}
						                                            	/>
						                                            	<FontAwesomeIcon icon={faCircle} /> Top-bar
						                                           </label>

						                                           <label className="c-radio">
						                                           		<Input 
						                                            		id="formTypeRadio3" 
						                                            		type="radio" 
						                                            		name="formTypeRadio" 
						                                            		value="bottom-bar"
						                                            		onChange={e => this.formEditorElementChange('formType', e.target.value)} 
						                                            		checked={formEditorDataTemp.formType === 'bottom-bar'}
						                                            	/>
						                                            	<FontAwesomeIcon icon={faCircle} /> Bottom-bar
						                                           </label>
						                                        </FormGroup>
															</FormGroup>

															<ButtonGroup>
																<Button 
																	color="primary" 
																	size="lg" 
																	onClick={() => this.formEditorElementChange('formSize', 'desktop')} 
																	active={formEditorDataTemp.formSize === 'desktop'}
																>
																	Desktop
																</Button>

																<Button 
																	color="primary" 
																	size="lg" 
																	onClick={() => this.formEditorElementChange('formSize', 'mobile')} 
																	active={formEditorDataTemp.formSize === 'mobile'}
																>
																	Mobile
																</Button>
															</ButtonGroup>

															<div className="row mt-4">
																<div className="col-6">

																	<FormGroup>
																		<Label for="formWidthInput">Ширина формы</Label>
																		
																		<Input 
																			value={formEditorDataTemp[formSize].formWidth} 
																			onChange={e => this.formEditorElementChange('formWidth', e.target.value, formSize)} 
																			type="number" 
																			id="formWidthInput" 
																			placeholder="Ширина в px" 
																			disabled={formSize === 'mobile' || formEditorDataTemp.formType === 'top-bar' || formEditorDataTemp.formType === 'bottom-bar'} 
																		/>
																	</FormGroup>

																</div>
																<div className="col-6">

																	<FormGroup>
																		<Label for="formHeightInput">Высота формы</Label>

																		<Input 
																			value={formEditorDataTemp[formSize].formHeight} 
																			onChange={e => this.formEditorElementChange('formHeight', e.target.value, formSize)} 
																			max={formSize === 'mobile' ? 500 : ''} 
																			min={0} 
																			type="number" 
																			id="formHeightInput" 
																			placeholder="Высота в px"
																		/>
																	</FormGroup>

																</div>
																<div className="col-sm-6 text-center">

																	<FormGroup className="color-picker-fg">
																		<Label className="d-block" for="Input">Цвет фона</Label>

																		<Input 
																			type="text" 
																			value={formEditorDataTemp[formSize].formBgColor} 
																			onClick={() => this.bgColorPickerClick()} 
																			onChange={e => this.formEditorElementChange('formBgColor', e.target.value, formSize)} 
																		/>
																		
																		<span 
																			className="node-color" 
																			onClick={() => this.bgColorPickerClick()} 
																			style={{ backgroundColor: formEditorDataTemp[formSize].formBgColor }}
																		></span>
																	</FormGroup>

																	{formBgColor ? (
																		<div style={popover}>
						                                                	<div style={cover} onClick={() => this.bgColorPickerClose()}/>
						                                             	   	
						                                             	   	<ChromePicker 
						                                             	   		color={formEditorDataTemp[formSize].formBgColor} 
						                                             	   		onChangeComplete={color => this.formEditorElementChange('formBgColor', color.hex, formSize)}
						                                             	   	/>
						                                            	</div>
						                                            ) : null }

																</div>
																<div className="col-sm-6 text-center">

																	<FormGroup className="color-picker-fg">
																		<Label className="d-block" for="Input">Цвет текста</Label>
																		
																		<Input 
																			type="text" 
																			value={formEditorDataTemp[formSize].formTextColor} 
																			onClick={() => this.textColorPickerClick()} 
																			onChange={e => this.formEditorElementChange('formTextColor', e.target.value, formSize)} 
																		/>
																		
																		<span 
																			className="node-color" 
																			onClick={this.textColorPickerClick} 
																			style={{ backgroundColor: formEditorDataTemp[formSize].formTextColor }}
																		></span>
																	</FormGroup>

																	{formTextColor ? (
																		<div style={popover}>
						                                                	<div style={ cover } onClick={this.textColorPickerClose}/>
						                                                	
						                                                	<ChromePicker 
						                                                		color={formEditorDataTemp[formSize].formTextColor} 
						                                                		onChangeComplete={color => this.formEditorElementChange('formTextColor', color.hex, formSize)}
						                                                	/>
						                                                </div>
						                                            ) : null}

																</div>
																<div className="col-sm-6 text-center">

																	<FormGroup className="color-picker-fg">
																		<Label className="d-block" for="Input">Иконка X</Label>

																		<Input 
																			type="text" 
																			value={formEditorDataTemp[formSize].formIconColor} 
																			onClick={() => this.closeIconPickerClick()} 
																			onChange={e => this.formEditorElementChange('formIconColor', e.target.value, formSize)} 
																		/>
																		
																		<span 
																			className="node-color" 
																			onClick={() => this.closeIconPickerClick()} 
																			style={{ backgroundColor: formEditorDataTemp[formSize].formIconColor }}
																		></span>
																	</FormGroup>

																	{formCloseIconColor ? (
																		<div style={popover}>
						                                                	<div style={cover} onClick={() => this.closeIconPickerClose()} />
						                                                  	
						                                                  	<ChromePicker 
						                                                  		color={formEditorDataTemp[formSize].formIconColor} 
						                                                  		onChangeComplete={color => this.formEditorElementChange('formIconColor', color.hex, formSize)}
						                                                  	/>
						                                                </div>
						                                            ) : null}

																</div>
																<div className="col-sm-6 text-center">

																	<FormGroup className="color-picker-fg">
																		<Label className="d-block" for="Input">Фон кнопки</Label>

																		<Input 
																			type="text" 
																			value={formEditorDataTemp[formSize].formButtonBgColor} 
																			onClick={() => this.buttonBgPickerClick()} 
																			onChange={e => this.formEditorElementChange('formButtonBgColor', e.target.value, formSize)} 
																		/>
																		
																		<span 
																			className="node-color" 
																			onClick={() => this.buttonBgPickerClick()} 
																			style={{ backgroundColor: formEditorDataTemp[formSize].formButtonBgColor }}
																		></span>
																	</FormGroup>

																	{formButtonBgColor ? (
																		<div style={popover}>
						                                                	<div style={cover} onClick={() => this.buttonBgPickerClose()} />
						                                                  	
						                                                  	<ChromePicker 
						                                                  		color={formEditorDataTemp[formSize].formButtonBgColor} 
						                                                  		onChangeComplete={color => this.formEditorElementChange('formButtonBgColor', color.hex, formSize)}
						                                                  	/>
						                                                </div>
						                                            ) : null}

																</div>
															</div>

															<FormGroup>
																<Label for="formBgImageFileInput">Выбрать иозбражение</Label>

																{formSize === 'desktop' && (
																	<>
																		<CustomInput 
																			className="formBgImageInput"
																			label={formEditorDataTemp[formSize].formBgImage}
																			type="file" 
																			innerRef={formBgImageRef[formSize]}
																			id="formBgImageFileInput" 
																			onChange={e => this.formEditorElementChange(
																				'formBgImage', 
																				e.target.files[0] 
																					? 
																						URL.createObjectURL(e.target.files[0]) 
																					: 
																						'',
																				formSize
																			)}
																		/>
																		
																		<span 
																			onClick={e => {
																				this.formEditorElementChange('formBgImage', '', formSize);
																				formBgImageRef[formSize].current.nextElementSibling.innerHTML = 'Choose file';
																			}} 
																			className="link"
																		>
																			Удалить изображение
																		</span>
																	</>
																)}

																{formSize === 'mobile' && (
																	<>
																		<CustomInput 
																			className="formBgImageInput"
																			label={formEditorDataTemp[formSize].formBgImage}
																			type="file" 
																			innerRef={formBgImageRef[formSize]}
																			id="formBgImageFileInput" 
																			onChange={e => this.formEditorElementChange(
																				'formBgImage', 
																				e.target.files[0] 
																					? 
																						URL.createObjectURL(e.target.files[0]) 
																					: 
																						'',
																				formSize
																			)}
																		/>
																		
																		<span 
																			onClick={e => {
																				this.formEditorElementChange('formBgImage', '', formSize);
																				formBgImageRef[formSize].current.nextElementSibling.innerHTML = 'Choose file';
																			}} 
																			className="link"
																		>
																			Удалить изображение
																		</span>
																	</>
																)}
															</FormGroup>

															<Editor
																editorState={formTitleEditing ? formEditorText[formSize].title : formEditorText[formSize].descr}
						                                        wrapperClassName="form-wysivig-editor-wrapper mt-4"
						                                        editorClassName="form-control"
						                                        editorStyle={{ height: 200 }}
						                                         toolbar={{
														            colorPicker: {
														                popupClassName:'popup-editor'
														            },
														            link: {
														                popupClassName:'popup-editor'
														            },
														            emoji: {
														                popupClassName:'popup-editor'
														            },
														            embedded: {
														                popupClassName:'popup-editor'
														            },
														            image: {
														                popupClassName:'popup-editor'
														            }
														        }}
						                                        onEditorStateChange={formTitleEditing ? e => this.editorElementChange('title', e, formSize) : e => this.editorElementChange('descr', e, formSize)}
						                                    />
						                                </div>
				                                    </Scrollable>

												</div>
												<div className={`col-lg-7 col-xl-8 col-xxl-9 card-form-col ${formSize === 'mobile' ? 'mobile-format' : ''}`}>
													<Scrollable 
														height="100%" 
														className={`card-form-scroll-wrapper ${formSize === 'mobile' ? 'mobile-format' : ''} ${formEditorDataTemp.formType === 'bottom-bar' && formEditorDataTemp[formSize].formHeight > 500 ? 'top-bar' : formEditorDataTemp.formType}`}
													>
														<div 
															className={`form-card ${formEditorDataTemp[formSize].formHeight} ${formSize === 'mobile' ? 'mobile-format' : ''} ${formEditorDataTemp.formType === 'bottom-bar' && formEditorDataTemp[formSize].formHeight > 500 ? 'top-bar' : formEditorDataTemp.formType}`}
															style={{ 
																width: `${formEditorDataTemp[formSize].formWidth}px`, 
																maxWidth: `${formEditorDataTemp[formSize].formWidth}px`, 
																height: `${formEditorDataTemp[formSize].formHeight === 'auto' ? 'auto' : formEditorDataTemp[formSize].formHeight + 'px'}` 
															}}

														>
															<div className="form-card-body" style={{
																backgroundColor: formEditorDataTemp[formSize].formBgColor, 
																backgroundImage: `url(${formEditorDataTemp[formSize].formBgImage})`
															}}>
																<FontAwesomeIcon 
																	style={{ color: formEditorDataTemp[formSize].formIconColor }} 
																	className="form-close-icon" 
																	icon={faTimes}
																/>

																<h3 
																	onClick={this.setTitleEdit} 
																	dangerouslySetInnerHTML={{ __html: formEditorDataTemp[formSize].formTitle }} 
																	className="form-title" 
																	style={{ color: formEditorDataTemp[formSize].formTextColor }}
																></h3>
																
																<p 
																	onClick={this.setDescrEdit} 
																	dangerouslySetInnerHTML={{ __html: formEditorDataTemp[formSize].formDescr }} 
																	className="form-descr" 
																	style={{ color: formEditorDataTemp[formSize].formTextColor }}
																></p>

																<FormGroup className="form-input-wrap">
																	<Input 
																		className="form-input" 
																		type="text" 
																		value={formEditorDataTemp[formSize].formInputPlaceholder} 
																		onChange={e => this.formEditorElementChange('formInputPlaceholder', e.target.value, formSize)}
																	/>
																</FormGroup>

																<div className="text-center form-button-wrap">
																	<Button 
																		style={{ backgroundColor: formEditorDataTemp[formSize].formButtonBgColor }} 
																		size="lg" 
																		color="info" 
																		className="form-button"
																	>
																		<Input 
																			className="form-button-input" 
																			value={formEditorDataTemp[formSize].formButtonText} 
																			onChange={e => this.formEditorElementChange('formButtonText', e.target.value, formSize)}
																		/>
																	</Button>
																</div>
															</div>
														</div>	
													</Scrollable>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							}
					</Aux>
				)}
			</ContentWrapper>
		);
	}
}

export default EditFormEditor;
