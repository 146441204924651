import React, { Component } from 'react';
import {
	Form,
	FormGroup,
	Label,
	Input,
	Alert,
	Button,
	Spinner
} from 'reactstrap';
import axios from '../../../axios';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';

// Components
import ContentWrapper from '../../../components/ContentWrapper';
import FormValidator from '../../../components/FormValidator';
import SpinnerLoader from '../../../components/SpinnerLoader';
import Aux from '../../../hoc/Auxilary';

import './ProfileAccountSettings.sass';

class ProfileAccountSettings extends Component {

	state = {
		contactDetailsForm: {
			profileName: '',
			phoneNumber: '',
			companyName: ''
		},
		passwordForm: {
			currentPassword: '',
			newPassword: '',
			newPasswordConfirm: ''
		},
		passwordChangeError: false,
		passwordChangeErrors: [],
		contactDetailsLoading: false, 
		contactDetailsErrorLoading: false, 
		contactDetailsSaveLoading: false,
		passwordChangeLoading: false
	};

	componentDidMount () {
		this.getContactDetails();
	}

	getContactDetails = () => {
		this.setState({
			contactDetailsLoading: true
		});

		axios.get('/settings/user/get').then(response => {
			this.setState({
				contactDetailsLoading: false
			});

			if (response.data.success) {
				this.setState({
					contactDetailsForm: {
						profileName: response.data.data.name,
						phoneNumber: response.data.data.phone,
						companyName: response.data.data.company
					}
				});
			} else {
				this.setState({
					contactDetailsErrorLoading: true
				});
			}
		}).catch(error => {
			this.setState({
				contactDetailsLoading: false,
				contactDetailsErrorLoading: true
			});
		});
	};

	validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });

    };

    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    };

	changePasswordFormSubmit = e => {
		e.preventDefault();

        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors, hasError } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        if (!hasError) {
        	const { passwordForm } = this.state;

        	this.setState({
        		passwordChangeLoading: true
        	});

        	const params = {
				oldPass: passwordForm.currentPassword,
				newPass: passwordForm.newPassword,
				newPass2: passwordForm.newPasswordConfirm
        	};

        	axios.post('/settings/user/password/change', params)
        		.then(response => {
	        		this.setState({
	        			passwordChangeLoading: false
	        		});

	        		if (response.data.success) {
	        			toast.success('Пароль успешно сохранен!');

	        			this.setState({
			        		passwordForm: {
								currentPassword: '',
								newPassword: '',
								newPasswordConfirm: ''
							},
							passwordChangeError: false,
							passwordChangeErrors: []
			        	});

	        		} else {
						let passwordChangeErrors = [];

		                for (let error in response.data.errors) {
		                    passwordChangeErrors.push({ id: uuid(), error: response.data.errors[error] });
		                }

		                this.setState({
		                    passwordChangeError: true,
		                    passwordChangeErrors
		                });
	        		}
	        	}).catch(error => {
	        		this.setState({
	        			passwordChangeLoading: false
	        		});

	        		toast.error('Ошибка при сохранении пароля');
	        	});
        }
	};

	saveContactDetailsFormSubmit = e => {
		e.preventDefault();

        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors, hasError } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        if (!hasError) {
        	const { contactDetailsForm } = this.state;

        	this.setState({
        		contactDetailsSaveLoading: true
        	});

        	const params = {
        		name: contactDetailsForm.profileName,
        		phone: contactDetailsForm.phoneNumber,
        		companyName: contactDetailsForm.companyName
        	};

        	axios.post('/settings/user/save', params)
        		.then(response => {
	        		this.setState({
	        			contactDetailsSaveLoading: false
	        		});

	        		if (response.data.success) {
	        			toast.success('Контактные данные сохранены.');
	        		} else {
	        			toast.error('Ошибка при сохранении данных');
	        		}
	        	}).catch(error => {
	        		this.setState({
	        			contactDetailsSaveLoading: false
	        		});

	        		toast.error('Ошибка при сохранении данных');
	        	});
        }
	};

	render () {
		const { 
			contactDetailsForm, 
			passwordForm, 
			passwordChangeError,
			passwordChangeErrors,
			contactDetailsLoading,
			contactDetailsErrorLoading,
			contactDetailsSaveLoading,
			passwordChangeLoading
		} = this.state;

		return (
			<ContentWrapper>
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-6">

							<h2>Контактные данные</h2>

							{contactDetailsErrorLoading ? (
								<Alert color="danger">
									Ошибка при загрузке данных

									<br/>

									<Button 
										className="mt-2" 
										size="xs" 
										color="secondary" 
										onClick={() => this.getContactDetails()}
									>
										Повторить запрос
									</Button>
								</Alert>
							) : (
								<Aux>
									{contactDetailsLoading ? <SpinnerLoader /> : (
										<Aux>
											<p>Чтобы улучшить опыт взаимодействия с нашим сервисом, пожалуйста, заполните контактную информацию ниже. Сервис гарантирует сохранность указанных данных - они никогда не будут переданы третьим лицам и будут использованы только с целью коммуникации по вопросам работы сервиса.</p>
											<p>По завершению ввода данных нажмите кнопку "Сохранить" ниже</p>

											<Form 
												name="contactDetailsForm" 
												className="profile-settings-form" 
												onSubmit={e => this.saveContactDetailsFormSubmit(e)}
											>
												<FormGroup>
													<Label for="profileNameInput">Как к вам обращаться</Label>
													
													<Input 
														name="profileName"
														id="profileNameInput" 
														type="text" 
														value={contactDetailsForm.profileName} 
														onChange={this.validateOnChange} 
													/>
												</FormGroup>

												<FormGroup>
													<Label for="profilePhoneInput">Номер телефона для связи</Label>
													
													<Input 
														name="phoneNumber"
														id="profilePhoneInput" 
														type="text" 
														value={contactDetailsForm.phoneNumber} 
														onChange={this.validateOnChange} 
													/>
												</FormGroup>

												<FormGroup>
													<Label for="profileCompanyInput">Название вашей организации</Label>
													
													<Input 
														name="companyName"
														id="profileCompanyInput" 
														type="text" 
														value={contactDetailsForm.companyName} 
														onChange={this.validateOnChange} 
													/>
												</FormGroup>

												<Button 
													color="primary" 
													size="lg"
													disabled={contactDetailsSaveLoading}
												>
													Сохранить {contactDetailsSaveLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
												</Button>
											</Form>
										</Aux>
									)}
								</Aux>
							)}

						</div>
						<div className="col-xl-6 mt-4 mt-xl-0">

							<h2>Смена пароль</h2>
							<p>Для смены текущего пароля аккаунта, укажите в полях ниже действующий и новый пароль. Новый пароль указывается дважды, для предотвращения ошибок ввода. После ввода паролей нажмите кнопку "Сменить пароль".</p>

							<ul>
								<li>Содержит не менее 8 символов</li>
								<li>Содержит строчные и заглавные буквы латинского алфавита</li>
								<li>Содержит минимум одну цифру</li>
							</ul>

							{passwordChangeError && (
								<Alert className="mb-4" color="danger">
	                                {passwordChangeErrors.map(error => <div key={error.id}>{error.error}</div>)}
	                            </Alert>
							)}

							<Form 
								name="passwordForm" 
								className="profile-settings-form" 
								onSubmit={e => this.changePasswordFormSubmit(e)}
							>
								<FormGroup>
									<Label for="profilePasswordInput">Текущий пароль</Label>
									
									<Input 
										id="profilePasswordInput" 
										name="currentPassword"
										type="password" 
										value={passwordForm.currentPassword}
										invalid={this.hasError('passwordForm','currentPassword','required')}
										data-validate='["required"]'
										onChange={this.validateOnChange}
									/>

									{this.hasError('passwordForm','currentPassword','required') && <span className="invalid-feedback">Поле обязательно</span>}
								</FormGroup>

								<FormGroup>
									<Label for="profileNewPasswordInput">Новый пароль</Label>
									
									<Input 
										id="profileNewPasswordInput" 
										name="newPassword"
										type="password" 
										invalid={this.hasError('passwordForm','newPassword','required')}
										data-validate='["required"]'
										value={passwordForm.newPassword}
										onChange={this.validateOnChange}
									/>

									<span className="invalid-feedback">Поле обязательно</span>
								</FormGroup>

								<FormGroup>
									<Label for="profileNewPasswordConfirmInput">Новый пароль повторно</Label>
									
									<Input 
										id="profileNewPasswordConfirmInput" 
										name="newPasswordConfirm"
										type="password" 
										invalid={this.hasError('passwordForm','newPasswordConfirm','equalto')}
										data-validate='["equalto"]'
										value={passwordForm.newPasswordConfirm}
										onChange={this.validateOnChange}
										data-param="profileNewPasswordInput"
									/>

									<span className="invalid-feedback">Поле должно совпадать с предыдущим</span>
								</FormGroup>

								<Button 
									color="primary" 
									size="lg"
									disabed={passwordChangeLoading}
								>
									Сменить пароль {passwordChangeLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}
								</Button>
							</Form>

						</div>
					</div>
				</div>
			</ContentWrapper>
		);
	}
}

export default ProfileAccountSettings;
