const Menu = [
    {
        heading: 'Главное меню',
    },
    {
        name: 'Пользователи',
        icon: 'icon-people',
        path: '/'
    },
    {
        name: 'Модерация',
        icon: 'icon-note',
        path: '/admin/moderation'
    },
    {
        name: 'Список исключений',
        icon: 'icon-close',
        path: '/admin/audience/exceptions'
    },
    {
        name: 'Оплата',
        icon: 'icon-basket',
        path: '/admin/payment'
    },
];

export default Menu;
