import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLogin, setUserData } from '../../store/actions/actions';
import { Link } from 'react-router-dom';
import { Input, Alert, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import axios from '../../axios';

// Components
import FormValidator from '../../components/FormValidator';

import './LoginPage.sass';

class LoginPage extends Component {

	state = {
        formLogin: {
            email: '',
            password: ''
        },
        userLoginLoading: false,
        userLoginError: false,
        userLoginErrors: []
    };

    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });

    };

    onSubmit = e => {
        e.preventDefault();

        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors, hasError } = FormValidator.bulkValidate(inputs);

        const { formLogin } = this.state;
        const { 
            userLogin,
            location,
            history,
            setUserData
        } = this.props;

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        if (!hasError) {
            this.setState({
                userLoginLoading: true
            });

            const token = localStorage.getItem('authToken');

            axios.post(`/user/login`, {
                email: formLogin.email,
                password: formLogin.password
            }, {
                headers: { Authorization: 'Bearer ' + token }
            }).then(response => {
                this.setState({
                    userLoginLoading: false
                });

                if (response.data.success) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.auth_token}`;
                    
                    userLogin(response.data.data.auth_token);
                    setUserData(response.data.data.user.id, response.data.data.user.name, response.data.data.user.email);

                    localStorage.setItem('authToken', response.data.data.auth_token);

                    const nextPathname = location.state ? location.state.nextPathname : null;

                    history.push(nextPathname ? nextPathname : '/');
                } else {
                    let userLoginErrors = [];

                    for (let error in response.data.errors) {
                        userLoginErrors.push({ id: uuid(), error: response.data.errors[error] });
                    }

                    this.setState({
                        userLoginError: true,
                        userLoginErrors
                    });
                }
            }).catch(error => {
                this.setState({
                    userLoginLoading: false
                });
                
                toast.error('Возникла ошибка при попытке входа');
            });
        }
    };

    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    };

	render () {
		return (
			<div className="block-center mt-4 wd-xl">

                <Helmet>
                    <title>Boostmy.Email - авторизация</title>
                </Helmet>

                <div className="card card-flat">

                    <div className="card-header text-center bg-dark">
                        <a href="!#">
                            <img className="block-center rounded" src={require('../../assets/img/logo.svg')} alt="Logo"/>
                        </a>
                    </div>
                
                    <div className="card-body">
                        <p className="text-center py-2">ВОЙДИТЕ, ЧТОБЫ ПРОДОЛЖИТЬ</p>

                        {this.state.userLoginError
                            ?
                                <Alert className="mb-4" color="danger">
                                    {this.state.userLoginErrors.map(error => <div key={error.id}>{error.error}</div>)}
                                </Alert>
                            :
                                null
                        }
                
                        <form className="mb-3" name="formLogin" onSubmit={this.onSubmit}>
                            
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="email"
                                        name="email"
                                        className="border-right-0"
                                        placeholder="Введите email"
                                        invalid={this.hasError('formLogin','email','required')||this.hasError('formLogin','email','email') ? true : false}
                                        onChange={this.validateOnChange}
                                        data-validate='["required", "email"]'
                                        value={this.state.formLogin.email}/>
                            
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-envelope"></em>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </span>
                                    </div>
                            
                                    { this.hasError('formLogin','email','required') && <span className="invalid-feedback">Поле обязательно</span> }
                                    { this.hasError('formLogin','email','email') && <span className="invalid-feedback">Неверно введен email</span> }
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="password"
                                        id="id-password"
                                        name="password"
                                        className="border-right-0"
                                        placeholder="Пароль"
                                        invalid={this.hasError('formLogin','password','required') ? true : false}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formLogin.password}
                                    />
                            
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                        	<FontAwesomeIcon icon={faLock} />
                                        </span>
                                    </div>
                            
                                    <span className="invalid-feedback">Поле обязательно</span>
                                </div>
                            </div>

                            <div className="clearfix">                            
                                <div className="float-right">
                                    <Link to="/recover" className="text-muted">Забыли пароль?</Link>
                                </div>
                            </div>

                            <button className="btn btn-block btn-primary mt-3" type="submit" disabled={this.state.userLoginLoading}>Войти {this.state.userLoginLoading ? <Spinner className="ml-2" size="sm" color="light" /> : null}</button>
                        </form>

                        <p className="pt-3 text-center">Нужно зарегистрироваться?</p>
                        
                        <Link to="register" className="btn btn-block btn-secondary">Зарегистрироваться</Link>
                    </div>
                </div>
            </div>
		);
	}
}

export default connect(
    null,
    { userLogin, setUserData }
)(LoginPage);
